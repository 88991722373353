@import "../../styles/colors.scss";

.lesson-finished {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  .icon {
    width: 160px;
    height: 160px;
    fill: $color-secondary;
  }

  .text {
    margin-top: 10px;
    font-weight: 500;
    font-size: 3em;
  }
}
