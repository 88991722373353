.curriculum-lessons {
  padding: 15px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;

  .no-lesson-container {
    margin-top: 30px;
    padding-left: 0px;
  }

  .curriculum-lessons-list {
    overflow-y: auto;
    margin-top: 3px;
  }
}