.pdf-container {
  width: 100%;
  height: 99%;
}

.pdf-container-loading {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .loading-message {
    margin-top: 15px;
    font-weight: 500;
  }
}
