@import "../../styles/colors.scss";

.getting-started {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  video {
    width: 60%;
    @media screen and (max-width: 1024px) {
      width: 80%;
    }
  }

  p {
    margin-top: 15px;
    font-size: 1.3em;
    font-weight: 500;
  }

  .add-student-link {
    margin-top: 10px;
    font-size: 1.4em;
    font-weight: 500;
    color: $color-secondary;
    cursor: pointer;
  }
}