@import '../../styles/colors.scss';

.tooltip-with-indicator {
  .tooltip-with-indicator-icon {
    font-size: 1.8em;
    color: $color-primary;
    background-color: white;
    border-radius: 15px;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 1024px) {
      font-size: 2.1em;
    }
  }
}

.tooltip-with-indicator-dialog {
  .tooltip-with-indicator-title {
    display: flex;
  }
  .tooltip-with-indicator-title-icon {
    font-size: 1.3em;
    color: $color-primary;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .tooltip-with-indicator-content {
    min-width: 20vw;
    .tooltip-with-indicator-content-text {
      color: $color-medium;
      white-space: nowrap;
    }
  }
  .tooltip-with-indicator-actions {
    display: flex;
    .tooltip-with-indicator-button {
      margin: auto;
      min-width: 50%;
      max-width: 300px;
    }
  }

  .tooltip-video-player-inline-video {
    max-width: 500px;
    min-width: 500px;
  }
  .MuiDialog-paperWidthSm {
    max-width: unset;
  }
}