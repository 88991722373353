@import '../../styles/colors.scss';

.search-and-sort-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  .table-toolbar {
    display: flex;
    align-items: center;
  }
  .keyword-input {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 300px;
    @media screen and (max-width: 1024px) {
      width: 225px;
    }
    margin-top: 5px;
    margin-bottom: 15px;
    .input-field {
      padding-right: 40px;
    }
  }

  .sortable-table-container {
    position: relative;
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

  .search-and-sortable-table-container {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 120px;
    .MuiTablePagination-root {
      overflow: hidden;
    }
  }
  .no-result-found {
    position: absolute;
    top: 30%;
    left: 5px;
    font-weight: 500;
    z-index: 1;
    font-size: 1.3rem;
    color: $color-secondary;
    width: 100%;
    text-align: center;
  }
  .clear-keyword-button {
    position: absolute;
    right: 0;
  }
  .gap {
    height: 5px;
  }
}
