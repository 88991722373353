@import './colors.scss';

.icon-primary {
  fill: $color-primary !important;
}

.icon-red-with-gradient-boder {
  background: rgba(209, 209, 209, 1);
  background: linear-gradient(
    to bottom,
    rgba(209, 209, 209, 1) 0%,
    rgba(209, 209, 209, 1) 51%,
    rgba(219, 219, 219, 1) 51%,
    #f3f2f2 100%
  );
  color: #ef1a1a;
  /* background-color: white; */
  border-radius: 2em;
  margin-right: 10px;
  font-size: 1.4em !important;
  padding: 0.08em;
  background-color: rgba(209, 209, 209, 1);
}