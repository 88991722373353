@import "../../../../styles/colors.scss";

.lesson-play-menu-item {
  .lesson-play-label {

    .record-data-to {
      font-size: 0.85em;
      color: $color-gray-monarch-website;
      font-weight: 400;
      padding-left: 5px;
      margin-bottom: 10px;
    }
  }
}