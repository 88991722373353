@import "../../../../../styles/colors.scss";

.cause-and-effect-activity-prompt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;

  .card-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    .card-container-placeholder {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .selected-card-container {
        width: 95%;
        height: 100%;
        background-color: $color-gray-monarch-website--light;
        border-radius: 10px;
        box-sizing: border-box;
        margin-top: 10px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .selected-card-container-inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
        }
      }
    }
  }

  .arrow {
    fill: $color-secondary;
    font-size: 3em;
    margin-left: 10px;
    margin-right: 10px;
  }

  .cause-and-effect-activity-card {
    cursor: default;
  }
}
