@import '../../../../styles/colors.scss';

.container-lesson-page-data-list {
  scroll-behavior: smooth;
  display: flex;
  align-items: center;
  padding: 1px 20px 1px 20px;

  .lesson-page-data-button-list {
    max-width: 120px;
  }

  .lesson-page-data-card {
   margin-right: 12px;
  }

  .lesson-page-data-card-selected {
    border: 3px solid $color-for-selection;
    box-sizing: border-box;
  }
  .lesson-page-data-card-wrapper {
    position: relative;
    outline: none;
  }

  .page-number {
    position: absolute;
    z-index: 1;
    margin-left: -12px;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4em;
    font-weight: 500;
    text-shadow: 2px 2px #CCCCCC;
    opacity: 0.65;
  }
  .lesson-page-data-card-delete-button {
    position: absolute;
    top: -8px;
    left: -8px;
    cursor: pointer;
    color: $color-medium;
    background-color: $color-light;
    border-radius: 25px;
    z-index: 2;
  }

  .lesson-page-data-dragged-item {
    opacity: 0.7;
  }

  .lesson-page-data-droppable {
    overflow-x: auto;
    display: flex;
    width: 100%;
    // Fix for safari issue
    font-size: calc(0.57em - 0px);
    box-sizing: border-box;
    padding: 8px 5px 5px 10px;
    -webkit-overflow-scrolling: touch !important;
  }

  .lesson-page-data-droppable > :last-child {
    .lesson-page-data-card {
      margin-right: 4px !important;
    } 
  }
  ::-webkit-scrollbar:horizontal {
    height: 0px !important;
    min-height: 0px !important;
  }
  
}