.selector-form {
  display: grid;
  grid-template-columns: 22% 30% auto;
  align-items: center;

  .label-container {
    display: flex;
    .label {
      font-weight: 400;
    }
  }

  .tooltip {
    margin-left: 10px;
  }
  .apply-button {
    width: 110px;
    margin-left: auto;
    height: 90%;
  }
  margin-bottom: 15px;
}
