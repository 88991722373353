@import "../../styles/colors.scss";

.sortable-table-component {
  display: flex;
  flex-direction: column;

  .sortable-table-container {
    position: relative;
    height: 100%;

    .sortable-table {
      height: 100%;
      overflow-x: hidden;
    }
    .table-cell {
      border-right: 1px solid $color-gray-monarch-website--light-fading;
      box-sizing: border-box;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 0.85em;
      position: relative;
      &:last-child {
        border-right: none;
      }
    }

    .clickable {
      transition: all 0.2s ease-in-out;
      // user-select: none;
      &:hover {
        background-color: lighten($color-primary, 40%);
        cursor: pointer;
      }
    }

    .Mui-selected {
      background-color: lighten($color-primary, 20%) !important;
      &:hover {
        background-color: $color-primary !important;
      }
    }
    tr:nth-child(even) {
      background-color: lighten($color-gray, 42%);
    }
  }
}

.loading-sortable {
  text-align: center;
  margin: 20px 0;
  position: absolute;
  top: 40%;
  left: 50%;
}
