@import "../../../../../styles/mixins.scss";

.card-activity {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0.8em;
  padding: 0.25em 0.1em 0.1em 0.2em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
  cursor: pointer !important;
  z-index: 1;
  &.disabled {
    cursor: default !important;
  }
  &.no-text {
    justify-content: center;
  }
  overflow: hidden;

  .MuiCardContent-root {
    padding: 10px 25px 10px 15px;
  }
  .media-player-icon {
    top: 5px;
    right: 5px;
  }
  .card-activity-image {
    width: 100%;
    background-size: contain;
    flex-grow: 1;
    &.relative {
      position: relative;
    }
  }

  .card-activity-text {
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    &.text-with-image {
      max-height: 67%;
    }
    &.only-text {
      max-height: 100%;
      padding-top: 0px;
      padding-bottom: 5px;
    }
  }

  .media-component {
    z-index: 100;
  }
  
  .drawing-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
