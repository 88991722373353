.wheel-spinner {
  width: 100%;
  height: 100%;
  margin: auto;

  .player-turn {
    display: flex;
    width: 82%;
    height: 100%;
    margin: auto;
    cursor: pointer;
    position: relative;
    .spinner-background {
      position: absolute;
      height: 100%;
      display: flex;
      width: 100%;
      z-index: -1;
      opacity: 0.25;
      left: 0px;
      div {
        margin:auto;
      }
    }

    .arrow-icon-container {
      position: relative;
      width: 90px;
      margin-right: 30px;
    }
    .arrow-icon {
      @keyframes arrowPoint {
        0% {
          left: 0px;
        }
        50% {
          left: -10px;
        }
        100% {
          left: 0px;
        }
      }
      position: absolute;
      top: 0px;
      width: 70px;
      bottom: 0;
      margin: auto;
      transition: all 0.5s ease-in-out;
      animation: arrowPoint normal 1.5s infinite ease-in-out; /* Opera */
    }
    .player-turn-icon {
      height: 100%;
      display: flex;
      img {
        height: 6em;
        margin-right: 20px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .player-turn-text {
      margin: auto;
      // font-size: 1.5em;
      font-weight: 500;
      word-break: break-word;
    }
  }

  .spinning-board {
    height: 100%;
    width: 100%;
    position: relative;
    img {
      padding: 10px;
      box-sizing: border-box;
      margin: auto;
      height: 100%;
    }

    .spinner-img {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    [class*="rotate"] {
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out;
    }

    .rotate-1 {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .rotate-2 {
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    .rotate-3 {
      -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: rotate(225deg);
    }

    .rotate-4 {
      -webkit-transform: rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: rotate(315deg);
    }

    .rotate-full {
      -webkit-transform: rotate(1080deg);
      -ms-transform: rotate(1080deg);
      transform: rotate(1080deg);
      transition: all linear 1500ms;
    }
    .board-outline {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
