@import "../../styles/components.scss";

.grid-list-container {
  @extend .list-with-gradient;
  padding: 10px;
  margin-bottom: 10px;
  h2 {
    font-weight: 500;
    font-size: 1.6em;
    white-space: normal;
    display: flex;
    align-items: center;
    margin-block-start: 0.6em;
    @media screen and (max-width: 1024px) {
      font-size: 1.8em;
    }
    .sub-title {
      font-weight: 500;
      font-size: 0.7em;
      margin-right: 15px;
      opacity: 0.5;
    }
  }

  .clickable-title {
    cursor: pointer;
    z-index: 1;
    &:hover {
      opacity: 0.5;
    }
  }

  .slider {
    position: relative;
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-list {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .slick-track {
      margin-left: unset;
      margin-right: unset;
      display: flex;
    }
  }

  .grid-list-navigate {
    font-size: 1.3em;
    vertical-align: middle;
    cursor: pointer;
    opacity: 1;
    color: black;
    z-index: 1;
  }

  .grid-list-navigate-previous {
    @extend .grid-list-navigate;
    transform: scaleX(-1);
  }

  .grid-list-navigate-next {
    @extend .grid-list-navigate;
    transform: scaleX(1);
  }

  .grid-list-fast-navigate {
    @extend .grid-list-navigate;
    font-size: 1.5em;
  }

  .grid-list-navigate-disable {
    cursor: default;
    opacity: 0.4;
  }
}
