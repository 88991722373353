.student-list-box {
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  .search-section {
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 16px;
  }
  .header-action-search-txtfield {
    width: 70%;
  }
  .search-result-existing-student {
    width: 100%;
    padding-left: 3%;
  }
}
