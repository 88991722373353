.curriculum-lessons-search-bar {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  .MuiSelect-select {
    background-color: white !important;
    border-radius: 5px !important;
    padding: 15px 35px 10px 20px;
    min-height: 20px;
    min-width: 120px;
  }

  .search-area {
    display: flex;
    .level-search {
      margin-right: 10px;
      min-width: 150px;
    }
  
    .matching-assessment-area-search {
      margin-right: 10px;
      min-width: 350px;
      max-width: 350px;
    }

    .vertical-assessment-area-search {
      margin-right: 10px;
      min-width: 250px;
      max-width: 250px;
    }
    .search-button {
      margin-left: 20px;
      min-width: 100px;
    }
  }

  .instructions {
    margin-top: 10px;
    font-weight: 500;
  }
}