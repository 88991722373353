.selected-student-list {
  .selected-student-list-paper {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .no-student-paper {
    padding-left: 0px;
  }
}
