@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.my-student-settings {
  margin: 10px;
  .student-setting-list {
    display: grid;
    grid-gap: 20px;
  }
  .MuiOutlinedInput-input {
    padding: 10px 10px;
  }
}
.student-setting-entry {
  margin-top: 25px;
  padding: 20px;
  box-sizing: border-box;
  @extend .vizzle-box-shadow;
  .student-setting-title-label {
    font-weight: 400;
    min-height: calc(1.8em + 5px);
    white-space: nowrap;
    font-size: 1.5rem;
    margin-right: 5px;
    .tooltip-with-indicator {
      height: 30px;
      width: 30px;
      font-size: 1.1rem;
    }
  }
}
