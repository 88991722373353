.student-lessons-row-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 45px;

  button {
    min-height: 40px;
  }

  .clear-selection {
    margin-left: -10px !important;
  }

  .select-all {
    margin-right: 0px;
  }
}