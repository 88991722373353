.lessons-assignment-v2-sortable-dialog {
  .MuiDialog-paperScrollPaper {
    max-height: unset;
    max-width: unset;
  }

  .custom-dialog-content {
    padding: 0px;
    height: 100vh;
  }
}
