@import "../../../styles/colors.scss";

.teacher-logout-dropdown {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  margin-right: 55px;

  .MuiLink-button {
    text-align: left;
  }

  .logout-link {
    text-transform: capitalize !important;
    color: $color-medium !important;
    font-weight: 400 !important;
    font-size: 1rem !important;

    @media screen and (min-width: 1600px) {
      font-size: 1rem !important;
    }
  
    @media screen and (min-width: 1800px) {
      font-size: 1.15rem !important;
    }
    
    .MuiSvgIcon-root {
      fill: $color-medium;
    }
  }

  .see-whats-new {
    margin-left: 26px;
  }
}

.teacher-logout-menu-list-popover {
  margin-top: 10px;
  margin-left: 20px;
}

.see-whats-news-dialog-container {
  .MuiPaper-root {
    max-width: none !important;
  }
}