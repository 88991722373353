@import "../../../../../../styles/colors.scss";

.chart-container {
  min-width: 300px;
  max-width: 300px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.clickable {
    cursor: pointer;
  }
  &.large-pie-chart {
    min-width: 350px;
    max-width: 350px;
    min-height: 340px;
  }

  &.line-chart {
    min-width: 380px;
    max-width: 380px;
    max-height: 400px;
  }

  @media screen and (max-width: 1024px) {
    &.large-pie-chart {
      min-width: 280px;
      max-width: 280px;
    }
    &.line-chart {
      min-width: 320px;
      max-width: 320px;
    }
  }

  h6 {
    color: $color-medium;
    font-weight: 500;
  }

  .highcharts-background {
    fill: none;
  }

  .no-data-container {
    justify-self: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    h5 {
      font-weight: 500;
      color: $color-medium;
    }
  }
  .chart-subtitle {
    font-weight: 700;
    font-size: 1.5em;
    font-family: "Rubik", "Open Sans", sans-serif;
  }
}
