.approved-for-courses-form {
  .assessment-radio-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .assessment-radio {
      white-space: nowrap;
    }
  }
}
