@import "../../../../../../styles/colors.scss";

.student-assignment {
  width: 800px;
  height: 100%;

  .actions-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: grid;
    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .user-name-link {
    color: $color-secondary;
    font-weight: 500;
  }

  .no-assignment {
    min-height: 50px;
    display: flex;
    align-items: center;
    h6 {
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
}