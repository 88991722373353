.edit-course-data-single-form {
  width: 100%;
  font-size: 0.8em;
  &.editable-form {
    max-width: 100%;
  }

  .single-field-form-textfield {
    width: 50vw;
  }
}