@import "../../../../styles/colors.scss";

.teacher-curriculum-stepper-container {
  height: 100%;
  position: relative;

  .teacher-curriculum-stepper {
    .side-menu {
      background-color: $color-medium;
      box-sizing: border-box;
      div {
        font-size: 1.2em;
      }
      .vertical-step-stepper-label {
        white-space: normal !important;
        text-align: left;
        font-size: 0.9em;
      }
    }
  }
}