@import '../../../../../styles/colors.scss';

.cause-and-effect-editor-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .card-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .arrow {
    fill: $color-secondary;
    margin-left: 5px;
    margin-right: 5px;
  }
}