.admin-school-form {
    display: flex;
    flex-direction: column;
    width: 400px;
    position: relative;
  
    .MuiFormControl-root {
      margin-top: 15px;
    }

    .share-student-checkbox {
      margin-top: 15px;
    }
    .form-school-school-ncesId {
      margin-top: 0px !important;
    }
  }