.fill-in-the-blank-editor {
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
  .answer-area {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px 15px 5px 15px;
    box-sizing: border-box;
    height: 40%;
    display: flex;
    flex-direction: column;
    .add-answer {
      display: flex;
      flex-direction: row;
      .text-field {
        width: 85%;
        margin-right: 10px;
      }
    }

    .no-answer-text {
      font-size: 0.95em;
    }
    .answers {
      margin-top: 10px;
      overflow-y: auto;
      box-sizing: border-box;
      .chip {
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }
  }

  .math-editor-button {
    width: 200px;
    margin-left: auto;
    margin-top: 5px;
  }
}