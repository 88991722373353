@import "../../../../../styles/colors.scss";

.feedback-loop-save-action {
  .toggle-disable-message {
    color: $color-bad;
    font-weight: 500;
  }
  .header {
    display: flex;
    .feedback-loop-tooltip {
      margin-left: 5px;

    }
  }
}

.feedback-loop-tooltip-dialog {
  video {
    min-width: 850px !important;
    max-width: 850px !important;
  }
}
