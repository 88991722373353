.generate-lessons-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 17%;
    width: 100%;
    height: 100%;
    .loading-icon {
      margin-bottom: 15px;
    }
  }

  .topic-template-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    box-sizing: border-box;
    // justify-content: center;
    padding: 0 5%;
  }

  .template-lesson-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 30px;
    margin-bottom: 20px;
    padding: 5px 10px;
    box-sizing: border-box;
    justify-content: center;
  }
  .template-lesson-card {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    // border: 1px solid black;
    // border-radius: 10px;
    width: 160px;
    height: 140px;
  }
}
