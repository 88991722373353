@import '../../../../styles/colors.scss';

.admin-tab-teachers {
  .search-district-action {
    min-width: 250px;
    max-width: 250px;
  }

  .teachers-table {
    .keyword-input {
      width: 250px;
    }
  }

  .filter-margin {
    margin-left: 5px;
  }
}