@import '../../../../styles/colors.scss';

.book-card-component {
  display: flex;
  height: 100%;
  width: 100%;
  
  .book-card {
    margin-left: 0.12em;
    margin-right: 0.12em;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
  
  .book-card-image {
    margin-bottom: 0.5em;
    background-size: contain;
    height: 100%;
    display: flex;
  }

  .book-card-text {
    align-items: center;
    padding: 0.4em 0.4em 0.4em 0.4em !important;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .book-card-padding {
    padding: 15px;
    margin-left: 0.52em;
    margin-right: 0.52em;
    box-sizing: border-box;
  }

  &.book-card-component-small {
    width: 55%;
  }

  &.book-card-component-big {
    width: 100%;
    padding: 15px;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: row;
    // .book-card-component {
    //   margin-left: 12px;
    //   margin-right: 12px;
    // }
  }
}

