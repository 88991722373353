@import "../../styles/colors.scss";

.text-editor-component-root {
  font-family: Quattrocento;
  text-align: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .text-editor-actions {
    display: flex;

    .actions-container {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.text-editor-component {
  width: 100%;
  height: 83%;
  margin-bottom: 10px;
  .text-editor-color-picker {
    height: auto;
    width: auto;
    padding-bottom: 0;
    top: -50px;
    left: -220px;
  }
  .text-editor-editor {
    height: 65%;
    margin-top: 20px;
  }

  .text-editor-font-size-display {
    min-width: 64px;
  }

  .text-editor-font-family-display {
    width: unset;
    min-width: 240px;
    .rdw-fontfamily-placeholder {
      max-width: unset;
      padding-right: 30px;
      min-width: 165px;
    }
  }

  .text-editor-font-family-list {
    width: max-content;
    padding: 10px 12px 10px 10px;
    .rdw-dropdownoption-default {
      padding: 5px 0px 5px 10px;
    }
  }

  // Override
  .DraftEditor-root {
    margin-top: 0px;
  }
  .public-DraftEditor-content {
    word-break: break-word;
  }
  .public-DraftStyleDefault-block {
    // Fix for safari, that the cursor does not appear when there is no text
    min-width: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .public-DraftEditor-content > :first-child {
    display: flex;
    flex-direction: column;
  }
  .rdw-option-active {
    background: $color-gray-monarch-website--light;
  }
  .rdw-right-aligned-block div {
    text-align: right !important;
  }

  .rdw-left-aligned-block div {
    text-align: left !important;
  }

  .rdw-center-aligned-block div {
    text-align: center !important;
  }

  .rdw-justify-aligned-block div {
    text-align: justify !important;
  }
}
