@import "../../../../../styles/colors.scss";

.teacher-activity-report-teachers {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;

  .no-data {
    padding-top: 10px;
  }
  .back-fab {
    background-color: $color-primary;
    color: $color-light;
    margin-right: 20px;
  }

  .teacher-activity-report-teachers-table {
    width: 620px;
    th {
      font-weight: 500;
    }
  }

  .student-subject-report-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .student-subject-report {
    display: grid;
    width: 100%;
    grid-template-columns: 80% 20%;
  }

  .student-list {
    color: $color-secondary;
    font-size: 0.85rem;
    &:hover {
      cursor: pointer;
      color: $color-primary;
      text-decoration: underline;
    }
  }
}

.teacher-activity-report-student-table-dialog {
  .MuiDialog-paperScrollPaper {
    height: 100%;
    width: 450px;
  }
}