.print-objective-detail-container {
  // max-width: 850px;
  // transform: scale(0.85);
  .name {
    margin-top: 20px;
    font-size: 25px;
  }
  .objective-detail-container {
    overflow-y: visible !important;
    background-color: white !important;
    padding: 20px 0px;
  }

  .objective-lesson {
    width: 95% !important;
  }

  .objective-lesson-play {
    font-size: 0.8em !important;
    page-break-inside: avoid;
  }

  .objective-lesson,
  .lesson-standards {
    page-break-before: always;
  }

  .no-lesson-played {
    font-size: 0.7em !important;
  }

  .graph-area {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 700px !important;
  }
}

@media print {
  html,
  body {
    height: 100%;
  }
}

@page {
  margin: 20mm 10mm !important;
  size: auto;
}
