@import '../../../styles/colors.scss';

.teacher-course-stepper-container {
	width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

	.content-panel {
		padding: 0;
		box-sizing: border-box;
		overflow: auto;
    overflow-y: auto;
    height: inherit;
		position: relative;
	}
	.inner-content-panel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
