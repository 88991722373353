@import '../../../../../styles/colors.scss';

.unit-guide-pdf-generator {
  &.loading-container {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .loading-spinner-loading {
      margin-top: 10%;
    }
  }

  &.unit-guide-pdf-container {
    width: 100%;
    height: 100%;

    .generated-unit-paper-container {
      padding: 0;
      margin-top: 10px;
      background-color: $color-gray-monarch-website--light-medium;
    }
  }
}

.generated-unit-container {
  @media print {
    @page {
      size: portrait;
    }
  }
  width: 100%;
}
