@import "../../styles/colors.scss";
.landing-top-app-bar {
  background-color: #64abdb !important;
  box-shadow: none !important;

  .toolbar {
    display: flex;
    justify-content: space-between;
  }

  .actions {
    display: flex;
    align-items: center;
  }
  .title {
    color: rgba(255,255,255,0.87);
    font-size: 25px;
  }
  .exit-button {
    min-height: 45px !important;
  }
  .rethink-button {
    min-height: 45px !important;
    color: $color-bad !important;
    font-weight: 500;
  }
}