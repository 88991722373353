.student-tracking {
  position: relative;
  width: 100%;
  height: 100%;
  .icon-button {
    width: 130px;
    margin-left: 10px;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .hide {
    display: none;
  }
  .tab {
    position: absolute;
    top: 0px;
    width: 100%;
  }

  .back-to-overview-top-bar {
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;
    display: flex;
    padding: 20px 10px;
    margin-bottom: 5px;
  }
}
