.apply-text-entry-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .row {
    display: flex;
    align-items: center;
    .label {
      min-width: 130px;
    }
  }
}