.teacher-curriculum-lessons-multi-selection-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  min-height: 45px;
  
  button {
    margin-right: 10px;
    min-height: 40px;
  }

  .clear-selection {
    margin-left: 10px;
  }
}