@import "../../../../../styles/colors.scss";

.categorizing-print-component {
  height: 100%;
  width: 100%;
  margin-top: -40px;
  position: relative;

  .categorizing-print-prompt {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-gap: 0px;
  }

  .flexible-column-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .prompt-panels-container {
    width: 100%;
    height: 100%;
  }

  .panel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 7px 12px;
    box-sizing: border-box;
  }

  .instruction-container {
    margin-top: 20px;
    height: 250px;
    align-items: baseline;
    .card-activity {
      flex-direction: column-reverse;
    }
  }

  .responses-container {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    display: grid;
    z-index: 10000000;
    grid-gap: 30px;

    .response-card-container {
      width: 100%;
      height: 100%;
    }
    .response-card-place-holder {
      min-height: 113px;
      max-height: 113px;
      height: 113px;
      position: relative;
    }

    &.large-gap {
      grid-row-gap: 24% !important;
    }

    &.categorizing-response-card-1 {
      grid-template-columns: 150px 150px;
      height: 50%;
      &.flatten-cards {
        grid-template-columns: 90%;
        height: 50%;
      }
    }
  
    &.categorizing-response-card-2 {
      grid-template-columns: 150px 150px;
      height: 50%;
      &.flatten-cards {
        grid-template-columns: 90%;
      }
    }

    &.categorizing-response-card-3, &.categorizing-response-card-4 {
      grid-template-columns: 150px 150px;
      height: 50%;

      &.flatten-cards {
        grid-template-columns: 90% !important;
        grid-row-gap: 5% !important;
        .response-card-place-holder {
          min-height: unset;
        }

        .card-activity {
          padding-top: 0px;
        }
        .card-activity-text {
          &.only-text {
            padding: 0;
          }
        }
      }
    }

    &.categorizing-response-card-3 {
      grid-template-rows: repeat(3, 30%);
    }

    &.categorizing-response-card-4 {
      grid-template-rows: repeat(3, 23%);
    }

    &.categorizing-response-card-5, &.categorizing-response-card-6 {
      grid-template-columns: 27% 27% 27%;
      grid-gap: 5%;
      height: 50%;

      &.flatten-cards {
        grid-template-columns: 50% 50%;
        height: 50%;
        grid-template-rows: repeat(3, 31%);
        grid-row-gap: 5% !important;
        .response-card-place-holder {
          min-height: unset;
        }

        .print-card-activity {
          padding-top: 0px;
        }
        .card-activity-text {
          &.only-text {
            padding: 0;
          }
        }
      }
    }

    &.categorizing-response-card-7, &.categorizing-response-card-8 {
      grid-template-columns: 21% 21% 21% 21%;
      grid-gap: 5%;
      height: 50%;
      &.flatten-cards {
        grid-template-columns: 50% 50%;
        grid-template-rows: repeat(4, 23%);
        .response-card-place-holder {
          min-height: unset;
        }

        .print-card-activity {
          padding-top: 0px;
        }
        .card-activity-text {
          &.only-text {
            padding: 0;
          }
        }
      }
    }
  }
  .prompt-container {
    justify-content: flex-start;
    &.two-columns {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .prompt {
      width: 100%;
      margin-bottom: 5px;

      &.prompt-1 {
        height: 200px;
      }
      &.prompt-2 {
        height: 200px;
      }
      &.prompt-3 {
        height: 160px;
      }
      &.prompt-4 {
        height: 140px;
      }
      &.prompt-5 {
        height: 110px;
      }
      &.prompt-6 {
        height: 100px;
      }
      &.prompt-7, &.prompt-8 {
        height: 130px;
        max-width: 47%;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }

  .categorizing-response-print-container {
    position: relative !important;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
  }

  .response-card-dragable-containr {
    width: 100%;
    height: 100%;
  }

  .selected-response-cards {
    height: 100%;
    width: 100%;
    background-color: $color-gray-monarch-website--light-fading;
    border-radius: 7px;
    z-index: 100;
    position: relative;
  }
}
