@import "../../../../styles/colors.scss";

.multiple-choice-component {
  height: 100%;
  width: 100%;
  display: flex;

  &.single-page {
    flex-direction: column;
    row-gap: 1em;
    .multiple-choice-component-question {
      height: 100%;
    }
    .multiple-choice-component-responses {
      height: 100%;
      row-gap: 0.6em;
    }
    .circle {
      margin-right: 1.1em;
    }
  }

  &.side-by-side {
    flex-direction: row;
    align-items: center;
    column-gap: 1.4em;

    .multiple-choice-component-question {
      height: 80%;
    }

    .multiple-choice-component-responses {
      height: 80%;
      row-gap: 1.8em;
    }

    .circle {
      margin-right: 0.5em;
    }
  }

  .multiple-choice-component-question {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .multiple-choice-component-responses {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
  }

  .multiple-choice-component-response-container {
    display: flex;
    align-items: center;
    .circle {
      width: 17px;
      height: 15px;
      border-radius: 10px;
      background-color: lightgray;
    }
    .multiple-choice-component-response {
      width: 100%;
      .multiple-choice-component-text {
        margin: 7px;
        width: 100%;
      }
    }
  }

  .multiple-choice-component-background {
    background: lightgray;
  }
}
