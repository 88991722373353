@import '../../../../styles/colors.scss';

.lesson-filter {
  .summary-panel {
    &.Mui-expanded {
      min-height: 25px;
    }
    .Mui-expanded {
      margin: 0px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 5px;
  }

  .keyword-input {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 220px;
    .input-field {
      padding-right: 40px;
    }
  }

  .clear-keyword-button {
    position: absolute;
    right: 0;
  }
  .clear-filter-button {
    margin-left: 10px;
    text-transform: unset;
    font-weight: 500;
    font-size: 1em;
    text-decoration: underline;
    white-space: nowrap;
    color: $color-medium;
  }
}
