.teacher-school-roster-access-dialog {
  width: 100%;
  height: 100%;
  .MuiDialogContent-root {
    padding-top: 0px;
    height: 100%;
    display: flex;
  }
  .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 10px);
    min-height: calc(100% - 10px);
    max-width: unset;
  }
}