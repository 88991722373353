@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.sequencing-activity {
  width: 100%;
  height: 100%;
  .prompt-cards-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4%;
    padding-bottom: 4%;
    box-sizing: border-box;
    background-color: lighten($color-primary, 20%);
    .prompt-cards {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .propmpt-container {
      width: calc(100% / 8);
      height: 100%;
      position: relative;
    }
  }

  .response-cards-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .response-cards {
      display: flex;
      justify-content: center;
      width: 100%;
      .response-card-place-holder {
        height: 100%;
        width: calc(100% / 8);
        position: relative;
      }
    }
  }

  .sequencing-selected-component {
    @extend .vizzle-selected-component;
    border-radius: 7px;
    width: 100%;
  }

  .sequencing-prompt-draggable {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50% 30%;
    grid-gap: 15%;
  }

  .sequencing-response-draggable {
    position: absolute !important;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    &:hover {
      @extend .vizzle-selected-component;
      border-radius: 7px;
    }
  }

  .sequencing-component-response-card-non-drag-items {
    transform: translate(0, 0) !important;
    transition: transform 1.2s !important;
  }

  .sequencing-component-reject-show {
    opacity: 1 !important;
  }

  .sequencing-component-reject-hide {
    opacity: 0 !important;
    transition: opacity 1.5s ease-in-out;
  }

  .sequencing-component-response-reject {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .sequencing-component-prompt-reject {
    @extend .sequencing-component-response-reject;
    border-radius: 7px;
    box-shadow: 0px 0px 3px 3px $color-bad, 0px 0px 2px 3px $color-bad, 0px 3px 2px -1px $color-bad !important;
  }

  .cards-2-margin {
    margin-left: 2.2rem;
    margin-right: 2.2rem;
  }
  .cards-3-margin {
    margin-left: 2.2rem;
    margin-right: 2.2rem;
  }
  .cards-4-margin {
    margin-left: 2.2rem;
    margin-right: 2.2rem;
  }
  .cards-5-margin {
    margin-left: 2.2rem;
    margin-right: 2.2rem;
  }
  .cards-6-margin {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
  .cards-7-margin {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
  .cards-8-margin {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}
