@import '../../../styles/colors.scss';

.image-video-uploader-component {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }

  .image-video-uploader-preview {
    margin-bottom: 15px;
    height: 85%;
    @media screen and (max-width: 1024px) {
      margin-bottom: 35px;
    }
  }
  
  .image-video-uploader-preview-card {
    width: 32vw;
    height: 90%;
    padding: 10px;
    position: relative;
  }

  .image-video-uploader-preview-card-action {
    position: relative;
    height: 100%;
  }

  .image-video-uploader-actions {
    display: flex;
    justify-content: center;
    margin-top: auto;
    .action-button {
      min-width: 90px;
    }
  }

  .image-preview {
    height: 100%;
    object-fit: contain;
  }
  .image-video-uploader-actions-inline {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 20px;
    width: 100%;
    .action-button {
      min-width: 90px;
    }
  }

  .image-video-uploader-drag-active {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $color-gray-monarch-website--light;
    opacity: 0.5;
    transition: 0.8s ease-in-out;
  }

}

