@import '../../styles/colors.scss';

.card-selector-component {
  display: flex;

  .card-selector {
    margin-right: 10px;
    box-sizing: border-box;
    position: relative;
  }

  .card-selector-action-area {
    position: absolute;
    height: 100%;
  }

  .card-selector-action-area-content {
    height: 85%;
    width: 90%;
    padding: 0;
    position: absolute;
    top: 7.5%;
    left: 5%;
    
  }

  .card-selected {
    border: 3px solid $color-for-selection;
    border-radius: 5px;
  }
}