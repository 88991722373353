@import '../../styles/colors.scss';

.no-lesson-container {
  padding: 20px;

  .no-lesson-container-message {
    color: $color-secondary;
    font-size: 1.6em;
    font-weight: 700;
  }
}