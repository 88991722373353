@import "../../../../../styles/colors.scss";

.student-lessons-multi-selection-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 45px;
  margin-left: auto;
  margin-right: 10px;

  button {
    min-height: 40px;
    margin-right: 10px;
  }

  .select-all {
    margin-right: 10px;
  }
}
