@import '../../../../styles/colors.scss';

.lesson-complete-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 0.5em;
  box-sizing: border-box;
  .message {
    color: $color-orange-secondary;
    font-weight: 400;
    font-size: 2em;
    margin-top: 0.5em;
  }

  .simple-celebration {
    width: 100%;
    margin-left: 0;
  }

  .close-button {
    text-transform: none;
    width: 220px;
    height: 42px;
    border-radius: 50px;
  }
}
