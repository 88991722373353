@import "../../../../styles/colors.scss";

.detailed-report {
  width: 100%;
  height: 100%;

  .criteria {
    .submit-button {
      margin-left: auto;
      margin-bottom: auto;
      margin-top: auto;
      min-width: 94px;
      max-width: 94px;
      height: 40px;
    }

    .place-holder-filled {
      width: 100%;
      margin-top: 5px;
    }

    .checkbox-filter-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .lesson-filter-checkbox {
      color: white;
      margin-right: 25px;
      padding-top: 0px !important;
      .MuiCheckbox-root {
        color: white;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
    .additional-criteria {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      width: 100%;

      label {
        z-index: 1;
        padding-left: 0px;
        padding-top: 13px;
      }
      .criteria-container {
        flex: 1;
        margin-right: 10px;
        &:last-child {
          margin-right: 0px;
        }
      }
      .criterial-selector {
        width: 100%;
        background-color: $color-light;
        margin-right: 10px;
        .MuiSelect-select {
          padding-top: 18px;
          padding-bottom: 6px;
        }
      }
    }
  }

  .result-tables {
    .student-report-table {
      margin-top: 10px;
    }
    .summary-table-container {
      margin-top: 20px;
      min-height: 50px;
      margin-bottom: 30px;
      .summary-table {
        width: 800px;
      }
      .table-footer-text {
        margin-top: 5px;
        font-weight: 500;
        color: $color-primary;
      }
    }
    .search-text {
      text-align: center;
      margin-top: 20px;
      font-weight: 500;
      font-size: 1.2em;
    }
  }
}
