.update-admin-teacher-panel {
  display: grid;
  grid-template-columns: 53% 46%;
  grid-column-gap: 1%;
  width: 1000px;

  .loading-teacher-data {
    grid-column: 1/-1;
    position: absolute;
    left: 48%;
    top: 43%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .loading-icon {
      margin-bottom: 20px;
    }
  }
}