@import '../../../styles/colors.scss';

.file-upload-placeholder {
  h6 {
    font-size: 1.1rem;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .file-upload-icon {
    color: $color-primary;
    font-size: 3em;
    opacity: 0.8;
  }

  .file-upload-sub-text {
    text-align: center;
  }

  .file-upload-sub-title {
    opacity: 0.7;
  }

  .file-upload-error {
    color: $color-bad;
    font-weight: 500;
  }
}