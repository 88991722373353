@import "../../../styles/colors.scss";

.text-to-speech-settings-form-v2 {
  width: 100%;
  max-width: 600px;
  min-width: 345px;

  .selector-grid {
    display: grid;
    align-items: center;
    grid-gap: 10px;
  }

  .selector-container {
    @extend .selector-grid;
    grid-template-columns: minmax(50px, 15%) minmax(100px, 60%);
  }

  .color-selector-container {
    @extend .selector-grid;
    grid-template-columns: minmax(50px, 15%) minmax(100px, 60%) auto;

    .tts-color {
      width: 40px;
      height: 40px;
      border-radius: 7px;
    }
  }

  .sample {
    display: flex;
    .sample-text {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      font-weight: 500;
    }
  }
}

.voice-list-style {
  .title {
    padding-left: 10px !important;
    font-weight: 700 !important;
    color: $color-medium !important;
  }
}

.tts-list-title {
  padding-left: 10px !important;
  font-weight: 600 !important;
  color: $color-medium !important;
}

.tts-list-item {
  &.MuiMenuItem-root {
    border-top: 1px solid $color-medium-light;
    .MuiListItemText-root {
      .MuiTypography-root {
        display: flex !important;
        align-items: center !important;
        .MuiSvgIcon-root {
          margin-left: 10px;
          color: $color-primary;
        }
      }
    }
    &:last-child {
      border-bottom: 1px solid $color-medium-light;
    }
  }
}
