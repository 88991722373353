.reset-password-comfirm-dialog {
  .reset-password-confirm {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }
  .vizzle-custom-dialog-actions {
    margin-top: 12px;
    justify-content: center;
  }
}