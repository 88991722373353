@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.assignment-lesson-for-ordering {
  display: grid;
  grid-template-columns: 25% 75%;
  height: 66px;
  border-radius: 5px;
  background-color: $color-light;
  align-items: center;
  border: 3px solid $color-gray;
  position: relative;
  overflow: hidden;

  .lesson-image {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    border-right: 2px solid $color-gray-monarch-website--light;
  }

  .lesson-name {
    font-size: 1em;
    padding: 0 0.8em;
    @include hideText(3);
  }

  .assign-leson-label {
    background: $color-tertiary;
    position: absolute;
    text-align: center;
    line-height: 30px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    width: 150px;
    font-size: 0.5em;
    left: -37px;
    top: 12px;
    -webkit-transform: rotate(-35deg);
  }
}
