@import "../../../../../styles/components.scss";
@import "../../../../../styles/colors.scss";

.play-mode-setting {
  .play-mode-dropdown-select {
    width: 250px;
  }

  .play-mode-radio-group {
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;

    .play-mode-radio {
      @extend .vizzle-box-shadow;
      border-radius: 4px;
      padding: 16px 8px;
      gap: 16px;
    }
    .label-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex-grow: 1;
    }
    .label-description {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
