.freeform-response-image-selector {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
  padding: 5px 10px;
  box-sizing: border-box;

  .image-selector {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 300px;
  }

  .file-upload-placeholder {
    padding: 0 10px;
    box-sizing: border-box;
  }
  .image-video-uploader-preview {
    height: 100%;
    margin-bottom: 0px;
  }
  .image-video-uploader-preview-card {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}