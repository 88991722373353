.report-table {
  .export-toolbar {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    div:nth-child(even) {
      margin-left: 15px;
    }
  }

  .header {
    margin-bottom: 15px;
  }
}

.report-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  p {
    margin-top: 15px;
    font-size: 1.2em;
    font-weight: 500;
  }
}