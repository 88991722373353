@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.searchable-group-selector {
  .searchable-group-selector-toolbar {
    background-color: $color-primary;
    .searchable-group-selector-title {
      color: $color-light;
    }
    .searchable-group-selector-radio-group {
      margin-left: 40px;
      flex-direction: row;
      color: $color-light;
    }
    .searchable-group-selector-search {
      width: 40%;
      padding-left: 20px;
      padding-right: 10px;
      display: flex;
      margin-left: auto;

      .searchable-group-selector-search-divider {
        width: 1px;
        height: inherit;
        margin: 4px;
      }
    }
  }

  .searchable-group-selector-dialog-content {
    padding: 8px 20px;
    max-height: 68.5vh;
  }

  .searchable-group-selector-content {
    min-height: 63.5vh;
    display: flex;
    position: relative;
  }
  .searchable-group-selector-fake-scroll-bar {
    margin-bottom: 6vh;
  }
  .searchable-group-selector-loading {
    margin: auto;
  }
  .searchable-group-selector-loading-more {
    position: absolute;
    bottom: -6vh;
    right: 0px;
    padding: 10px 10px 10px 20px;
    border-radius: 5px;
    background-color: white;
    z-index: 10;
    display: flex;
  }
  .searchable-group-selector-loading-more-text {
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .searchable-group-selector-loading-more-icon {
    margin-top: auto;
    margin-bottom: auto;
  }

  .searchable-group-selector-card-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .searchable-group-selector-card {
      width: 155px;
      height: 155px;
      margin: 10px;
      overflow: hidden;
      position: relative;
    }

    .searchable-group-selector-card-selected {
      box-sizing: border-box;
      border: 3px solid $color-for-selection;
      border-radius: 5px;
    }
    .searchable-group-selector-card-action-area {
      position: absolute;
      height: 100%;
    }
    .searchable-group-selector-card-action-area-content {
      height: 100%;
      width: 100%;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    
    svg.searchable-group-selector-card-image {
      display: flex;
      color: $color-primary;
      font-size: 3.5em;
      margin: auto;
    }

    .searchable-group-selector-card-image {
      height: 83%;
      background-size: contain;
    }
    .searchable-group-selector-card-description {
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 0.9em;
      text-align: center;
      @include hideText(1);
      line-height: 1.5em;
      display: block;
      white-space: nowrap;
    }
  }

  .load-more-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .load-more-action {
      min-width: 150px;
    }
  }
}