.login-with-clever-button {
  margin-top: 20px;
  display: flex;
  .login-with-clever-link {
    margin-left: 10px;
    background-image: url("../../../../static/images/clever/LogInWithClever.png");
    width: 207px;
    height: 49px;
    cursor: pointer;
    // transition: box-shadow 1s;

    box-shadow: 0 0 0.25rem #a8aeba;
    transition: all 0.25s ease-out;
    border-radius: 5px;
    &:hover {
      box-shadow: 0 0 0.25rem black;
      opacity: 0.8;
    }
  }
}
