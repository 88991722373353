@import "../../../../../../styles/colors.scss";

.admin-districts-selector-form {
  width: 1000px;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  position: relative;

  .school-selector-checkbox {
    padding: 0px;
    svg {
      fill: $color-secondary;
    }
  }

  .MuiTableRow-root > :first-child {
    padding: 2px !important;
  }
}