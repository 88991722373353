.selected-response-cards-component {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;

  .selected-response-display {
    font-size: 0.9em;
    border-radius: 7px;
    overflow: hidden;
    width: 30% !important;
    margin-left: 1.6%;
    margin-right: 1.6%;
    margin-top: 2%;
    height: 42.5%;
    @media screen and (min-width: 1025px) {
      font-size: 1em;
      margin-top: 0.5%;
      height: 47.5%;
    }
  }

  .selected-response-display-small {
    font-size: 0.8em;
    width: 22.5% !important;
    height: 40.5%;
    margin-top: 3%;
    margin-left: 1.2%;
    margin-right: 1.2%;
    overflow: hidden;
    border-radius: 8px;
    @media screen and (min-width: 1025px) {
      font-size: 0.9em;
      width: 22.5% !important;
      height: 47.5%;
      margin-top: 0.5%;
    }
  }
}
