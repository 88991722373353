@import '../../styles/colors.scss';

.vertical-step-container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .vertical-step-stepper-container {
    width: 18%;
    background-color: $color-secondary;
    transition: width 0.7s;
    position: relative;
    height: 100%;
    @extend .center;

    .stepper-inner {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .vertical-stepper-icon {
    border-radius: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    svg {
      font-size: 2.5em;
      color: $color-light;
    }
  }

  .vertical-step-collapse-action {
    position: absolute;
    right: -0.7em;
    padding: 5px;
    top: 50%;
    background-color: $color-primary !important;
    .vertical-step-collapse-icon {
      font-size: 1.2em;
      color: $color-light;
    }
    @media screen and (max-width: 1024px) {
      right: -0.7em;
    }
  }

  .vertical-step-content {
    width: 82%;
    overflow-y: auto;
    padding: 0px 20px 10px 30px;
    transition: width 0.7s;
  }

  .vertical-step-stepper {
    background-color: transparent;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 15px;
  }

  .vertical-step-icon {
    color: $color-light;
  }
  
  .vertical-step-selected-icon {
    border: 3px solid $color-primary;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 1px;
  }

  .vertical-step-stepper-label {
    color: $color-light !important;
    font-size: 1.3em;
    font-weight: 500;
    white-space: nowrap;
  }

  // Override
  .MuiStepConnector-lineVertical {
    border-left-style: none;
    margin-top: 22px;
    @media screen and (max-width: 1024px) {
      margin-top: 19px;
    }
  }
}


.vertical-step-container-collapsed {
  .MuiStepLabel-iconContainer {
    display: none;
  }
  .vertical-step-stepper-container {
    width: 5% !important;
    min-width: unset !important;
  }
  .vertical-step-stepper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .vertical-step-content {
    width: 95%;
  }
  .vertical-step-collapse-action {
    right: -0.75em;
  }
  .vertical-icon-step-selected {
    svg {
      color: $color-gray !important;
      transform: scale(1.2);
    }
  }
}