.free-text-response-tabs {
  &.full-height {
    height: 100%;
  }
  .tabs-container {
    margin-bottom: 20px;
  }

  .container {
    height: 225px;
    width: 700px;
  }

  .toggle-fullscreen {
    position: absolute;
    bottom: 2px;
    right: 5px;
    z-index: 100;
    width: 40px;
    box-shadow: 0px 0px 3px 1px #bbb;
    background-color: #fff;
    color: #000000;
    width: 35px;
    height: 35px;
  }
  .draw-canvas-container {
    // height: calc(100vh - 150px);
    height: 100%;
    width: calc(100vw - 100px);
    min-width: calc(100vw - 100px);
    position: relative;
    .drawing-board-container {
      height: 100% !important;
      width: 100% !important;
    }
  }
  .text-editor-container {
    margin-top: 10px;
    .text-editor-actions {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .images-selector-container {
    height: 490px;
    width: 900px;
    overflow-y: auto;
    .ptro-wrapper {
      bottom: 60px;
    }
    .ptro-bar {
      height: 60px;
    }

    .ptro-icon-btn {
      width: 53px;
      height: 53px;
    }
    .ptro-icon {
      font-size: 18px;
    }

    .ptro-info {
      display: none;
    }
    .ptro-bar-right {
      margin-left: auto;
    }
  }
}
