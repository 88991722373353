.lesson-pages-list {
  padding-top: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;

  .lesson-page-card {
    width: 300px;
    height: 300px;
    padding: 5px;
    &:hover {
      cursor: pointer;
      transform: scale(1.03);
    }
  }
}