@import "../../../../../../../styles/colors.scss";

.update-review-status-action {
  width: 240px;
  margin-right: 20px !important;
  text-transform: none !important;
  &:hover {
    background-color: $color-secondary !important;
  }
}

.update-review-status-action-dialog {
  .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 15px);
    max-width: 370px;
    min-width: 370px;
  }

  .title {
    font-size: 1.3em;
  }
  .review-warning {
    margin-top: 20px;
    color: $color-secondary;
    font-weight: 700;
    font-size: 1.1rem;

    div + div {
      margin-top: 10px;
    }
  }
}
