.copy-lesson-search-lesson-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .lesson-list-container {
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
    .lesson-list {
      position: absolute;
      width: 100%;
    }
  }

  .loading-spinner-container {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .action-button {
    width: 150px;
  }
}
