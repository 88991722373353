@import "../../../../styles/colors.scss";

.lesson-preview-dialog {
  .full-screen-dialog-toolbar {
    background-color: $color-blue-primary;
    min-height: 50px;
    color: white;
  }
  .MuiDialog-paperFullScreen {
    background-color: $color-blue-secondary;
  }

  .success-message-toolbar {
    .lesson-navigate-bottom-bar, .lesson-navigate-toolbar {
      background-color: $color-green !important;
    }
  }
}
