@import '../../../styles/colors.scss';

.audio-uploader-component {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    margin-top: 10px;
  }
  .audio-uploader-drop-area {
    // margin-bottom: 10px;
    height: 70%;
    @media screen and (max-width: 1024px) {
      margin-bottom: 35px;
    }
  }
  
  .audio-uploader-drop-area-card {
    height: 65%;
    margin: auto;
    padding: 10px;
  }

  .audio-uploader-drop-area-card-action {
    position: relative;
    height: 100%;
  }

  .audio-uploader-actions {
    display: flex;
    justify-content: center;
    margin-top: 55px;
    .action-button {
      min-width: 90px;
    }
  }

  .audio-uploader-audio-player {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    audio {
      width: 90%;
    }
  }

  .audio-uploader-drag-active {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $color-gray-monarch-website--light;
    opacity: 0.5;
    transition: 0.8s ease-in-out;
  }

}

