@import "../../../../../styles/colors.scss";

.lesson-plans {
  ul {
    list-style: none;
    padding-left: 10px;
    font-size: 1.4em;
    font-weight: 500;
  }

  li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  a {
    text-decoration: none;
    color: $branding-blue;
  }

  .icon {
    margin-right: 10px;
    font-size: 0.8em;
    color: $color-secondary;
  }

  .no-lesson-container {
    margin-top: 10px;
    padding-left: 0px;
  }
}