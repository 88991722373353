@import '../../styles/colors.scss';

.card-action {
  color: $color-secondary;
  font-size: 30px;
  padding: 5px;
  background-color: $color-light;
  margin: 3px 3px 3px 0px;
  margin-right: 3px !important;
  border-radius: 4px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}

.icon-hover {
  &:hover {
    transform: scale(1.1);
    opacity: 0.8;
  }
}

