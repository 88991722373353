@import '../../../../../styles/colors.scss';

.fill-in-the-blank-page-thumbnail-component {
  display: flex;
  width: 100%;
  height: 100%;

  .fill-in-the-blank-page-thumbnail {
    margin-left: 0.2em;
    margin-right: 0.2em;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .fill-in-the-blank-page-thumbnail-image {
    flex-grow: 1;
    background-size: contain;
  }

  .gap {
    height: 10px;
    width: 100%;
  }

  .small-gap {
    height: 3px;
    width: 100%;
  }

  .fill-in-the-blank-page-thumbnail-placeholder {
    flex-grow: 1;
    background: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .fill-in-the-blank-questions-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    &.small-padding {
      padding: 2px 5px;
      .text-lines-text-content {
        width: unset;
      }
    }
  }
}
