@import '../../../../styles/colors.scss';

.book-card-thumbnail-component {
  display: flex;
  height: 100%;
  width: 100%;

  :first-child {
    margin-left: 0 !important;
  }
  :last-child {
    margin-right: 0 !important;
  }

  .book-card-thumbnail {
    margin-left: 0.2em;
    margin-right: 0.2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 99%;
    position: relative;
  }

  .book-card-thumbnail-image {
    background-size: contain;
    height: 100%;
    display: flex;
    margin-top: 3px;
  }

  .book-card-thumbnail-text-background {
    background: lightgray;
  }

  .book-card-thumbnail-placeholder {
    height: 100%;
    background: lightgray;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    @media screen and (max-width: 1024px) {
      font-size: 0.75em;
    }
    margin-bottom: 0.3em;
    display: flex;
  }

  .book-card-thumbnail-text {
    align-items: center;
    padding: 0.4em 0.4em 0.4em 0.4em !important;
    justify-content: center;
    margin-bottom: auto;
    display: flex;
    height: 15%;
    min-height: 15px;
    @media screen and (max-width: 1024px) {
      font-size: 0.1em;
    }
  }

  .book-card-thumbnail-text-with-value {
    // display: flex;
    width: 100%;
    height: 100%;
    .text-lines-text-content {
      white-space: nowrap;
    }
    @media screen and (max-width: 1024px) {
      padding-left: 3px;
      padding-right: 3px;
      box-sizing: border-box;
    }
  }

  .book-card-thumbnail-placeholder-text {
    text-align: center;
  }
}