@import '../../../styles/colors.scss';

.teacher-assigned-student-course-filter {
  display: flex;
  align-items: center;
  .margin-right {
    margin-right: 10px;
  }

  .filter-lesson-combobox, .filter-student-combobox {
    max-width: 200px;
    margin-right: 10px;
  }
  .current-student-name {
    margin-right: 10px;
    font-size: 1.6em;
    font-weight: 500;
  }
}
