.reset-password-form {
  .content {
    .text-form {
      width: 100%;
    }
  }

  .actions {
    margin-top: 50px;
    width: 100%;
  }
}