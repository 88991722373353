$color-gray: #8d8a8c;
$color-gray--dark: darken($color-gray, 20%);
$color-gray--light: lighten($color-gray, 20%);

// $color-primary: #3b94d1;
$color-primary: #1565C0;
$color-secondary: #ee7b22;
$color-tertiary: #78b543;
$color-quaternary: #f0bb2c;
$color-blue-primary: #386dbd;
$color-blue-secondary: #cce0ff;
$color-green: #39bd39;
$color-orange-secondary: #FBAB30;

$color-good: $color-tertiary;
$color-bad: #ce3c3c;
$color-attention: $color-secondary;

$color-dark: #232323;
$color-medium: #444444;
$color-light: #ffffff;
$color-medium-light: #e0e0e0;

$color-icon: $color-gray;
$color-body: lighten($color-primary, 5%);
$color-header: lighten($color-primary, 10%);

$branding-blue: rgb(80, 159, 214);
$branding-blue-light: rgb(100, 171, 219);
$branding-orange: rgb(239, 124, 34);
$branding-bar-blue: #477eb7;

$color-gray-monarch-website: #545454;
$color-gray-monarch-website--dark: darken($color-gray, 20%);
$color-gray-monarch-website--light: lighten($color-gray, 20%);
$color-gray-monarch-website--light-medium: lighten($color-gray, 32%);
$color-gray-monarch-website--light-fading: lighten($color-gray, 40%);

$color-gradient-blue: #54bfff;

$color-for-selection: $color-secondary;

$color-pale-pink: #fdcdcd;
$color-pale-yellow: #ffdc9a;
$color-pale-orange: lighten($color-secondary, 20%);
