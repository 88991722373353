@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.sequencing-play-activity {
  width: 99%;
  height: 100%;
  margin-top: 20px;
  .prompt-cards-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    background-color: lighten($color-primary, 20%);
    .prompt-cards {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .prompt-container {
      width: calc(100% / 8);
      height: 100%;
      position: relative;
    }
  }

  .response-cards-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    .response-cards {
      display: flex;
      justify-content: center;
      width: 100%;
      .response-card-place-holder {
        width: calc(100% / 8);
        height: 100%;
        position: relative;
      }
    }
  }

  .sequencing-selected-component {
    @extend .vizzle-selected-component-medium;
    border-radius: 7px;
  }

  .sequencing-prompt-draggable {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50% 33.5%;
    grid-gap: 10%;
  }

  .sequencing-response-draggable {
    position: absolute !important;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    &:hover {
      @extend .vizzle-selected-component;
      border-radius: 7px;
    }
  }

  &.prompt-selected-response-cards-hover {
    .sequencing-activity-prompt {
      &:hover {
        @extend .vizzle-selected-component-medium;
        cursor: pointer;
      }
      .media-player-action-area {
        pointer-events: none;
      }
    }

    .sequencing-activity-response-card {
      &.vizzle-selected-component-medium {
        .media-player-action-area {
          pointer-events: none;
        }
      }
    }
  }

  .cards-2-margin {
    margin-left: 2.2rem;
    margin-right: 2.2rem;
    @media screen and (max-width: 1024px) {
      width: calc(100% / 5.5) !important;
    }
  }
  .cards-3-margin {
    margin-left: 2.2rem;
    margin-right: 2.2rem;
    @media screen and (max-width: 1024px) {
      width: calc(100% / 5.5) !important;
    }
  }
  .cards-4-margin {
    margin-left: 2.2em;
    margin-right: 2.2em;
    @media screen and (max-width: 1024px) {
      width: calc(100% / 5.5) !important;
    }
  }
  .cards-5-margin {
    margin-left: 2.2rem;
    margin-right: 2.2rem;
    @media screen and (max-width: 1024px) {
      margin-left: 1.2rem;
      margin-right: 1.2rem;
      width: calc(100% / 4) !important;
    }
  }
  .cards-6-margin {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    @media screen and (max-width: 1024px) {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      width: calc(100% / 4) !important;
    }
  }
  .cards-7-margin {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    @media screen and (max-width: 1024px) {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
      width: calc(100% / 4) !important;
    }
  }
  .cards-8-margin {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    @media screen and (max-width: 1024px) {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      width: calc(100% / 8) !important;
    }
  }
}
