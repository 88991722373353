@import '../../../../styles/colors.scss';

.lesson-activity-play {
  background-color: $color-blue-secondary;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    height: 100%;
    overflow: hidden;
    word-break: break-all;
  }
}