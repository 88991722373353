@import "../../styles/colors.scss";

.ms-copilot {
  position: absolute;
  bottom: 60px;
  right: 5px;
  z-index: 9999;

  .chat-button {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-width: 175px !important;
  }

  &.hide {
    display: none;
  }
}

.copilot-popup-container {
  position: relative;
  border: 1px solid #e0e0e0;
  .close-button {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}