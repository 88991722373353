.player-pieces {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 5px;
  padding: 5px;
  box-sizing: border-box;
  img {
    display: flex;
    z-index: 103;
    max-height: 4em;
    min-height: 2em;
    @media screen and (min-width: 1025px) {
      min-height: 1.8em;
      max-height: 4em;
    }
    margin: auto;
    transition: all 0.4s ease-in-out;
  }
  .inactive-player {
    opacity: 0.5;
  }
}