.text-selector-dropdown {
  width: 400px !important;
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10px;
  }
}

.text-selector-dropdown-small {
  width: 100px !important;
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10px;
  }
}
