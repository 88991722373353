@import '../../../styles/colors.scss';

.card-detail-container {
  .action-area {
    padding-top: 0;
    padding-bottom: 0;
    button {
      color: $color-medium;
      font-weight: 500;
    }
  }
  .content {
    padding-top: 0;
    overflow-x: hidden;
    border-radius: 10px;
  }
  .detail-navigator-button {
    position: absolute;
    top: 47%;
    z-index: 1;
    opacity: 1;
    background-color: $color-gray-monarch-website--light-fading;
    padding: 0px;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    svg {
      font-size: 2.3em;
      color: $color-medium;
      @media screen and (max-width: 1024px) {
        font-size: 3.6em;
      }
    }
    &.previous {
      left: 23px;
      transform: scaleX(-1);
    }
    &.next {
      right: 24px;
    }
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
      cursor: default;
    }
    &:hover {
      background-color: $color-gray-monarch-website--light;
    }
  }

  .card-detail-slider {
    position: relative;
    user-select: text;
  }
}
