@import "../../styles/colors.scss";

.chat-widget {
  position: absolute;
  bottom: 70px;
  right: 20px;
  .chat-container {
    bottom: 100px;
  }

  .badge-content {
    .MuiBadge-badge {
      background-color: $color-bad;
      color: white;
      width: 30px;
      height: 30px;
      padding: 8px;
      z-index: 99999999;
    }
  }
  .chat-button {
    z-index: 99999999;
    border-radius: 999px;
    padding: 13px;
    width: 60px;
    height: 60px;
  }

  &.chat-widget-button {
    position: static;
    .chat-button-button-mode {
      width: 160px;
      margin-right: 20px !important;
      text-transform: none !important;
      height: 45px;
    }
    .badge-content {
      .MuiBadge-badge {
        background-color: $color-bad;
        color: white;
        width: 30px;
        height: 30px;
        right: 20px;
        top: 10px;
      }
    }
  }
}

.chat-container {
  &.right-panel {
    .MuiPaper-root {
      right: 20px !important;
      left: unset !important;
    }

    .chat-panel {
      height: calc(100vh - 60px);
      max-height: unset;
    }
  }
  &.normal {
    margin-top: -30px;
  }
}
