@import "../../../../styles/colors.scss";
.classroom-report {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  
  .user-name-link {
    color: $color-secondary;
    font-size: 0.85rem;
    &:hover {
      cursor: pointer;
      color: $color-primary;
      text-decoration: underline;
    }
  }

  .submit-button {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
    min-width: 100px;
    height: 40px
  }

  .data-table {
    margin-top: 5px;
    flex-grow: 1;
    height: 100%;
    font-size: 1em;
  }

  .search-text {
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    font-size: 1.2em;
  }

  .additional-criteria {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 50%;

    label {
      z-index: 1;
      padding-left: 0px;
      padding-top: 13px;
    }
    .criteria-container {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
    .criterial-selector {
      width: 50%;
      background-color: $color-light;
      margin-right: 10px;
      .MuiSelect-select {
        padding-top: 18px;
        padding-bottom: 6px;
      }
    }
  }

  .date-from-to-form {
    margin-top: 0px;
    padding-top: 0;
    padding-bottom: 5px;
  }
}