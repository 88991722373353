.catorizing-card-thumbnail {
  display: flex;
  height: 100%;

  .catorizing-card-prompts {
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 0.2em;
    justify-content: center;
    @media screen and (min-width: 1025px) {
      margin-right: 0.3em;
    }

    .catorizing-card-prompt {
      flex-grow: 1;
      max-height: 50%;
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .catorizing-card-responses-container {
    display: flex;
    flex-direction: column;
    width: 49%;
    height: 100%;
    margin-right: 4px;
  }

  .instruction-thumbnail {
    max-height: 40%;
  }
  .instruction {
    background: lightgray;
    height: 100px;
    margin-right: 0.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .catorizing-card-responses {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
    .catorizing-card-response {
      width: 46%;
      max-height: 40%;
      margin: 0.2em;

      @media screen and (min-width: 1025px) {
        width: 47%;
      }
    }
    &.small {
      max-height: 100%;
      .catorizing-card-response {
        margin: 0.2em 0.1em;
        font-size: 0.6em;
        height: 50%;
        max-height: 100%;
      }
    }
  }

  .display-partial {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;

    .catorizing-card-response {
      height: 100% !important;
    }

    .display-partial-text {
      margin-bottom: 4px;
      margin-top: 4px;
    }

    .display-partial-prompt {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .display-partial-response {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
