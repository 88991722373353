@import "../../../../../styles/colors.scss";

.teacher-activity-report {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  .teacher-activity-report-inner {
    position: absolute;
    padding: 20px 30px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .teacher-activity-report-inner-container {
    display: flex;
    align-items: center;
  }
  .teacher-name-label {
    font-weight: 500;
    color: $color-medium;
  }

  .teacher-last-logged-in {
    margin-left: 20px;
    font-size: 1.2em;
  }
  .teacher-activity-report-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
  }

  .graphs-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .graphs-container-row {
    display: flex;
    justify-content: flex-start;
    &.left {
      margin-left: -30px;
    }
    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  .space {
    width: 50px;
  }

  .no-active-license {
    color: red !important;
    font-size: 1.1em;
    font-weight: 500;
    opacity: 0.8;
  }
}
