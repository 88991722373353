@import '../../../../../styles/colors.scss';

.game-interaction-popup {
  .content {
    box-sizing: border-box;
  }
  .close-icon {
    float: right;
    color: $color-primary;
    padding: 10px 20px;
    border-radius: 2px;
    background-color: lighten( $color-primary, 45% );
    cursor: pointer;
    position: absolute;
    right: 28px;
    top: 24px;
    z-index: 1102;
    &:hover {
      background-color: lighten( $color-primary, 35% );
    }
  }
  .content-component {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border: 4px solid;
    box-sizing: border-box;
    padding-top: 5px;
    overflow-y: hidden;
  }

  .book-card-popup {
    width: 99%;
    height: 99%;
    display: flex;
    margin: auto;
    > div {
      margin: 0px !important;
    }
    .media-player-icon {
      left: 10px;
    }
  }
}