.sequencing-thumbnail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5em;
  width: 100%;
  height: 100%;

  .card-area {
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
    div {
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .question-area {
    @extend .card-area;
  }

  .answer-area {
    @extend .card-area;
  }

  &.small-thumbnail {
    font-size: 0.5em;
    grid-gap: 1.2em;
    .card-area {
      grid-gap: 2px;
    }

    .thumbnail-card-small {
      @media screen and (max-width: 1024px) {
        font-size: 0.5em;
      }
    }
  }
}