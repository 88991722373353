@import "../../styles/colors.scss";

.lesson-navigate-component {
  // Fix for safari
  height: calc(100% - 100px);

  .lesson-navigate-slider div {
    outline: none;
    box-sizing: border-box;
  }

  .lesson-navigate-slider {
    height: 100%;
    padding: 10px 10px 10px 10px;
    width: 98%;
    flex-grow: 1;
    @media screen and (min-width: 1025px) {
      width: 100%;
    }
    margin: auto;
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-list,
    .slick-track,
    .slick-slide > div {
      height: 100%;
    }
    .slick-center {
      display: flex;
      justify-content: center;
    }

    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div {
      height: 100%;
    }

    .lesson-navigator-element-container {
      height: 100%;
      width: 99%;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
    }
  }

  .lesson-navigate-bottom-bar {
    box-shadow: none !important;
    .lesson-navigate-toolbar {
      background-color: $color-blue-primary;
      padding-left: 10px;
      padding-right: 10px;
      min-height: 50px;
    }

    .navigation-button {
      background-color: white !important;
      width: 90px;
      height: 40px;
      border-radius: 30px;
      font-size: 0.65em;
      &.navigation-button-mobile {
        &:hover {
          opacity: 1 !important;
        }
      }
    }

    .container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      gap: 10px;
    }
    .icon {
      font-size: 2.5em;
      color: $color-primary;
    }
    .right {
      margin-left: auto;
    }
  }
}
