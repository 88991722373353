/* autoprefixer: off */
.loader-dialog {
  .loader-container {
    padding: 2em;
    min-width: 200px;
    min-height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loader-container-no-padding {
    padding: 0px !important;
  }
  
  .loader-message {
    margin-top: 20px;
  }

  .rise-loader {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

