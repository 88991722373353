.assigned-students-courses {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;

  .assigned-student-course-table {
    width: 90%;
    .search-and-sortable-table-container {
      position: unset;
    }
  }

  .assigned-student-lesson-in-table {
    height: 85px;
    grid-template-columns: 25% 75%;
    width: 100%;
  }

  .current-assignments-lesson-actions {
    padding-right: 7px;
  }

  .actions-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: grid;
    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
