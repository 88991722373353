@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/components.scss';

.instructions-editor {
  font-size: 0.8em;
  margin-right: 5%;
  margin-top: -5px;
  border: none;
  padding: 0px;

  legend {
    font-weight: 500;
    word-wrap: none;
    white-space: nowrap;
  }
  .instructions-editor-card {
    height: 75%;
  }
  .instructions-editor-text {
    height: calc(54% - 3px);
    margin-bottom: 3px;
    @include hideText(1);
  }

  .instructions-editor-audio {
    height: 46.5%;
  }

  .instructions-editor-placeholder {
    background: lightgray;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.3em;
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
  }

  .instructions-editor-with-value {
    text-align: center;
    box-sizing: border-box;
    // width: 100%;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
  }

  .instructions-editor-with-audio {
    width: 100%;
    height: 100%;
    background-color: $color-gray-monarch-website--light-fading;
    .instructions-editor-with-audio-icon {
      color: $color-primary;
      font-size: 1.6em;
      margin: auto;
      width: 100%;
      margin-top: 2px;
    }
  }

  .instructions-editor-selected {
    @extend .editor-selected-card;
    .instructions-with-value-content {
      top: 0px;
    }

    .instructions-editor-with-audio-icon {
      margin-top: 0px;
    }
  }
}