@import '../../../styles/colors.scss';
.reinforcers-setting-container-v2 {
  .reinforcers-el-box {
    width: 17%;
    margin: 1%;
    border-radius: 2px;

    &:hover {
      transform: scale(1.1);
    }
  }
  .reinforcers-is-selected {
    box-shadow: 0 0 8px 4px $color-secondary;
  }
  .reinforcers-is-selected:hover {
    box-shadow: 0 0 16px 8px $color-secondary;
  }
}
