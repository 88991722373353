@import '../../styles/colors.scss';
@import '../../styles/components.scss';

.tab-menu-style {
  margin-top: 0px;
  z-index: 1;
  .MuiTab-root {
    min-width: 150px;
    font-weight: 400;
    &.Mui-selected {
      color: $color-primary;
      font-weight: 500;
    }
  }

  .MuiTabs-indicator {
    background-color: $color-primary;
  }

  @media screen and (max-width: 1024px) {
    .MuiTab-root {
      min-width: 120px;
    }
  }
}

.vizzle-tab-menu-style {
  margin-top: 0px;
  z-index: 1;
  padding-left: 10px;
  @extend .vizzle-box-shadow;

  .MuiTab-root {
    min-width: auto;
    font-weight: 400;
    text-transform: none;
    flex-grow: 0;
    white-space: nowrap;
    overflow: visible;
    padding: 12px 16px;
    &.Mui-selected {
      color: $color-primary;
      font-weight: 500;
    }
  }

  .MuiTabs-indicator {
    background-color: $color-primary;
  }

  @media screen and (max-width: 1024px) {
    .MuiTab-root {
      min-width: 120px;
    }
  }
}