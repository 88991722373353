@import "../../../styles/mixins.scss";

.text-lines-hide-long-text {
  font-size: 1.09em;
  @media screen and (max-width: 1024px) {
    font-size: 0.75em;
  }
}

.text-lines-text-content-line {
  width: 100%;
  span {
    display: contents;
    white-space: nowrap;
  }

  .math-live-container {
    pointer-events: none;
  }
}

.text-lines-text-content {
  margin: 0;
  overflow: hidden;
  min-height: 11px;
  word-break: break-word;
}

.match-live-text-container {
  opacity: 0;
  position: absolute;
}

math-field {
  margin-left: 0px !important;
  margin-right: 0px !important;
  display: inline-flex;
}
