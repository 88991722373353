@import '../../styles/colors.scss';

.lesson-selection {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  overflow: hidden;

  .transparent-background {
    z-index: 1;
    background-color: rgba(59,148,209,0.2) !important;
  }
  
  .student-lessons-summary-banner {
    position: fixed;
    top: 5px;
    left: 50%;
    right: 50%;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    white-space: nowrap;
  }

  canvas {
    &.switch-selected-component {
      box-shadow: none !important;
    }
  }
}

.lesson-complete-dialog {
  .title {
    margin: auto;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 10px 20px;
    .icon {
      font-size: 5em;
      color: $color-primary;
    }
    .message {
      margin-top: 20px;
      font-size: 1.5em;
    }
  }
}