.app-version {
  text-align: left;
  position: fixed;
  right: 10px;
  bottom: 10px;

  &.app-version-loading {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .spinner {
      margin-right: 10px;
    }
  }

  &.app-version-error {
    text-align: left;
  }
}

