@import "../../styles/colors.scss";

.teacher-course-skills-suite {
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
  	display: grid;
  	grid-template-columns: 340px calc(100% - 350px);
  	flex-grow: 1;
  }

  .search-panel {
		padding: 0 20px 20px 20px;
		box-sizing: border-box;
	}
  
  .subject-title {
    box-sizing: border-box;
    font-weight: 500;
    padding-top: 10px;
    padding-left: 20px;
  }

  .back-to-previous-container {
    z-index: 10;
    margin-top: 0px;
    margin-bottom: 15px;
  }
}
