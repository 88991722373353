.student-info-flexbox {
  width: 100%;
  .flex-student-setting-on-edit {
    margin-top: 20px;
    .action-button {
      min-width: 100px;
    }
    .margin-right {
      margin-right: 10px;
    }
  }

  .my-student-title {
    font-weight: 600;
  }

  .student-info-toggle {
    margin-bottom: 0px;
  }

  .dob-form-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    .dob-picker {
      width: 100%;
    }
    .rmdp-container {
      width: 100%;
    }
    .rmdp-input {
      width: 100%;
      height: 41px;
      font-size: 1em;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      background-color: white;
    }
  }

  .state-identifier-field {
    min-height: unset;
    // width: 30%;
  }

  .selector-dropdown-container {
    min-height: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    .selector-dropdown {
      width: 100%;
    }
  }
}
