@import "../../styles/colors.scss";

.hover-tooltip {
  .hover-tooltip-icon {
    font-size: 1.8em;
    color: $color-primary;
    background-color: white;
    border-radius: 15px;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 1024px) {
      font-size: 2.1em;
    }
  }
}

.hover-tooltip-custom-tooltip {
  .MuiTooltip-tooltip {
    background-color: white !important;
  }
}
