@import "../../styles/colors.scss";

.admin-home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px 15px;
    box-sizing: border-box;
    height: 100%;

    &.admin-data {
      padding: 5px 1px;
    }
  }
}
