@import '../../../styles/colors.scss';

.teacher-assigned-student-no-selected-date-message {
  position: relative;
  margin-bottom: 10px;
  .no-seleted-lessons-title {
    color: $color-secondary;
    text-align: left;
  }
}
