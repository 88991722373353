@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.multiple-choice-v2-activity-component {
  width: 98%;
  height: 100%;
  grid-column-gap: 2%;
  justify-items: center;
  padding-bottom: 5px;

  &.single-page {
    grid-template-rows: 63% 35%;
    .prompt-area {
      width: 50%;
    }
    .response-cards-container {
      width: 100%;
      margin-top: 0.2em;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
    }
    .multiple-choice-component-response-main-container {
      max-width: 27%;
    }

    .multiple-choice-2 {
      gap: 10%;
    }

    .multiple-choice-3 {
      gap: 6%;
    }
    .multiple-choice-4 {
      gap: 3%;
    }
  }

  &.side-by-side {
    justify-content: center;
  }

  &.stack-vertically {
    .multiple-choice-component-response-container {
      padding: 0px 0px 0px 10px;
    }
    .MuiPaper-root {
      overflow-y: hidden !important;
    }

    .rejected-answer-icon {
      font-size: 3em;
    }

    &.single-page {
      grid-template-rows: minmax(45%, 60%) auto;
      .response-area {
        width: 100%;
      }
      .response-cards-container {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 60%;
      }
      .multiple-choice-component-response-container {
        min-height: 40px;
        max-height: 45px;
      }
      .multiple-choice-component-response-main-container {
        max-width: 100%;
      }
    }

    &.side-by-side {
      justify-content: center;
      grid-column-gap: 1%;
      grid-template-columns: 55% 45%;

      .response-cards-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .multiple-choice-component-response-container {
        // height: auto;
        min-height: 50px;
        max-height: 80px;
        flex-grow: 1;
      }
    }

    .submitted-answer-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        font-size: 2.1em !important;
      }
    }
  }
  position: relative;

  .prompt-area {
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    .multiple-choice-component-question-card {
      .card-activity-text.only-text {
        padding-top: 7px;
      }
      min-height: 50%;
    }
    .multiple-choice-prompt-text,
    .multiple-choice-sub-text {
      max-height: 25%;
    }
    .multiple-choice-prompt-text {
      .MuiCardContent-root {
        padding: 10px 25px 10px 15px;
      }
    }
    .media-component {
      height: 100%;
      .media-player-icon {
        top: 5px;
        right: 5px;
      }
    }

    .multiple-choice-sub-text {
      .card-activity-text.only-text {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
  .prompt-cards-container {
    flex-grow: 1;
  }

  .multiple-choice-component-response-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 5px 6px 5px 5px;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;
    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-self: center;
    }
    .prefix {
      font-weight: 500;
      margin-right: 0px;
      white-space: nowrap;
    }

    .rejected-answer-response {
      width: calc(100% - 10px) !important;
      height: calc(100% - 16px) !important;
      top: 8px;
      left: 5px;
      .rejected-answer-icon {
        font-weight: 500;
      }
    }
  }

  .multiple-choice-component-response {
    height: 100%;
    position: relative;
    .card-activity-text.only-text {
      height: auto;
      padding-top: 5px;
    }
  }
}

.multiple-choice-v2-submit-answer-action {
  margin-left: auto !important;
  width: 160px;
  height: 40px;
}
