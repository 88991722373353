.container-with-side-bar {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 270px 10px 1fr;
  justify-items: start;

  .container-sidebar {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .container-content {
    width: 100%;
    height: 100%;
  }
}
