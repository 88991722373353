@import "../../../styles/colors.scss";

.table-selector {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  box-sizing: border-box;
  height: 100%;

  thead {
    display: none;
  }

  .keyword-input {
    width: 270px;
    .input-field {
      font-size: 0.9em;
    }
  }

  .MuiToolbar-root {
    padding: 0px;
  }

  .selector-table {
    flex-grow: 1;
    height: 100%;
  }

  .sortable-table {
    position: absolute;
  }

  .MuiTablePagination-actions {
    margin-left: 0px;
  }

  .MuiTablePagination-spacer {
    display: none;
  }

  .MuiTablePagination-toolbar {
    overflow-x: hidden;
    min-height: 25px;
    margin-top: 10px;
    margin-bottom: 5px;
    p {
      font-size: 0.9em;
    }
  }

  .MuiTablePagination-selectRoot {
    margin-right: 5px;
    margin-left: 5px;
  }

  .loading-sortable {
    position: absolute;
    margin: auto;
    left: calc(50% - 30px);
  }

  .MuiTableRow-root.Mui-selected {
    background-color: $color-secondary !important;
    &:hover {
      background-color: $color-secondary !important;
      opacity: 0.8;
    }
    td {
      color: white;
      font-weight: 500;
    }
  }

  .MuiTablePagination-root {
    overflow: hidden;
  }
}
