@import "../../../../../styles/colors.scss";
.selected-student-box {
 
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  box-sizing: border-box;
  border-radius: 6px;
  border: 2px solid $color-medium;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .student-box {
    display: flex;
    flex-direction: column;
  }
  .selected-student-subtitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    width: 200px;
  }

  .selected-student-cancel-icon {
    color: $color-bad;
    &:hover {
      transform: scale(1.5);
    }
  }
}
