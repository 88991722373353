@import '../../../styles/colors.scss';

.user-verify-success {
  .message {
    padding: 20px 10px;
    background: $color-tertiary;
    text-align: center;
    border-radius: 4px;
    color: white;
  }

  .actions {
    margin-top: 30px;
  }
}