@import '../../../../styles/colors.scss';

.student-lesson-summary {
  background-color: $color-tertiary;
  padding: 10px 50px;
  color: white;
  z-index: 2;
  border-radius: 3px;
}

.student-lesson-summary-expire-lessons {
  color: $color-secondary;
  font-size: 1.4rem;
  font-weight: 500;
  z-index: 2;
}