@import "../../../../../styles/colors.scss";

.my-lessons-row-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 45px;

  .select-all {
    margin-right: 0px;
    min-height: 40px;
    width: 100%;
  }

  .clear-selection {
    margin-left: 0px !important;
    margin-right: 15px;
    width: 100%;
  }

  .lessons-sort-radio {
    display: flex;
    flex-direction: row;
    div {
      margin-left: 0px !important;
    }
    .Mui-checked {
      .MuiCheckbox-root {
        color: $branding-orange !important;
      }
    }
  }

  .lesson-sort-dropdown-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .MuiSelect-outlined.MuiSelect-outlined {
      padding: 10px;
    }
    h5 {
      font-size: 1.4rem;
      white-space: nowrap;
    }
    .lesson-sort-dropdown {
      width: 200px;
      margin-right: 20px;
      padding: 5px 10px;
      margin-left: 0px !important;
      .MuiInputBase-root {
        background: white;
      }
      .MuiSelect-root {
        background: transparent;
      }
    }
  }
}
