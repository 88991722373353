.multiple-choice-activity-print {
  position: relative;
  width: 99%;
  height: 100%;
  &.side-by-side {
    height: 97%;
    width: 98% !important;
    max-height: 600px;
    .multiple-choice-component-question {
      height: 100%;
      width: 55%;
    }

    .multiple-choice-component-responses-form {
      width: 45%;
      padding: 0 15px;
      height: 100%;
    }
    .multiple-choice-component-responses {
      height: 100%;
      row-gap: 2.5em;

      .multiple-choice-component-response {
        min-height: 70px;
      }
    }
  }
  &.single-page {
    margin-left: auto;
    margin-right: auto;
    width: 650px !important;
    row-gap: 0em;
    background: white;
    border-radius: 5px;
    margin-top: -5px;
    .multiple-choice-component-question {
      padding: 15px 10px;
      box-sizing: border-box;
    }
    .multiple-choice-component-responses-form {
      height: 100%;
      padding: 0px 15px;
      margin-bottom: 5px;
      .multiple-choice-component-response {
        min-height: 45px;
      }
    }
  }

  .multiple-choice-component-responses-form {
    background: white;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .multiple-choice-component-response-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0 0 10px;
    box-sizing: border-box;
    .MuiFormControlLabel-root {
      width: 100%;
      margin-right: 0px;
    }
    .prefix {
      font-weight: 500;
      margin-right: 10px;
    }
  }
  .MuiFormControlLabel-label {
    width: 100%;
  }
  .only-text {
    padding-top: 7px !important;
  }
}

.multiple-choice-submit-answer-action {
  margin-left: auto !important;
  width: 150px;
  height: 50px;
}
