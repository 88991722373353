@import '../../../../styles/colors.scss';

.thumbnail-card {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;

  .card-key {
    position: absolute;
    left: 3px;
    top: 3px;
    background-color: white;
    padding: 0.2em 0.3em;
    color: $color-secondary;
    font-weight: 700;
  }

  .thumbnail-text {
    align-items: center;
    width: 100%;
    padding: 0.3em 0px 0.3em 0px !important;
    justify-content: center;
    margin-bottom: auto;
    display: flex;
    height: 20%;
    @media screen and (min-width: 1025px) {
      height: 17%;
    }
    background: lightgray;
    margin-top: 0.3em;
  }

  .thumbnail-text-with-value {
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 3px;
    padding-right: 3px;
    box-sizing: border-box;
  }

  .text-large {
    margin-top: 0px;
    height: 100%;
  }
  .thumbnail-image {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    margin-bottom: 0.1em;
    background-size: contain;
    display: flex;
  }

  .thumbnail-image-placeholder {
    height: 100%;
    width: 100%;
    background: lightgray;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    display: flex;
  }
}