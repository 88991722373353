@import '../../../styles/colors.scss';

.audio-recorder {

  .audio-recorder-title {
    text-align: center;
  }

  .audio-recorder-content {
    padding-top: 18px;
    width: 400px;
  }

  .audio-recorder-audio-player {
    display: flex;
    audio {
      width: 100%;
    }
  }

  .audio-recorder-time-elapsed-label {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    height: 22px;
  }

  .audio-recorder-action {
    display: flex;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .audio-recorder-icon {
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 1.4em;
    padding: 6px;
  }

  .audio-recorder-button {
    min-width: 120px;
    height: 36px;
  }

  .audio-recorder-start-recording-action {
    @extend .audio-recorder-action;
    &:hover {
      opacity: 0.7;
    }
  }

  .audio-recorder-icon-no-margin {
    margin-right: 0px;
    font-size: 1.65em !important;
  }

  .audio-recorder-stop-recording-action {
    @extend .audio-recorder-action;
    background-color: $color-bad !important;
    &:hover {
      opacity: 0.5;
    }
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes pulse{
    0%{
      box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
    }
    65%{
      box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
    }
    90%{
      box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
    }
  }
}