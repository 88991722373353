@import "../../../../styles/colors.scss";

.student-settings {
  .text-area {
    font-size: 1.2em;
    margin-top: 10px;
    @media screen and (max-width: 1024px) {
      font-size: 1.7em;
    }
    p {
      font-weight: 500;
      margin-top: 0.3em;
      margin-bottom: 0.5em;
    }

    .no-student-message {
      color: $color-bad;
    }
  }

  .setting-title {
    font-weight: 500;
    margin-bottom: 10px;
    .tooltip-with-indicator {
      height: 30px;
      width: 30px;
      font-size: 1.1rem;
    }
  }
  .setting-list {
    display: grid;
    grid-gap: 20px;
    .setting-entry {
      padding: 20px;
      box-sizing: border-box;
    }
  }

  .MuiOutlinedInput-input {
    padding: 10px 10px;
  }
}
