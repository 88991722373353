@import "../../../styles/colors.scss";

.course-subject-selector-menu {
  .course-subject-menu-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  .course-subject-menu-item {
    min-width: 250px;
  }
  .title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    flex-wrap: nowrap;
    padding-left: 0px;
  }

  .curriculum-menu-title {
    font-size: 0.8rem !important;
    display: flex;
    @media screen and (min-width: 1030px) {
      font-size: 0.9rem !important;
    }
  
    @media screen and (min-width: 1600px) {
      font-size: 1rem !important;
    }
  
    @media screen and (min-width: 1800px) {
      font-size: 1.15rem !important;
    }
  }
}