.react-lesson-play-disable {
  // display: none;
  pointer-events: none;
  * {
    pointer-events: none;
  }
}

.react-lesson-play-hide {
  display: none;
  // button, .lesson-card-content, .badges, .lesson-action-overlay {
  //   display: none;
  // }
}

.react-lesson-card-play-hide {
  button, .lesson-card-content, .badges, .lesson-action-overlay {
    display: none;
    opacity: 0;
    transition: all 2s ease-in-out;
  }
}
