@import '../../../../../../styles/colors.scss';
@import "../../../../../../styles/components.scss";

.categorizing-activity-prompt-card {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px 5px;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  .MuiCardContent-root {
    padding: 5px !important;
  }
  .prompt-detail {
    width: 22%;
    height: 100%;
  }

  .prompt-selected-response-cards {
    height: 100%;
    width: 75%;
    border-radius: 7px;
    z-index: 1;
    background-color: $color-gray-monarch-website--light-fading;;
  }
}