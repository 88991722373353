@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.cause-and-effect {
  width: 97%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;

  .cause-and-effect-prompt-draggable {
    width: 100%;
    height: 100%;
  }
  .cause-and-effect-area-container {
    width: 100%;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .cause-and-effect-area {
      width: 90%;
      height: 100%;
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      grid-column-gap: 2em;
      grid-row-gap: 1em;

      &.cause-length-1 {
        grid-template-columns: auto;
        width: 50%;
        height: 60%;
      }

      &.cause-length-2 {
        width: 42%;
        grid-template-columns: auto;
      }
    }

    .prompt-container {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .cards-area-container {
    margin-top: 1em;
    width: 100%;
    height: 30%;
    .cards {
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
    }

    .cause-and-effect-card-place-holder {
      height: 100%;
      width: calc(100% / 8);
      position: relative;
      > div {
        height: 100%;
      }
    }

    .card-margin-2-cards {
      margin-left: 2.2rem;
      margin-right: 2.2rem;
    }

    .card-margin-4-cards {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    .card-margin-6-cards {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    .card-margin-8-cards {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
    }
  }

  .cause-and-effect-response-card-container {
    position: absolute !important;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left:0 !important;
    &:hover {
      @extend .vizzle-selected-component;
    }
  }
  .cause-and-effect-selected-component {
    @extend .vizzle-selected-component-medium;
    border-radius: 7px;
  }
}
