/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 29, 2015 */
@font-face {
    font-family: "KG Primary Penmanship";
    src: url('../static/fonts/kgprimarypenmanship-webfont.eot');
    src: url('../static/fonts/kgprimarypenmanship-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/kgprimarypenmanship-webfont.woff2') format('woff2'),
         url('../static/fonts/kgprimarypenmanship-webfont.woff') format('woff'),
         url('../static/fonts/kgprimarypenmanship-webfont.ttf') format('truetype'),
         url('../static/fonts/kgprimarypenmanship-webfont.svg#kg_primary_penmanshipregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KG Primary Penmanship Lined';
    src: url('../static/fonts/kgprimarypenmanshiplined-webfont.eot');
    src: url('../static/fonts/kgprimarypenmanshiplined-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/kgprimarypenmanshiplined-webfont.woff2') format('woff2'),
         url('../static/fonts/kgprimarypenmanshiplined-webfont.woff') format('woff'),
         url('../static/fonts/kgprimarypenmanshiplined-webfont.ttf') format('truetype'),
         url('../static/fonts/kgprimarypenmanshiplined-webfont.svg#kg_primary_penmanship_linedRg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KG Primary Dots Lined';
    src: url('../static/fonts/kgprimarydotslined-webfont.eot');
    src: url('../static/fonts/kgprimarydotslined-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/kgprimarydotslined-webfont.woff2') format('woff2'),
         url('../static/fonts/kgprimarydotslined-webfont.woff') format('woff'),
         url('../static/fonts/kgprimarydotslined-webfont.ttf') format('truetype'),
         url('../static/fonts/kgprimarydotslined-webfont.svg#kg_primary_dots_linedregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Alegreya';
    src: url('../static/fonts/alegreya-regular-webfont.eot');
    src: url('../static/fonts/alegreya-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/alegreya-regular-webfont.woff2') format('woff2'),
         url('../static/fonts/alegreya-regular-webfont.woff') format('woff'),
         url('../static/fonts/alegreya-regular-webfont.ttf') format('truetype'),
         url('../static/fonts/alegreya-regular-webfont.svg#alegreyaregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Alegreya';
    src: url('../static/fonts/alegreya-bold-webfont.eot');
    src: url('../static/fonts/alegreya-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/alegreya-bold-webfont.woff2') format('woff2'),
         url('../static/fonts/alegreya-bold-webfont.woff') format('woff'),
         url('../static/fonts/alegreya-bold-webfont.ttf') format('truetype'),
         url('../static/fonts/alegreya-bold-webfont.svg#alegreyabold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Alegreya';
    src: url('../static/fonts/alegreya-italic-webfont.eot');
    src: url('../static/fonts/alegreya-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/alegreya-italic-webfont.woff2') format('woff2'),
         url('../static/fonts/alegreya-italic-webfont.woff') format('woff'),
         url('../static/fonts/alegreya-italic-webfont.ttf') format('truetype'),
         url('../static/fonts/alegreya-italic-webfont.svg#alegreyaitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Alegreya';
    src: url('../static/fonts/alegreya-bolditalic-webfont.eot');
    src: url('../static/fonts/alegreya-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/alegreya-bolditalic-webfont.woff2') format('woff2'),
         url('../static/fonts/alegreya-bolditalic-webfont.woff') format('woff'),
         url('../static/fonts/alegreya-bolditalic-webfont.ttf') format('truetype'),
         url('../static/fonts/alegreya-bolditalic-webfont.svg#alegreyabold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Josefin Slab';
    src: url('../static/fonts/josefinslab-regular-webfont.eot');
    src: url('../static/fonts/josefinslab-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/josefinslab-regular-webfont.woff2') format('woff2'),
         url('../static/fonts/josefinslab-regular-webfont.woff') format('woff'),
         url('../static/fonts/josefinslab-regular-webfont.ttf') format('truetype'),
         url('../static/fonts/josefinslab-regular-webfont.svg#josefin_slabregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Josefin Slab';
    src: url('../static/fonts/josefinslab-bold-webfont.eot');
    src: url('../static/fonts/josefinslab-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/josefinslab-bold-webfont.woff2') format('woff2'),
         url('../static/fonts/josefinslab-bold-webfont.woff') format('woff'),
         url('../static/fonts/josefinslab-bold-webfont.ttf') format('truetype'),
         url('../static/fonts/josefinslab-bold-webfont.svg#josefin_slabbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Josefin Slab';
    src: url('../static/fonts/josefinslab-italic-webfont.eot');
    src: url('../static/fonts/josefinslab-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/josefinslab-italic-webfont.woff2') format('woff2'),
         url('../static/fonts/josefinslab-italic-webfont.woff') format('woff'),
         url('../static/fonts/josefinslab-italic-webfont.ttf') format('truetype'),
         url('../static/fonts/josefinslab-italic-webfont.svg#josefin_slabitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Josefin Slab';
    src: url('../static/fonts/josefinslab-bolditalic-webfont.eot');
    src: url('../static/fonts/josefinslab-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/josefinslab-bolditalic-webfont.woff2') format('woff2'),
         url('../static/fonts/josefinslab-bolditalic-webfont.woff') format('woff'),
         url('../static/fonts/josefinslab-bolditalic-webfont.ttf') format('truetype'),
         url('../static/fonts/josefinslab-bolditalic-webfont.svg#josefin_slabbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Quattrocento';
    src: url('../static/fonts/quattrocentosans-regular-webfont.eot');
    src: url('../static/fonts/quattrocentosans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/quattrocentosans-regular-webfont.woff2') format('woff2'),
         url('../static/fonts/quattrocentosans-regular-webfont.woff') format('woff'),
         url('../static/fonts/quattrocentosans-regular-webfont.ttf') format('truetype'),
         url('../static/fonts/quattrocentosans-regular-webfont.svg#quattrocento_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quattrocento';
    src: url('../static/fonts/quattrocentosans-bold-webfont.eot');
    src: url('../static/fonts/quattrocentosans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/quattrocentosans-bold-webfont.woff2') format('woff2'),
         url('../static/fonts/quattrocentosans-bold-webfont.woff') format('woff'),
         url('../static/fonts/quattrocentosans-bold-webfont.ttf') format('truetype'),
         url('../static/fonts/quattrocentosans-bold-webfont.svg#quattrocento_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Quattrocento';
    src: url('../static/fonts/quattrocentosans-italic-webfont.eot');
    src: url('../static/fonts/quattrocentosans-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/quattrocentosans-italic-webfont.woff2') format('woff2'),
         url('../static/fonts/quattrocentosans-italic-webfont.woff') format('woff'),
         url('../static/fonts/quattrocentosans-italic-webfont.ttf') format('truetype'),
         url('../static/fonts/quattrocentosans-italic-webfont.svg#quattrocento_sansitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Quattrocento';
    src: url('../static/fonts/quattrocentosans-bolditalic-webfont.eot');
    src: url('../static/fonts/quattrocentosans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/quattrocentosans-bolditalic-webfont.woff2') format('woff2'),
         url('../static/fonts/quattrocentosans-bolditalic-webfont.woff') format('woff'),
         url('../static/fonts/quattrocentosans-bolditalic-webfont.ttf') format('truetype'),
         url('../static/fonts/quattrocentosans-bolditalic-webfont.svg#quattrocento_sansbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../static/fonts/OpenSans-Regular-webfont.eot');
    src: url('../static/fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../static/fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('../static/fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../static/fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../static/fonts/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../static/fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../static/fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../static/fonts/Rubik/Rubik-Italic-VariableFont_wght.ttf') format('truetype');
    font-style: italic;
}