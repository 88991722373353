@import "../../../../../../../styles/colors.scss";

.lesson-assignment-ordering {
  height: 100%;
  .selected-lesson-component {
    border-color: $color-secondary;
  }
  h6 {
    color: $color-secondary;
  }
  span {
    color: $color-secondary;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .order-lessons {
    display: grid;
    grid-row-gap: 10px;
    overflow-y: auto;
    align-content: baseline;
    height: 100%;
  }

  .lesson-assigment-container-container {
    height: inherit;
    display: flex;
    flex-direction: column
  }

  .assign-leson-label {
    font-size: 7px;
  }
}