@import '../../../styles/components.scss';
@import '../../../styles/colors.scss';

.teacher-assigned-student-courses-panel {
  @extend .list-with-gradient;
  padding-top: 10px;
  box-sizing: border-box;
  position: relative;

  .assignment-calendar-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    .assignment-calendar-title {
      font-weight: 500;
      font-size: 1.6em;
      white-space: nowrap;
      margin-block-start: 0.6em;
      margin: 0px 8px 0px 0px !important;
    }
  }

  .teacher-assigned-student-courses-container {
    position: relative;
    &.fading {
      opacity: 0.4;
    }
  }
}
