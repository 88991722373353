.accessibility-component {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;

  .accessibility-component-tts {
    flex-shrink: 0;
  }
  .accessibility-components {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
