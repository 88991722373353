.sorting-card-thumbnail {
  display: flex;
  height: 100%;

  .sorting-card-prompts {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 0.2em;
    @media screen and (min-width: 1025px) {
      margin-right: 0.3em; 
    }

    .sorting-card-prompt {
      height: 50%;
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .sorting-card-responses {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .sorting-card-response {
      width: 46%;
      height: 23%;
      margin: 0.2em;

      @media screen and (min-width: 1025px) {
        width: 47%;
      }
    }

    .sorting-thumbnail-placeholder {
      height: 69%;
      font-size: 0.8em;
    }

    .sorting-thumbnail-text {
      font-size: 0.8em;
    }
  }

  .sorting-thumbnail-text {
    align-items: center;
    padding: 0.3em 0.4em 0.4em 0.4em !important;
    justify-content: center;
    margin-bottom: auto;
    display: flex;
    height: 20%;
    @media screen and (min-width: 1025px) {
      height: 17%;
    }
    background: lightgray;
  }

  .sorting-thumbnail-image {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    // position: absolute;
    margin-bottom: 0.1em;
    background-size: contain;
    display: flex;
  }

  .sorting-thumbnail-placeholder {
    height: 75%;
    background: lightgray;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    margin-bottom: 0.3em;
    display: flex;
  }

  .small-thumbnail {
    .sorting-thumbnail-placeholder {
      height: 100%;
      content: none;
    }
  }

  .sorting-card-responses .small-thumbnail  {
    font-size: 0.6em;
  }

  .sorting-card-response.small-thumbnail {
    width: 45%;
    font-size: 0.32em;
    @media screen and (min-width: 1025px) {
      width: 44%;
      font-size: 5px;
    }
  }

  .sorting-card-prompts .small-thumbnail  {
    font-size: 0.63em;
    @media screen and (min-width: 1025px) {
      font-size: 0.9em;
    }
  }
}
