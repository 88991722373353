.categorizing-selected-activity-response-cards-component {
  width: 100%;
  height: 100%;
  padding: 5px 5px 10px 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.container-flatten-cards {
    flex-direction: column;
    width: 100%;
  }

  .selected-response-display {
    font-size: 0.9em;
    border-radius: 7px;
    overflow: hidden;
    width: 30% !important;
    margin-left: 1.6%;
    margin-right: 1.6%;
    margin-top: 2%;
    position: relative;
    @media screen and (min-width: 1025px) {
      font-size: 1em;
      margin-top: 0.5%;
    }
  }

  .row-height-by-prompt {
    height: 100%;
    flex-grow: 1;

    &.flatten-cards {
      width: 97% !important;
      height: 10% !important;
    }
  
    &.row-height-by-answers-length-4 {
      height: 50%;
      min-width: 45%;
    }

    &.row-height-by-answers-length-5,
    &.row-height-by-answers-length-6 {
      height: 50%;
      width: 31%;
    }

    &.row-height-by-answers-length-7,
    &.row-height-by-answers-length-8 {
      height: 50%;
      width: 20% !important;
      &.flatten-cards {
        width: 97% !important;
      }
    }

    &.small {
      height: 100%;
      min-width: unset;
    }
  }
}
