@import '../../../styles/colors.scss';

.terms-of-service {
  .MuiPaper-root {
    max-width: calc(100% - 100px);
    max-height: calc(100% - 100px);
  }
  .MuiDialogContent-root {
    padding-top: 0px;
  }
  .already-accepted {
    text-align: center;
    margin-bottom: 10px;
  }

  .content {
    a {
      color: $color-secondary;
      text-decoration: none;
    }
    p, div, section {
      text-align: justify;
      font: 400 14px/20px Rubik,"Helvetica Neue",Helvetica,Arial,sans-serif;
    }
    b {
      font-weight: 500;
    }
    h1 {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      position: sticky;
      top: 0px;
      margin-top: 0px;
      background: white;
      padding-bottom: 10px;
    }

    .content-red {
      color: red;
    }

    .margin-top-remove {
      margin-top: 0px;
    }

    .margin-bottom-remove {
      margin-bottom: 0px;
    }

    .margin-top-large {
      margin-top: 50px;
    }

    .margin-bottom {
      margin-bottom: 15px;
    }
  
    .margin-bottom-large {
      margin-bottom: 50px;
    }
  
    .underline {
      text-decoration: underline;
    }

    .content-capitalize {
      text-transform: capitalize;
    }
  }

  .action-button {
    min-width: 153px;
  }
  .accept-button {
    margin-right: auto;
  }
}
