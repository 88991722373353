@import '../../../../../styles/colors.scss';
@import '../../../../../styles/components.scss';

.matching-component-response-card {
  position: absolute !important;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0 !important;
  left:0 !important;
  &:hover {
    @extend .vizzle-selected-component;
  }
}