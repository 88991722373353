.admin-district-form {
  display: flex;
  flex-direction: column;
  width: 400px;

  .MuiFormControl-root {
    margin-top: 15px;
    label {
      line-height: 1.7375em;
    }
  }

  .checkbox-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}