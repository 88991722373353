.objective-card-getting-started {
  display: flex;
  flex-direction: row;
  flex-direction: column;
  position: relative;

  h2 {
    padding-top: 0px;
    margin-top: 0px;
  }

  .top-bar {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    display: flex;
    padding: 20px 10px;
  }
  .objective-card-container {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
  }
  .objective-card {
    position: relative;
    opacity: 0.7;
    h2 {
      font-weight: 500;
      font-size: 1.6em;
      white-space: nowrap;
      @media screen and (max-width: 1024px) {
        font-size: 1.8em;
      }
    }

    .grid-list-navigate {
      font-size: 1.3em;
      vertical-align: middle;
      cursor: pointer;
      opacity: 1;
      color: black;
    }

    .grid-list-navigate-previous {
      @extend .grid-list-navigate;
      transform: scaleX(-1);
    }

    .grid-list-navigate-next {
      @extend .grid-list-navigate;
      transform: scaleX(1);
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .objective-video {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 33%;
    @media screen and (max-width: 1024px) {
      padding-right: 0;
    }

    box-sizing: border-box;
    video {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }
}
