@import "../../../../../styles/colors.scss";
@import "../../../../../styles/components.scss";

.matching-prompt-activity-container {
  height: 100%;
  width: 100%;

  .matching-prompt-activity {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
  }
  .matching-prompt-activity-prompt-area {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .matching-prompt-activity-prompt-area-question-container {
    height: 100%;
    display: grid;
    grid-template-columns: 55% 45%;
    padding: 10px;
    box-sizing: border-box;
    width: 57%;
    margin: auto;
    position: relative;
    z-index: 1;
    &:hover {
      @extend .vizzle-selected-component;
      border-radius: 5px;
      @media screen and (min-width: 1024px) {
        box-shadow: none !important;
      }
    }
    &.clickable {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .matching-prompt-activity-image {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background-size: contain;
    height: 100%;
    display: flex;
  }

  .matching-prompt-activity-text {
    align-items: center;
    padding: 0.4em 0.4em 0.4em 0.4em !important;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .matching-prompt-activity-area-question-video {
    width: 25%;
    margin-right: auto;
    display: flex;
    position: relative;
    height: 100%;
    background: transparent;
    box-shadow: none;
    overflow: visible;
    video {
      border-radius: 5px;
    }
  }

  .matching-prompt-activity-area-question {
    height: 100%;
    width: 100%;
  }

  .matching-prompt-activity-area-question-placeholder {
    height: 100%;
    width: 97%;
    margin-left: 10px;
    overflow: visible;
    position: relative;
  }

  .matching-prompt-activity-media-player-icon {
    font-size: 2em;
    color: $color-primary;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 3px;
    background-color: white;
    border-radius: 15px;
  }

  .media-component {
    z-index: 100;
  }
}
