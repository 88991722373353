.game-player-setup {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  .name-input {
    width: 50%;
  }

  .piece-selector {
    width: 50%;
  }

  .piece-icon {
    display: none;
  }
}

.player-piece-selector-pop-over {
  top: 30px !important;
}