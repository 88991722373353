@import "./colors.scss";


.main-with-background-container {
  background-color:#FFFFFF;
  min-height: 100vh;
}

.main-with-blue-background {
  background-color: $color-primary;
  min-height: 100vh;
}

.main-with-full-height {
  min-height: 100vh;
}

.blue-gradient {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, $color-gradient-blue 0%, #ffffff 18%, #ffffff 100%);
    opacity: 0.3;
  }
}

.blue-gradient-small {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, $color-gradient-blue 0%, #ffffff 70px, #ffffff 100%);
    opacity: 0.3;
  }
}

.list-with-gradient {
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
  background-size: cover;
  display: block;
  align-items: center;
  position: relative;
  margin-top: 5px;
  padding-left: 10px;
  padding-bottom: 20px;
}

.arrow-up {
  position: absolute;
  top: -8px;
  left: 10%;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $color-dark;
}

.badges {
  position: absolute;
  bottom: 2px;
  height: 10%;
  left: 0;
  right: 0;
  padding: 8px 8px 0px 8px;
  white-space: nowrap;
  overflow: hidden;
  .badge {
    display: inline-block;
    background: $color-gray--dark;
    border-radius: 10px;
    padding: 3px 8px;
    font-weight: 500;
    color: $color-light;
    font-size: 0.75em;
    letter-spacing: 0.1em;
  }
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

.center {
  display: flex;
  justify-content: center;
}

.orange-title {
  color: $color-secondary;
  font-weight: 500 !important;
}

.panel-with-shadow {
  border-radius: 10px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.margin-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.margin-right-small {
  margin-right: 5px !important;
}

.margin-left-small {
  margin-left: 5px !important;
}

.margin-right-medium {
  margin-right: 15px !important;
}

.margin-left-medium {
  margin-left: 15px !important;
}

.MuiInputBase-input.Mui-disabled {
  background-color: white;
  -webkit-text-fill-color: black !important;
}

.vizzle-selected-component {
  border-radius: 5px;
  box-shadow: 0px 1px 3px 4px $color-secondary, 0px 0px 2px 3px $color-secondary, 0px 3px 2px -1px $color-secondary !important;
}

.vizzle-selected-component-medium {
  border-radius: 5px;
  box-shadow: 0px 1px 3px 4px $color-secondary, 0px 0px 7px 7px $color-secondary, 0px 3px 2px -1px $color-secondary !important;
  &:hover {
    box-shadow: 0px 1px 3px 4px $color-secondary, 0px 0px 7px 7px $color-secondary, 0px 3px 2px -1px $color-secondary !important;
  }
}

.switch-selected-component {
  @extend .vizzle-selected-component-medium;
}

.component-disabled {
  pointer-events: none;
}

.top-left {
  float: left;
  margin-left: 5px;
  margin-top: 5px;
}

.bottom-right {
  float: right;
  margin-right: 5px;
  margin-bottom: 5px;
  position: absolute;
  bottom: 0;
}

.editor-selected-card {
  box-sizing: border-box;
  border: 3px solid $color-for-selection;
  border-radius: 5px;
}

.link {
  color: $color-secondary !important;
  font-weight: 500;
  &:hover {
    color: $color-primary !important;
  }
}

.flex-box {
  display: flex;
}

.dropdown-small-padding {
  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
  }
}

.selector-helper-text {
  .MuiFormHelperText-root {
    margin-left: 0px;
    color: $color-bad !important;
    font-weight: 500;
    font-size: 0.8em;
  }
}

.MuiPickersDatePickerRoot-toolbar {
  h6 {
    font-size: 2em;
    color: white;
  }
  h4 {
    font-size: 1.5em;
  }
}

.vizzle-checkbox {
  color: $color-secondary;
  svg {
    fill: $color-secondary;
  }
  &.selectable {
    cursor: pointer;
  }
  &.disabled {
    color: $color-gray;
    svg {
      fill: $color-gray;
    }
  }
}
.vizzle-radio {
  .Mui-checked {
    color: $color-secondary !important;
  }
}

.hide {
  display: none !important;
}

.required {
  &:after {
    margin-left: 5px;
    content: '*';
    color: $color-bad;
  }
}

.vizzle-box-shadow {
  box-shadow: 0px 0px 0px 1px $color-medium-light !important;
}