@import "../../../../styles/colors.scss";

.multiple-choice-v2-layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-column-gap: 1em;

  &.single-page {
    grid-template-rows: 60% 40%;
    gap: 5px;
    .response-cards-container {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 7.5px));
      gap: 7.5px;
      &.three-columns {
        grid-template-columns: repeat(3, calc(33.33% - 7.5px));
      }
    }
  }

  &.side-by-side {
    grid-template-columns: repeat(2, 50%);
    .response-area {
      justify-content: space-around;
    }
    .response-cards-container {
      grid-template-columns: repeat(2, calc(50% - 7.5px));
      grid-auto-rows: minmax(10px, 50%);
      display: grid;
      grid-column-gap: 15px;
      grid-row-gap: 0.7em;
      align-content: center;
    }
  }

  .prompt-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .prompt-cards-container {
      height: 100%;
    }
  }

  .response-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .submit-response-area {
      height: 30%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed $color-medium;
      border-radius: 1em;
      text-align: center;
      overflow: hidden;
    }

    .multiple-choice-v2-submit-text {
      width: 100%;
    }
  
    .response-cards-container {
      width: 100%;
      flex-grow: 1;
      margin-top: 1em;
    }
  }
}
