@import "../../../../../styles/colors.scss";

.teacher-selector {
  height: 100%;
  display: flex;
  flex-direction: column;

  .MuiTablePagination-input {
    margin-right: 5px;
  }
  .MuiIconButton-root {
    padding: 3px 9px;
  }

  .MuiCheckbox-root {
    color: $color-secondary !important;
  }

  .not-active-license {
    color: red !important;
    opacity: 0.5;
  }
}
