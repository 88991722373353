@import "../../../../../../styles/colors.scss";

.teacher-course-generate-lessons-dialog {
  .MuiDialog-paperScrollPaper {
    max-width: 1000px;
    min-width: 1000px;
    max-height: 95vh;
    min-height: 95vh;
  }
  &.saving-container {
    .custom-dialog-content {
      display: flex;
      align-items: center;
    }
  }

  .generate-button {
    min-width: 120px;
  }
  .dialog-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .generating-lesson-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .saving-text {
      font-size: 2em;
      font-weight: 500;
      color: $color-primary;
      margin-top: 15px;
    }
  }
}
