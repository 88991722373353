.game-thumbnail {
  height: 100%;
  .game-card {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.place-holder {
      background-color: lightgray;
    }

    .card-image-container {
      width: 100%;
      height: 100%;
      position: relative;
      .card-image {
        width: 100%;
        height: 100%;
        background-size: contain;
      }
      .extra-icon {
        position: absolute;
        height: 85%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

.game-card-small {
  p {
    min-height: 6px;
  }
  .card-order {
    font-size: 0.8em;
    @media screen and (min-width: 1025px) {
      font-size: 1em;
    }
  }
}