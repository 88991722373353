@import "../../../../styles/colors.scss";

.lesson-detail-container {
  height: 90vh;
  overflow-y: auto;
  background-color: $color-dark;
  color: white;
  padding: 20px 11%;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 20px 2.5%;
  }

  .action-area {
    margin-bottom: 15px;
    display: flex;
    button {
      font-weight: 500;
    }
    * {
      z-index: 1;
    }
  }

  .label-area {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .card-image {
    width: 100%;
    height: 300px;
    background-color: #fff;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 15px;
    border-radius: 5px;

    .arrow-up-lesson-detail {
      position: absolute;
      left: 10%;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $color-dark;
    }
  }

  $content-padding: 10px 20px;

  .lesson-summary {
    font-size: .8em;
    padding: $content-padding;
    display: flex;
    .play-description {
      display: flex;
      align-items: center;
    }
    .lesson-id-container {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .lesson-id {
        font-size: 0.9em;
      }
      .lesson-card-specialty-icon {
        margin-left: 10px;
        width: 30px;
      }
    }
  }

  .lesson-name {
    padding: $content-padding;
    word-break: break-all;
  }

  .lesson-name-and-icon {
    display: flex;
    align-items: center;
    .icon {
      fill: $color-secondary;
      margin-right: 5px;
    }
  }
  .goals-objectives {
    padding: $content-padding;
    margin-bottom: 15px;
  }

  .lesson-specialty-description {
    padding: $content-padding;
    margin-bottom: 60px;

    .description {
      font-size: 1em;
    }

    a {
      color: $color-primary;
    }
  }

  .bottom {
    font-size: .95em;
    padding: $content-padding;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    .page {
      margin-right: 10px;
      margin-top:5px;
      padding: 0px 10px 2px 10px;
      background-color: $color-gray-monarch-website;
      border-radius: 10px;
    }
    .subject-name {
      @extend .page;
      margin-right: 15px;
    }
  }

  .divider {
    background-color: $color-gray;
    margin-bottom: 15px;
  }
}
