@import "../../../../../../../styles/colors.scss";

.extension-activities-file-upload {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px 15px;
  box-sizing: border-box;
  background-color: white;
  .title {
    margin-right: 40px;
    font-weight: 500;
  }
  .menu-bar {
    display: flex;
    gap: 20px;
  }

  .extensition-activities-action {
    text-transform: none;
    font-size: 1em;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .select-all-action {
    margin-left: auto;
  }

  .body-container {
    flex-grow: 1;
    overflow-y: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    z-index: 1;
    .file-name {
      word-break: break-all;
    }
    .loading-container {
      height: 100%;
      padding-top: 10%;
      box-sizing: border-box;
    }
  }
}
