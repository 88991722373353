.lessons-group-play-filter {
  z-index: 5;
  margin-bottom: 10px;
  display: flex;
}

.lesson-group-play-subjct-popper {
  .MuiAutocomplete-listbox {
    columns: 2;
  }
}