@import "../../../../styles/colors.scss";

.simple-celebration {
  margin-top: 10px;
  margin-left: 15px;
  width: 17%;
  height: 154px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $color-green;
  border-radius: 10px;
  gap: 10px;
  color: white;
  font-weight: 500;
}
