@import '../../styles/colors.scss';

.simple-table-component {
  .table-header {
    margin-bottom: 15px;
  }
  .simple-table {
    .header {
      
      background: lighten( $color-gray, 30% );
      .header-cell {
        padding: 5px 10px;
      }
    }
    .table-cell {
      border-right: 1px solid $color-gray-monarch-website--light-fading;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 0.9em;
      &:last-child {
        border-right: none;
      }
    }
  }
}
