.student-table-selector {
  .student-selector-table {
    .table-cell {
      padding-top: 5px;
      padding-bottom: 5px;
      word-break: break-all;
    }
    .student-active-switch-toggle {
      margin-left: 15px;
    }
  }
}
