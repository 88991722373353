@import "../../../../../styles/colors.scss";

.view-student-answers-action {
  width: 100%;
  text-transform: none !important;
  text-align: left;
  justify-content: left !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  position: relative;

  .view-student-answers-action-chat-icon {
    position: absolute;
    top: -10px;
    right: 0px;
    .icon {
      color: $color-secondary;
    }
  }
}

.view-student-answers-lesson-play-dialog {

  // cancel the fading css in the acitivty components
  .fading {
    opacity: 1;
  }
}