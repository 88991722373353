@import "../../../../../styles/colors.scss";

.topic-standards {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .state-selector-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    align-self: center;
  }

  .standard-container {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 10px;

    .standard-list-paper {
      padding: 10px 0px;
      margin-top: 5px;
    }
    .loading-container {
      margin-top: 70px;
      .loading-message {
        color: $color-secondary;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }

    .standard-list {
      font-size: 1em;
      li {
        margin-bottom: 10px;
        .standard-code {
          font-weight: 500;
        }
      }
    
      .standard-description:first-letter {
        text-transform: capitalize;
      }
    }
  }
}