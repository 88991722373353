@import '../../../../styles/colors.scss';

.book-replay-button {
  padding: 8px 20px !important;
  position: absolute;
  left: 15px;
  top: -30px;
  z-index: 1;
  &:disabled {
    background-color: $color-primary !important;
  }
}