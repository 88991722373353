@import "../../../../styles/colors.scss";

.admin-report {
  width: 100%;
  height: 100%;
  &.loading-container {
    padding-top: 10%;
    box-sizing: border-box;
    .loading-message {
      color: $color-secondary;
      font-size: 1.5em;
    }
  }
  &.error-message {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 50px;
    box-sizing: border-box;
  }
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
