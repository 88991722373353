.categorizing-cards-editor {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .categorizing-cards-selector {
    min-height: 40px;
  }
  .cards-container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    grid-template-rows: 50%;
    margin-top: 10px;
    grid-gap: 10px;
    overflow: hidden;
  }

  .card-editor-with-value {
    padding: 0.1em;
  }

  .MuiSelect-select {
    padding-right: 20px !important;
  }
}