.apply-styles-form-container {
  width: 700px;

  .apply-to-activity-dropdown {
    padding: 5px 10px;
  }

  .apply-to-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .label {
      min-width: 130px;
    }
  }
}