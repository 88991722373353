.fill-in-the-blank-editor-with-text-tyle {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 12px;
  box-sizing: border-box;

  .answer-area {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px 15px 5px 15px;
    box-sizing: border-box;
    height: 40%;
    display: flex;
    flex-direction: column;
    .add-answer {
      display: flex;
      flex-direction: row;
      .text-field {
        width: 85%;
        margin-right: 10px;
      }
    }

    .no-answer-text {
      font-size: 0.95em;
    }
    .answers {
      margin-top: 10px;
      overflow-y: auto;
      box-sizing: border-box;
      .chip {
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }
  }

  .text-editor-component-root {
    display: flex;
    flex-direction: column;
    .text-editor-editor {
      height: 100%;
    }
  
    .text-editor-actions {
      margin-top: 10px;
      float: unset;
      z-index: 1;
    }
  }
  
}
