@import '../../styles/colors.scss';

.PaperSheetError {
  .paper-sheet {
    padding: 10px;
  }

  .paper-sheet-error {
    background-color: lighten($color-bad, 43%);
    color: $color-bad;
    p {
      font-size: 0.9em;
    }
  }
}