@import "../../../../../styles/colors.scss";

.move-teacher-form {
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .move-teacher-table {
    width: 100%;
    height: 300px;
    max-height: 350px;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .move-student-checkbox {
    color: $color-secondary;
    svg {
      fill: $color-secondary;
    }
  }

  .form-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .form-label {
      width: 200px;
    }
  }

  .search-teacher-search-school-action {
    margin-left: 10px;
    flex-grow: 1;
  }
}
