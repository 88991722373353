@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.sorting-activity-component {
  width: 97%;
  height: 100%;
  .sorting-prompt-draggable {
    height: 100%;
    width: 100%;
  }
  .sorting-prompt-area {
    width: 100%;
    height: 55%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    > div {
      width: 49%;
    }
  }

  .sorting-response-area-container {
    margin-top: 2em;
    width: 100%;
    height: 30%;
    .sorting-response-cards {
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;

      .sorting-response-draggable {
        position: absolute !important;
        margin: auto;
        width: 100%;
        height: 100%;
        top: 0 !important;
        left: 0 !important;
        &:hover {
          @extend .vizzle-selected-component;
          border-radius: 7px;
        }
      }
      .response-card-place-holder {
        height: 100%;
        width: calc(100% / 8);
        position: relative;
      }

      .response-margin-2-responses {
        margin-left: 2.2rem;
        margin-right: 2.2rem;
      }

      .response-margin-3-responses {
        margin-left: 2.2rem;
        margin-right: 2.2rem;
      }

      .response-margin-4-responses {
        margin-left: 2rem;
        margin-right: 2rem;
      }

      .response-margin-5-responses {
        margin-left: 2rem;
        margin-right: 2rem;
      }

      .response-margin-6-responses {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      .response-margin-7-responses {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
      }

      .response-margin-8-responses {
        margin-left: 0.3rem;
        margin-right: 0.3rem;
      }
    }
  }
  .sorting-component-response-card-non-drag-items {
    transform: translate(0, 0) !important;
    transition: transform 0.7s !important;
  }

  .sorting-selected-component {
    @extend .vizzle-selected-component-medium;
    border-radius: 7px;
  }

  .selected-response-cards {
    height: 100%;
    width: 100%;
    background-color: $color-gray-monarch-website--light-fading;
    border-radius: 7px;
    z-index: 100;
  }

  .sorting-component-response-reject {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .sorting-component-reject-show {
    opacity: 1 !important;
  }

  .sorting-component-reject-hide {
    opacity: 0 !important;
    transition: opacity 1s ease-in-out;
  }

  .sorting-component-prompt-reject {
    @extend .sorting-component-response-reject;
    border-radius: 7px;
    box-shadow: 0px 0px 3px 3px $color-bad, 0px 0px 7px 7px $color-bad, 0px 3px 2px -1px $color-bad !important;
  }

  .sorting-component-response-selected {
    @extend .vizzle-selected-component-medium;
  }

  &.prompt-selected-response-cards-hover {
    .prompt-selected-response-cards {
      &:hover {
        @extend .vizzle-selected-component-medium;
        cursor: pointer;
      }
    }
  }

  .switch-select {
    border-radius: 5px;
    box-shadow: 0px 1px 3px 4px $color-tertiary, 0px 0px 7px 7px $color-tertiary, 0px 3px 2px -1px $color-tertiary !important;
    &:hover {
      box-shadow: 0px 1px 3px 4px $color-tertiary, 0px 0px 7px 7px $color-tertiary, 0px 3px 2px -1px $color-tertiary !important;
    }
  }
}
