.off-button-form {
  display: grid;
  grid-template-columns: 22% 20% auto;
  align-items: center;

  .label-container {
    display: flex;
    .label {
      font-weight: 500;
    }
  }

  .off-button {
    width: 110px;
    height: 90%;
  }
  margin-bottom: 15px;
}
