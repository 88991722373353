@import '../../../../../../styles/colors.scss';

.game-activity-play-setup-dialog {
  .dialog-content {
    width: 370px;
    // height: 440px;
  }
  .number-of-players-text {
    margin-bottom: 20px;
  }
  .number-of-players-slider {
    width: 95%;
  }
  .players-setup {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .actions {
    width: 50%;
    margin: auto;
    margin-top: 15px;
    .play-button {
      width: 100%;
    }
  }
}