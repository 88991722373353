@import '../../../styles/colors.scss';

.share-link-dialog {
  .content {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
  }
  .input-link-to-share {
    display: flex;
    align-items: center;
    width: 550px;
    border: 0px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .action-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .share-to-classroom {
    margin-left: 5px !important;
    margin-top: 10px !important;
  }

  .copy-icon-button {
    background: $color-secondary !important;
    color: white !important;
    padding: 12px 20px !important;
    margin-left: 10px !important;
    border-radius: 8% !important;
    &:hover {
      opacity: 0.5;
    }
  }
}
