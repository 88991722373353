.seasonal-filter-flexbox {
  .MuiTypography-root {
    font-weight: 500;
    font-size: 1rem;
  }
  .seasonal-filter-select {
    min-width: 200px;
  }
  .saving-icon {
    position: absolute;
    align-self: center;
    bottom: 0;
  }
}
