.review-and-feedback {
  width: 60%;
  height: 100%;
  padding-top: 5px;
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  .no-result-found {
    font-size: 1.7rem;
  }
}
