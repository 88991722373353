@import '../../../styles/colors.scss';
.celebration-setting-container {
  .celebration-el-box {
    width: 22%;
    margin: 1%;
    border-radius: 2px;
    box-shadow: 3px $color-secondary;
    &:hover {
      transform: scale(1.1);
    }
  }
  .celebration-is-selected {
    box-shadow: 0 0 8px 4px $color-secondary;
  }
  .celebration-is-selected:hover {
    box-shadow: 0 0 16px 8px $color-secondary;
  }
}
