@import "../../../../../styles/colors.scss";

.teacher-curriculum-instruction {
  padding: 10px 20px;
  box-sizing: border-box;
  p {
    color: $color-secondary;
    font-weight: 500;
    font-size: 1.2em;
    @media (max-width: 1024px) {
      font-size: 1.4em;
    }
  }

  .no-bottom-margin {
    text-align: center;
    margin-bottom: 0px;
  }
  .no-top-margin {
    text-align: center;
    margin-top: 0px;
  }
  .main-heading {
    font-size: 1.5em;
    @media (max-width: 1024px) {
      font-size: 1.7em;
    }
  }

  video {
    min-width: 49vw;
    max-width: 49vw;
    max-height: 50vh;
    @media (max-width: 1024px) {
      margin-top: 10px;
      min-width: 65vw;
      max-width: 65vw;
    }
    box-shadow: 5px 2px 10px 3px #888888;
    border: 1px solid #888;
    padding: 15px;
    border-radius: 10px;
  }
  .video-container {
    display: flex;
    justify-content: center;
  }
}
