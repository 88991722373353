@import '../../../../../styles/colors.scss';
@import "../../../../../styles/components.scss";

.categorizing-activity-prompt-card {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px 5px;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  .media-action {
    z-index: 1;
    .media-player-action-area {
      border-radius: 10px;
    }
  }

  .prompt-details {
    width: 22%;
    max-height: 100%;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  
    .prompt-image-container {
      min-height: 40%;
      flex-grow: 1;
      .prompt-image {
        height: 100%;
        background-size: contain;
      }
    }
  
    .prompt-text {
      padding: 5px;
      box-sizing: border-box;
      min-height: 28px;
      width: 100%;
    }

    .media-player-icon {
      font-size: 1.3em;
      color: $color-primary;
      margin-left: auto;
      top: 0px;
      left: 2px;
      border: 3px;
      background-color: white;
      border-radius: 15px;
      position: absolute;
    }
  }

  .prompt-selected-response-cards {
    height: 100%;
    width: 75%;
    border-radius: 7px;
    z-index: 1;
    background-color: $color-gray-monarch-website--light-fading;;
  }
}