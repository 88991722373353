.setting-text-field-form {
  display: grid;
  grid-template-columns: 15% 60% auto;
  align-items: center;

  .label {
    font-weight: 500;
  }
  
  .apply-button {
    width: 110px;
    margin-left: auto;
    height: 90%;
  }

  .input-container {
    display: flex;
    align-items: center;
    .sub-label {
      white-space: nowrap;
      margin-left: 10px
    }
  }
  margin-bottom: 15px;
}