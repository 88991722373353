.non-drag-items {
  transform: translate(0, 0) !important;
  transition: transform 0.7s !important;
  z-index: 1;
}

.item-placeholder {
  background: #4399d3;
  border: 3px solid #3f96d2;
  border-radius: 10px;
  width: 100%;
  height: 91%;
  position: relative;
}

.high-z-index {
  z-index: 1000;
  transform: unset !important;
  transition: all .2s ease-in-out;
}