@media print {
    html,
    body {
      height: 100%;
    }
    .objective-detail-container {
      overflow-y: visible !important;
      background-color: white !important;
    }
    .objective-lesson, .lesson-standards {
      page-break-before: always;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }