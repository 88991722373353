.small-audio-selector-container {
  display: flex;
  flex-direction: row;
  height: 100%;

  .small-audio-selector {
    width: 100%;
    height: 100%;
    padding: 0px;
    border-radius: 5px;
    border: 1px lightgray solid;
    position: relative;
   
    legend {
      position: absolute;
      z-index: 1;
      top: -10px;
      left: 10px;
      background-color: white;
      padding-left: 3px;
      padding-right: 3px;
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.54);
    }
  
    .audio-selector-drop-area-card {
      height: 100%;
      width: 100%;
      position: relative;
      margin-right: 10px;
      .audio-selector-drop-area-card-action {
        height: 100%;
        width: 100%;
        display: flex;
        margin: auto;
      }
    }
  }
  
  .audio-player {
    display: flex;
    flex-direction: row;
    width: 100%;
    audio {
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;
    }
  }
  .remove-button {
    margin-top: 3px;
  }
}