@import "../../../../../styles/colors.scss";

.flexible-matching-print-component {
  width: 100%;
  height: 99%;
  grid-column-gap: 5%;
  justify-items: center;
  justify-content: center;
  margin-top: -40px;

  &.no-prompt-area {
    grid-template-columns: repeat(1, calc(50% - 1em));
  }
  .submit-response-area {
    background-color: white;
    position: relative;
  }

  .selected-response-container {
    border: none !important;
    background-color: transparent;
    .selected-response-card {
      width: 50%;
    }
  }

  .response-card-container {
    position: relative;
    max-height: 150px;
  }

  .response-cards-container {
    grid-auto-rows: minmax(10px, 140px) !important;
    grid-gap: 20px !important;
  }

  .flexi-matching-response-card {
    height: 100%;
    position: relative;
  }
  .flexi-matching-component-response-card-container {
    position: absolute !important;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    z-index: 100;
  }

  .flexi-matching-prompt-text,
  .flexi-matching-sub-text {
    min-height: 85px;
  }
  .card-activity-text {
    padding: 5px !important;
  }

  .prompt-cards-container {
    &.flexi-matching-one-card {
      height: 300px !important;
    }
    .card-activity-text {
      padding: 5px !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
}
