.multi-selector-with-search {
  .MuiAutocomplete-tag {
    max-width: 70% !important;
  }
  .Mui-focused {
    height: 52px !important;
    .MuiAutocomplete-tag {
      display: none;
    }
  }

  .MuiChip-deleteIcon {
    z-index: 10;
  }
  .MuiAutocomplete-clearIndicator {
    z-index: 10;
  }
  .MuiAutocomplete-input {
    z-index: 9;
    left: 0px;
    text-indent: 10px;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    &.Mui-disabled {
      z-index: 0;
      padding: 0px !important;
    }
  }
  .MuiOutlinedInput-root {
    height: 52px;
  }
}

// .MuiAutocomplete-popper {
//   width: 80% !important;
// }
