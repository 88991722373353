@import "../../../../../styles/colors.scss";

.assignment-overview-filter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;

  .subject-selector {
    width: 300px;
    margin-right: 40px;
    .MuiSelect-outlined.MuiSelect-outlined {
      padding: 10px;
    }
    .subject-selector-dropdown {
      margin-left: 5px;
      padding: 5px 10px;
    }
  }
}