.flexi-matching-thumbnail {
  .prompt-area {
    overflow: visible;
    .flexi-matching-prompt-text,
    .flexi-matching-sub-text {
      flex-grow: 1;
    }
  }

  &.small {
    padding: 2px;
    box-sizing: border-box;
    grid-template-columns: repeat(2, calc(50% - 0.25em));
    grid-column-gap: 0.5em;
    .prompt-area {
      overflow: hidden;
      .prompt-cards-container {
        min-height: 40%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .small-thumbnail {
          width: 100%;
        }
      }

      .flexi-matching-prompt-text,
      .flexi-matching-sub-text {
        flex-grow: unset;
        height: 20%;
      }
    }

    .response-area {
      .submit-response-area {
        font-size: 0.7em;
        border-radius: 0.5em;
      }
      .response-cards-container {
        margin-top: 0.5em;
        .flexi-matching-response-card {
          font-size: 0.5em;
        }

        .response-more-card-text {
          grid-area: 2/1 / span 1 / span 2;
          margin: auto;
        }
      }
    }
  }
  
}
