@import "../../styles/colors.scss";

.my-classroom {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px 15px;
    box-sizing: border-box;
    height: 100%;
  }
}
