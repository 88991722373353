.browse-image-data-dialog {
  .browse-image-data-container {
    max-width: 500px;
    min-width: 500px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 30px;
    box-sizing: border-box;

    .browse-image-keywords-container {
      margin-top: 20px;
    }

    .keywords-editor {
      .keywords {
        max-height: 100px;
      }
    }
  }
}