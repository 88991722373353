.verify-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .vizzle-logo-container {
    margin-top: 100px;
    img {
      width: 200px;
    }
  }

  .loading-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .loading-message {
      margin-top: 20px;
    }
  }

  .title {
    padding-top: 30px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.3em;
  }

  .verify-user-content {
    width: 350px;
    margin-top: 20px;
  }
}