.celebration-setting-form{
  display: flex;
  flex-direction: column;
  gap: 10px;

  .celebration-style-container {
    gap: 10px;
    display: flex;
    flex-direction: row;
  }
}