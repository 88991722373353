@import '../../../../styles/colors.scss';

.rejected-answer {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  position: relative;
  display: flex;
  
  .rejected-answer-icon {
    margin: auto;
    font-size: 6em;
    color: $color-bad;
    font-weight: 700;
  }
}

.rejected-answer-border {
  box-shadow: 0px 3px 3px 3px $color-secondary, 0px 0px 2px 3px $color-secondary, 0px 3px 2px -1px $color-secondary !important;
}
