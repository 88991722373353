@import "../../styles/colors.scss";
@import "../../styles/buttons.scss";

.multiple-date-selector {
  display: flex;
  align-items: center;
  .dates-selector {
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    .icon {
      margin: 5px 10px;
      fill: white;
      font-size: 2em;
    }
  }

  .multiple-date-selector-radio-group {
    margin-left: 10px;
    display: flex;
    font-size: 1em;
    .MuiRadio-root {
      padding: 3px 6px;
    }
  }
  .selected-dates-container {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date-picker-component-container {
    background-color: $color-primary;
    border-radius: 5px;
    padding-right: 10px;
    display: flex;
    align-items: center;

    .divider {
      height: 25px;
      width: 2px;
      margin-right: 10px;
    }
  }
  .date-picker-component {
    &.no-highlight {
      .rmdp-today {
        span {
          background-color: white !important;
          color: black;
        }
      }
      &.rmdp-selected,
      &.rmdp-range {
        span {
          background-color: #0074d9;
          color: white;
        }
      }
    }
  }

  .apply-date-button {
    margin-right: 5px;
  }

  &.form-mode {
    .date-picker-component-container {
      background-color: white !important;
      padding-right: 0px;
    }
    .date-picker-component {
      .rmdp-input {
        width: 170px;
        height: 40px;
        text-align: center;
      }
    }
  }
}
