@import '../../../../../styles/colors.scss';
.theme-mystudent-setting-container {
  .theme-setting-el-box {
    width: 17%;
    margin: 1%;
    border-radius: 2px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .theme-setting-is-selected {
    box-shadow: 0 0 8px 4px $color-secondary;
  }
  .theme-setting-is-selected:hover {
    box-shadow: 0 0 16px 8px $color-secondary;
  }
}
