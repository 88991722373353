.cause-and-effect-thumbnail {
  margin-top: 2px;
  display: grid;
  grid-template-columns: 48% 48%;
  width: 100%;
  height: 100%;
  align-items: center;
  grid-column-gap: 1em;

  &.small {
    grid-column-gap: 0.5em;
  }

  &.cause-length-1 {
    grid-template-columns: auto;
  }

  &.cause-length-2 {
    grid-template-columns: auto;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
