@import "../../../../../styles/components.scss";

.objective-lessons {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  .objective-lesson-play-container {
    &:hover {
      @extend .vizzle-selected-component;
      cursor: pointer;
      transition: all 0.5s;
    }
  }
}
