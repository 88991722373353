@import "../../../../../../../styles/colors.scss";

.extension-activities-rename-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  box-sizing: border-box;
  height: 100%;
  gap: 25px;
  .rename-file-bold {
    font-weight: 500;
  }
  .rename-file-icon {
    font-size: 5em;
  }

  .font-fading-color {
    color: $color-gray-monarch-website;
  }

  .rename-file-input-area {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: flex-start;
    .rename-file-field {
      width: 400px;
    }
    .rename-file-action {
      min-width: 100px;
      text-transform: none;
      font-size: 1em;
      margin-top: 7px;
    }
  }
}