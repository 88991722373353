@import "../../../../styles/colors.scss";

.flexi-matching-layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 1em));
  grid-column-gap: 1em;

  .prompt-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .prompt-cards-container {
      min-height: 60%;
    }

    .flexi-matching-prompt-text {
      // flex-grow: 1;
      margin-bottom: 0.6em;
      height: unset;
    }

    .flexi-matching-sub-text {
      // flex-grow: 1;
      margin-top: 0.6em;
      height: unset;
    }

    .flexi-matching-one-card {
      display: block;
    }

    .flexi-matching-two-cards-vertical {
      display: grid;
      grid-template-areas:
        "card-1 card-1"
        "card-2 card-2";
      grid-template-columns: repeat(2, calc(50% - 0.25em));
      grid-template-rows: repeat(2, calc(50% - 0.25em));
      grid-gap: 0.5em;
    }

    .flexi-matching-two-cards-horizental {
      display: grid;
      grid-template-areas:
        "card-1 card-2"
        "card-1 card-2";
      grid-template-columns: repeat(2, calc(50% - 0.25em));
      grid-template-rows: repeat(2, calc(50% - 0.25em));
      grid-gap: 0.5em;
    }

    .flexi-matching-two-cards-horizental-flipped {
      display: grid;
      grid-template-areas:
        "card-2 card-1"
        "card-2 card-1";
      grid-template-columns: repeat(2, calc(50% - 0.25em));
      grid-template-rows: repeat(2, calc(50% - 0.25em));
      grid-gap: 0.5em;
    }

    .flexi-matching-three-cards-1 {
      display: grid;
      grid-template-areas:
        "card-1 card-1"
        "card-2 card-3";
      grid-gap: 0.5em;
      grid-template-columns: repeat(2, calc(50% - 0.25em));
      grid-template-rows: repeat(2, calc(50% - 0.25em));
    }

    .flexi-matching-three-cards-2 {
      display: grid;
      grid-template-areas:
        "card-1 card-2"
        "card-3 card-3";
      grid-gap: 0.5em;
      grid-template-columns: repeat(2, calc(50% - 0.25em));
      grid-template-rows: repeat(2, calc(50% - 0.25em));
    }

    .flexi-matching-four-cards {
      display: grid;
      grid-template-areas:
        "card-1 card-2"
        "card-3 card-4";
      grid-template-columns: repeat(2, calc(50% - 0.25em));
      grid-template-rows: repeat(2, calc(50% - 0.25em));
      grid-gap: 0.5em;
    }
  }

  .response-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .submit-response-area {
      height: 30%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed $color-medium;
      border-radius: 1em;
      text-align: center;
      overflow: hidden;
    }

    .flexi-matching-submit-text {
      width: 100%;
    }
  
    .response-cards-container {
      width: 100%;
      flex-grow: 1;
      margin-top: 1em;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 7.5px));
      grid-auto-rows: minmax(10px, 50%);
      grid-column-gap: 15px;
      grid-row-gap: 0.7em;
      align-content: center;
    }
  }
}
