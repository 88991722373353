@import "../../styles/colors.scss";

.switch-access-dialog {
  .content {
    padding: 40px 40px 30px 40px !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      fill: $color-primary;
      font-size: 5em;
      margin-bottom: 10px;
    }
    .title {
      font-size: 1.5em;
      text-align: center;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .messages {
      text-align: center;
    }
  }
}