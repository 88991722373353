@import '../../../../../styles/colors.scss';
@import '../../../../../styles/components.scss';

.matching-activity-prompt-area-question-mark-container {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: $color-gray-monarch-website--light;
  color: white;
  position: relative;
  border-radius: 5px;
}

.matching-activity-prompt-area-question-mark {
  margin: auto;
  font-size: 7em;
  font-weight: 500;
}
