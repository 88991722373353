@import "../../../../../../../../styles/colors.scss";

.score-answer-form {
  width: 90vw;
  height: 85vh;
  &.no-answer {
    width: 400px;
    .answer-card-comment-container {
      display: flex !important;
      width: 100%;
      flex-direction: column;
    }
  }

  .answer-card-comment-container {
    display: grid;
    grid-template-columns: 30% 67%;
    grid-gap: 3%;
    height: 100%;
    .answer-card-container {
      height: 100%;
    }
    .comment-apply-button {
      margin-top: 20px;
    }

    .form-container {
      height: 100%;
      .message-field {
        height: 100%;
        .MuiOutlinedInput-root, textarea {
          height: 100% !important;
        }
      }

      .message-field-edit {
        margin-bottom: 10px;
      }
    }

    .card-activity-text {
      overflow-y: auto;
    }

    .card-activity-image {
      height: 100%;
      max-height: 100%;
      position: relative;
      padding: 0;
    }
  }

  .score-answer-container {
    width: 100%;
    height: 100%;

    .score-answer-form-answer-paper-container {
      height: 100%;
      .score-answer-form-answer-paper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        padding: 5px 10px 10px 10px;
        box-sizing: border-box;
        position: relative;

        .title {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
          margin-bottom: 10px;
        }
        .answer-page-title {
          max-width: 80%;
          overflow: hidden;
          a {
            color: inherit;
            text-decoration: none;
            &:hover {
              color: $color-secondary;
            }
          }
        }
        .page-subtitle {
          font-size: 0.7em;
        }
      }
    }

    .MuiCardContent-root {
      padding-bottom: 0px;
    }
  }
}
