@import '../../../../../styles/colors.scss';

.matching-editor-answer-key {
  position: absolute;
  z-index: 10;
  left: 5px;
  width: 70%;
  max-width: 100px;
  top: -3px;
  @media screen and (max-width: 1024px) {
    width: 60%;
    max-width: 70px;
  }

  .answer-key-editor {
    display: flex;
  
    .answer-key-text-field {
      border-radius: 5px;
      background-color: $color-gray-monarch-website--light-fading;
      label {
        font-size: 0.7em;
        font-weight: 500;
      }
      fieldset {
        border-width: 2px;
      }
      input {
        padding: 5px 4px 6px 6px;
        text-overflow: ellipsis;
      }
      input::placeholder {
        font-size: 0.72em;
        font-weight: 500;
      }
      .Mui-disabled {
        color: black !important;
        opacity: 1 !important;
      }
    }
  
    .answer-key-card-status {
      margin-top: 10px;
      background: white;
      border-radius: 15px;
      margin-right: 3px;
    }
    
    .answer-key-card-status-correct {
      @extend .answer-key-card-status;
      color: $color-good;
    }
  
    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      transform: translate(10px, 10px) scale(1);
    }
  
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}
