@import "../../../../../styles/colors.scss";
@import "../../../../../styles/components.scss";
@import "../../../../../styles/mixins.scss";

.fitb-page-editor-component {
  display: flex;
  height: 100%;
  width: 100%;

  .fitb-page-editor {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50% 24% 24%;
    grid-gap: 1%;
    margin-left: 0.12em;
    margin-right: 0.12em;

    .fitb-page-editor-placeholder {
      height: 100%;
      width: 100%;
      background: lightgray;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .fitb-page-editor-image-action {
      display: flex;
      height: 100%;
      width: 100%;
      .fitb-page-editor-image {
        margin-left: 0.12em;
        margin-right: 0.12em;
        height: 100%;
        width: 100%;
        margin-bottom: 0em;
        background-size: contain;
        display: flex;
      }
      .fitb-page-editor-with-video-icon {
        color: $color-primary;
        font-size: 2em;
        margin-left: auto;
      }
    }

    .fitb-page-editor-action {
      overflow: hidden;
      background: $color-gray-monarch-website--light-fading;
      border-radius: 5px;
      .fitb-page-editor-with-value {
        margin: auto;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .fitb-page-editor-with-icon {
        color: $color-primary;
        font-size: 2em;
        margin: auto;
      }
    }
  }

  .fitb-card-editor-selected {
    .fitb-page-editor-section-selected {
      box-sizing: border-box;
      border: 3px solid $color-for-selection;
      border-radius: 5px;
    }
  }
}
