.create-student-dialog {
  .profile-box {
    width: 100%;
    .profile-box-textfield {
      width: 100%;
    }
  }

  &.with-schoology-id {
    .MuiDialog-paperScrollPaper {
      @media screen and (min-height: 800px) {
        max-height: 680px !important;
        min-height: 680px !important;
      }
    }
  }
  .MuiDialog-paperScrollPaper {
    max-height: 610px !important;
    min-height: 610px !important;
    width: 450px;
  }

  .MuiOutlinedInput-input {
    padding: 10px 14px
  }

  .create-student-prfile-box {
    min-height: 60px;
    margin-bottom: 10px;
  }
}
