@import "../../../../../../styles/colors.scss";

.move-student-form {
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .move-student-table {
    width: 100%;
    height: 300px;
    max-height: 350px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
}
