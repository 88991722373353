@import "../../../../styles/colors.scss";

.clear-lesson-selection {
  margin-left: 10px;
  color: $color-primary;
  font-weight: 500;
  white-space: nowrap;
  .number-of-selected-lessons {
    display: flex;
    .selected-number {
      margin-right: 0px;
      border-radius: 50%;
      padding: 0px 6px 1px 6px;
      background-color: transparent;
      transition: all 1s;
      min-width: 10px;
      text-align: center;
    }
    .selected-number-highlighted {
      background-color: $color-secondary;
      color: white;
    }
  }
  
  .clear-selected-lessons {
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      opacity: 0.8;
    }
  }
}
