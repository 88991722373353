@import "../../../../../styles/colors.scss";
@import "../../../../../styles/components.scss";

.teacher-course-generated-lessons {
	box-sizing: border-box;

	.actions-container-fieldset {
    height: 100%;
    border-radius: 5px;
    border: 1px lightgray solid;
    position: relative;
		margin-top: 15px;
	}
}
