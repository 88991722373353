@import "../../../../../../styles/colors.scss";

.assigned-student-courses-card {
  padding-bottom: 10px;
  .title {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    .day {
      margin-right: 5px;
    }
  }

  .image-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    .icon {
      padding: 25px;
      font-size: 3em;
      border-radius: 50%;
      fill: $color-medium;
      &.no-assignment {
        background-color: #FFBBB7;
      }
      &.some-assigned {
        background-color: #FAFBBF;
      }
      &.all-assigned {
        background-color: #D4F4D7;
      }
    }
  }

  .description {
    margin-top: 20px;
    text-align: center;
  }

  .card-actions {
    display: flex;
    justify-content: center;
  }
}