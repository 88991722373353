.lesson-card-list-detail-container {
  .lesson-card-list-detail-header {
    padding-top: 5px;
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;
  }
  .lesson-list-row {
    position: relative;
    .lesson-list-row-component {
      position: absolute;
      display: flex;
      width: 100%;
      div {
        margin-left: 10px;
        z-index: 1;
      }
    }
  }
  .lesson-card-action {
    &:hover {
      cursor: pointer;
    }
  }

  .no-lesson-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
    position: relative;
  }
}