@import "../../styles/colors.scss";
@import "../../styles/components.scss";

.my-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  box-sizing: border-box;

  .container {
    height: 100%;
    width: 100%;
    position: relative;

    .my-settings-container {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }

  .my-setting-body {
    margin: 10px;
    .my-setting-body {
      display: grid;
      grid-gap: 20px;
    }
    .my-setting-body-entry-title-label {
      .tooltip-with-indicator {
        height: 30px;
        width: 30px;
        font-size: 1.1rem;
      }
    }
    .MuiOutlinedInput-input {
      padding: 10px 10px;
    }
  }
  .my-setting-body-entry {
    margin-top: 25px;
    padding: 20px;
    box-sizing: border-box;
    @extend .vizzle-box-shadow;
    .my-setting-body-entry-title-label {
      font-weight: 400;
      min-height: calc(1.8em + 5px);
      white-space: nowrap;
      font-size: 1.5rem;
      margin-right: 5px;
    }
  }
  .hr-settings {
    border-color: $color-gray;
  }
}
