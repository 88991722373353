@import "../../../../../../styles/colors.scss";

.teacher-course-add-topic-dialog {
  .MuiDialog-paperScrollPaper {
    max-height: 95vh;
  }
  .dialog-content {
    min-width: 500px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  
    .enter-topic-container {
      margin-top: 15px;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-end;
    }
    .topic-list {
      max-height: 60vh;
      overflow-y: auto;
    }
  }
}