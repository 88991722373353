.student-usernames-print {
  margin-top: 10px;
  display: block;
  .vizzle-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    img {
      width: 250px !important;
    }
  }

  .header {
    margin-top: 20px;
    font-size: 2em;
    .username {
      font-weight: 500;
    }
  }

  .student-list {
    margin-top: 20px;
    display: block;
    .student {
      display: inline-block;
      position: relative;
      padding: 10px 10px 0px 10px;
      height: 100px;
      box-sizing: border-box;
      border: 1px solid black;
      border-radius: 3px;
      page-break-inside: avoid;
      width: 45%;
      margin-bottom: 20px;
      &:nth-child(odd) {
        margin-right: 5%;
      }
      
      .username {
        font-size: 1.6em;
        font-weight: 500;
      }
      .name {
        font-size: 1.2em;
      }
    }
  }
}
