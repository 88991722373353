.transparent-blocker {
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  z-index: 999999;
  &.full-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
  }
}