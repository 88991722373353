@import "../../../../styles/colors.scss";

.teacher-curriculum-search-bar {
  background-color: $branding-bar-blue;
  padding: 15px 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 2;
  min-height: 30px;

  .MuiSelect-select {
    background-color: white !important;
    border-radius: 5px !important;
  }

  .MuiSelect-selectMenu {
    padding: 10px 32px 10px 15px;
    min-height: 20px;
  }

  .subject-search {
    margin-right: 10px;
    width: 200px;
  }

  .grade-search {
    margin-right: 10px;
    width: 150px;
  }

  .unit-search {
    width: 250px;
    margin-right: 20px;
  }

  .search-button {
    border: 2px white solid;
  }
}