.book-card-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &.book-card-padding {
    padding: 8px;
    box-sizing: border-box;
  }

  .book-card-image {
    background-size: contain;
    height: 100%;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
  
  .book-card-text {
    align-items: center;
    padding: 1em 2em 0.2em 2em !important;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .media-component {
    z-index: 100;
  }
  
  .hospot-container {
    position: absolute;
    width: 84%;
    height: 78%;
    left: 8.5%;
  }
}
