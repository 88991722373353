.custom-dialog-component {
  .custom-dialog-icon-button {
    float: right;
    margin-left: auto;
    margin-bottom: auto;
    padding: 0px !important;
  }
  .custom-dialog-title {
    padding: 16px 24px 0px;
    display: flex;
    justify-content: space-between;
    h2 {
      display: flex;
      align-items: center;
    }
  }

  .vizzle-custom-dialog-actions {
    padding: 8px 15px 15px;
  }
}