@import '../../../../styles/colors.scss';
@import '../../../../styles/components.scss';

.matching-editor-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .matching-editor-prompts-area {
    height: 60%;
    width: 100%;
    @media screen and (max-width: 1024px) {
      height: 70%;
    }
  }

  .matching-editor-responses-area {
    margin-top: 0.5em;
    height: 38%;
    width: 100%;
    display: flex;
    @media screen and (max-width: 1024px) {
      height: 30%;
    }
  }

  .allow-custom-response {
    position: absolute;
    z-index: 1;
    background-color: white;
    margin: 0;
    padding-right: 4px;
    left: 5px;
    top: 40px;
    border-radius: 5px;
  }
}