@import "../../../../../../styles/colors.scss";

.extension-activities-file-list {
  padding: 10px 15px;
  box-sizing: border-box;

  &.loading-container {
    height: 100%;
    padding-top: 10%;
    box-sizing: border-box;
  }
}
