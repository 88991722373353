@import '../../../../../styles/colors.scss';

.submitted-answer-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  margin: auto;
  display: flex;
  top: 0px;
  left: 0px;
  // justify-content: center;
  // align-items: center;
  .icon {
    background-color: white;
    border-radius: 50%;
    font-size: 4.5em !important;
    &.correct {
      color: lighten($color-good, 5%);
      background-color: rgba(120, 181, 67, 0.4);
    }
    &.incorrect {
      color: #ff0000;
      background-color: rgba(255, 0, 0, 0.4);
    }
  }
}