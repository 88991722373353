.teacher-assignment-student-course-tab {
  flex-direction: column;
  align-items: center;

  .teacher-assignment-student-course-tab-toggle-container {
    align-self: flex-start;
    display: flex;
    .teacher-assignment-student-course-tab-toggle {
      margin-bottom: 20px;
      .MuiToggleButton-label {
        font-weight: 800 !important;
        font-size: 12px;
        padding: 0px 10px;
        font-size: 1em;
      }
    }
  }

  .assigned-student-courses-actions {
    margin-bottom: 10px;
    margin-left: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
