@import "../../../../../../styles/colors.scss";

.admin-permission-form {
  width: 400px;
  display: flex;
  flex-direction: column;

  .permission-checkbox {
    color: $color-secondary;
    svg {
      fill: $color-secondary;
    }
  }
}
