@import "../../../../../../../styles/colors.scss";

.update-admin-teacher-form {
  .form-container {
    width: 100%;
    label + .MuiInput-formControl {
      margin-top: 13px;
      flex-grow: 1;
    }

    .MuiTextField-root {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .MuiTextField-root, .MuiInput-root {
      flex-grow: 1;
    }
  }
  .form-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &.flex-end {
      margin-top: 10px;
      align-items: flex-end;
      .form-label {
        line-height: unset;
      }
    }
    .form-label {
      width: 200px;
      &.for-checkbox {
        width: 210px;
        min-width: 210px;
      }
    }
    .admin-communication-label {
      width: auto !important;
      white-space: nowrap;
    }
  }

  .search-teacher-search-school-action {
    flex-grow: 1;
  }
}
