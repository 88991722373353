@import "../../../styles/colors.scss";
@import "../../../styles/components.scss";

.media-player-component {
  position: absolute;
  width: 100%;
  height: inherit;
  left: 0px;
  top: 0px;
  .media-player-action-area {
    height: inherit;
    &.enable-play {
      cursor: pointer;
      &:hover {
        background-color: rgba(100, 100, 100, 0.1);
      }
    }
  }
  .media-player-icon {
    font-size: 2em;
    color: $color-primary;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 3px;
    background-color: white;
    border-radius: 15px;
  }
}

.media-player-playing-icon {
  .media-player-icon {
    color: $branding-orange !important;
  }
}

.media-player-playing {
  @extend .vizzle-selected-component-medium;
  .media-player-icon-play {
    color: $branding-orange !important;
  }
}
