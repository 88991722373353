.dropdown-input {
  position: relative;
  display: flex;
  flex-direction: column;
  .spinner {
    position: absolute;
    justify-self: center;
    align-self: center;
    top: 50%;
    z-index: 1;
    &.no-label {
      top: 22%;
    }
  }
}