@import '../../../../styles/colors.scss';

.matching-layout-selector {
  height: 100%;
  .matching-layout-selector-question {
    height: 56%;
    margin: auto;
    display: flex;
  }

  .matching-layout-selector-responses {
    height: 37%;
    margin-top: 0.5em;
    display: flex;
    white-space: nowrap;
    .matching-layout-selector-response {
      @extend .matching-layout-selector-question;
      height: 100%;
      font-size: 0.55em;

      .matching-layout-selector-response-text {
        padding: 0 1px;
      }
    }
  }

  .matching-layout-selector-text {
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 1.5em;
    white-space: initial;
  }

  .matching-layout-selector-background {
    background: lightgray;
  }
}