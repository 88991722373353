@import '../../styles/colors.scss';

.notification-blocker {
  .notification-blocker-content {
    display: flex;
    padding: 20px;
    flex-direction: column;
    min-width: 200px;
  }

  .notification-blocker-icon-container {
    margin: auto;
    margin-bottom: 10px;
  }

  .notification-blocker-icon {
    color: $color-primary;
    font-size: 3em;
    
  }
}