.fitb-page-activity {
  display: flex;
  height: 90%;
  width: 100%;

  .fitb-card-activity-small {
    width: 50%;
  }

  .fitb-card-activity-big {
    width: 100%;
    padding: 15px;
    justify-content: space-between;
    box-sizing: border-box;
    .book-card-component {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
