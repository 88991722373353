@import "../../../styles/colors.scss";

.student-search-form {
  .input-field {
    background-color: $color-gray-monarch-website--light-fading;
    border-radius: 5px;
    flex-grow: 1;
    margin-right: 20px;
    padding: 10px 15px;
    width: 100%;
    margin-top: 5px;
    &:before {
      border-bottom: none !important;
    }
    &:after {
      border-bottom: none !important;
    }
  }

  .search-icon {
    color: $color-secondary;
    font-size: 2em;
  }
}