@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.filterable-multi-selector {
  min-width: 200px;
  @media screen and (max-width: 1024px) {
    min-width: 150px;
  }

  .filter-button {
    text-transform: unset;
    min-height: 43px;
    width: 100%;
    padding-right: 0px;
    border-color: $color-gray-monarch-website--light;
    background: white !important;
    position: relative !important;
    .divider {
      margin-left: 5px;
    }
    .filter-button-icon {
      margin-left: 8px;
      margin-right: 8px;
      color: $color-medium;
    }

    .button-label {
      width: 100%;
      white-space: nowrap;
      text-align: left;
      color: black;
      font-weight: 400;
      @include hideText(1);
    }
  }

  .MuiCircularProgress-root {
    width: 22px !important;
    height: 22px !important;
  }
  &.search-schools-action {
    min-width: 300px;
    margin-left: -15px;
    .MuiCircularProgress-root {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.filterable-multi-select-popper {
  border: 1px solid rgba(27, 31, 35, 0.15);
  box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
  border-radius: 3px;
  min-width: 300px;
  z-index: 10000;
  color: $color-gray-monarch-website--light;
  background-color: #f6f8fa;

  .MuiAutocomplete-popperDisablePortal {
    width: auto !important;
    min-width: 400px;
    position: relative !important;
    transform: none !important;
  }
  .header {
    border-bottom: 1px solid $color-light;
    padding: 8px 10px;
    font-weight: 500;
  }

  .text {
    flex-grow: 1;
    margin-left: 10px;
    // white-space: nowrap;
    max-width: 500px;
    @include hideText(2);
  }

  svg {
    color: $color-secondary;
  }

  .input-base {
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid #dfe2e5;
    input {
      border-radius: 4px;
      background-color: $color-light;
      padding: 8px;
      border: 1px solid #ced4da;
      &:focus {
        box-shadow: 0px 0px 0px 0.2rem rgba(80, 159, 214, 0.25);
        border-color: $branding-blue;
      }
    }

    &.hidden {
      height: 0px;
      width: 0px;
      padding: 0px;
      position: absolute;
      z-index: -100;
      input {
        width: 0px;
        height: 0px;
        padding: 0px;
        &:focus {
          box-shadow: none;
          border-color: #ced4da;
        }
      }
    }
  }

  .actions {
    margin-top: auto;
    width: 100%;
    display: flex;
    .button {
      font-size: 1em;
      text-transform: unset;
      padding-left: 20px;
      padding-right: 20px;
    }
    .cancel {
      @extend .button;
      color: $color-gray;
    }
    .apply {
      @extend .button;
      margin-left: auto;
      color: $color-secondary;
    }
  }

  .MuiAutocomplete-paper {
    margin-bottom: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .MuiAutocomplete-listbox {
    max-height: 42vh;
  }

  .row-entry {
    display: flex;
    align-items: center;
  }
}
.loading-filter {
  margin-left: 40%;
  display: flex;
  align-items: center;
}
