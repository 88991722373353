
.lesson-standard-section {
  padding: 10px 20px;
  background-color: transparent !important;
  color: white !important;;
  box-shadow: none !important;;

  .MuiAccordionSummary-expandIcon {
    order: -1;
    padding-left: 0px;
    margin-right: 10px;
  }
  .expand-icon {
    fill: white;
  }

  .title-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .title {
      font-size: 0.9em;
      margin-right: 10px;
    }
    .click-to-expand-label {
      font-size: 0.6em;
    }
  }
  .standard-title {
    display: flex;
    align-items: center;
    padding-left: 0px;

    .level-title {
      margin-left: auto;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .tooltip {
        margin-left: 10px;
      }
    }
  }

  .standard-list {
    font-size: 1em;
    li {
      margin-bottom: 10px;
      .standard-code {
        font-weight: 500;
      }
    }
  
    .standard-description:first-letter {
      text-transform: capitalize;
    }
  }
}
