.game-play-wheel-spinner {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;

  .spinning-board {
    height: 100%;
    width: 100%;
    position: absolute;
    img {
      padding: 10px;
      box-sizing: border-box;
      margin: auto;
      height: 100%;
    }

    .spinner-img {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    [class*="rotate"] {
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out;
    }

    .rotate-1 {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .rotate-2 {
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    .rotate-3 {
      -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: rotate(225deg);
    }

    .rotate-4 {
      -webkit-transform: rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: rotate(315deg);
    }

    .rotate-full {
      -webkit-transform: rotate(1080deg);
      -ms-transform: rotate(1080deg);
      transform: rotate(1080deg);
      transition: all linear 1500ms;
    }
    .board-outline {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
