@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.categorizing-editor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .MuiTab-root {
    &.Mui-selected {
      color: $color-primary;
      font-weight: 500;
    }
  }

  .MuiTabs-indicator {
    background-color: $color-primary;
  }

  .editor-content-container {
    flex-grow: 1;
    margin-top: 10px;
    height: 100%;
    display: flex;
  }

  .categorizing-prompt-card-key {
    position: absolute;
    z-index: 1;
    margin-left: 5px;
    margin-top: 3px;
    padding: 7px;
    padding: 5px 10px;
    background-color: white;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .response-cards-container {
    .cards-container {
      grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    }
  }

  .response-card-key {
    position: absolute;
    z-index: 2;
    width: 42%;
    left: 3px;
    top: 3px;
    @media screen and (min-width: 1025px) {
      width: 50%;
    }
    .MuiOutlinedInput-input {
      padding: 8px;
      background: white;
      border-radius: 5px;
      font-size: 0.8em;
    }
    .MuiSelect-iconOutlined {
      right: 0px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23) !important;
      fieldset {
        span {
          content: '';
        }
      }
    }
  }

  .card-editor-with-audio {
    font-size: 0.7em;
    padding: 0px 0.5em;
  }
}