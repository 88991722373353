@import '../../styles/colors.scss';

.sortable-table-head {
  .header {
    padding: 9px 10px 5px 5px;
    background: lighten( $color-gray, 30% );
    svg {
      position: absolute;
      right: -15px;
    }
  }
  .sort-label {
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .nosort-label {
    text-align: center;
  }
}