@import '../../../../styles/colors.scss';

.fill-in-the-blank-activity-with-text-style {
  height: 100%;
  font-size: 2em;
  text-align: center;
  padding-top: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  box-sizing: border-box;

  .question-area {
    padding: 20px;
    word-break: break-word;
    overflow-y: scroll;
    max-height: 75%;
    span {
      padding: 2px 5px;
      border-radius: 5px;
    }
    &.clickable {
      span {
        cursor: pointer;
        &:hover {
          background-color: rgba(130, 130, 130, 0.1);
        }
      }
    }
  }
  .answer-text {
    font-weight: 500;
    color: $color-secondary;
    display: initial;
    margin-left: 7px;
    margin-right: 7px;
    word-break: break-word;
  }

  .submit-answer-form {
    display: flex;
    flex: row;
    justify-content: center;
    margin-top: 1.5em;
    position: relative;
    .text-field {
      width: 35%;
      margin-right: 10px;
    }
    .try-again {
      position: absolute;
      top: -1.75em;
      color: $color-bad;
    }
  }

  .wrong-answer {
    border: 4px solid $color-bad;
    border-radius: 4px;
    box-sizing: border-box;
    fieldset {
      border: none !important;
    }
  }
}