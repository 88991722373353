@import "../../../../../styles/colors.scss";

.edit-mastery-sets-button {
  text-align: center;
  color: white !important;
  background-color: $color-secondary !important;
  border-radius: 50%;
  font-size: large !important;
}

.edit-mastery-sets-custom-dialog {

  .content-container {
    display: inline-grid;
  }

  .content {
    display: inline-flex;
    align-items: center;
  }

  .input {
    margin: 0px 10px 0px 10px !important;
  }


  .edit-mastery-sets-custom-ok-button {
    margin-left: auto !important;
    margin-right: auto !important;
    min-width: 100px !important;
  }

}