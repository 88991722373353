@import "../../../../styles/colors.scss";

.student-lessons {
  .student-access-filter {
    margin-right: 10px;
    width: 50px;
  }
  
  .lesson-tags-filter {
    width: 220px;
    margin-right: 10px;
  }

  .last-assignment-date-panel {
    bottom: 10px;
    position: absolute;
    color: $color-secondary;
    font-weight: 500;
    padding: 0px 5px;
    font-size: 0.95rem;
  }

  .lesson-row-action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
  }

  .delete-selected-assignment {
    margin-left: 10px;
  }
  .lesson-card-list-detail-header {
    display: flex;
    align-items: center;
  }
}
