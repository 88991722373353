@import "../../../styles/colors.scss";

.lesson-page-print {
  height: 100%;
  width: 100%;
  .page-print {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;

    .media-player-action-area,
    .matching-component-prompts-media-player-icon,
    .media-player-icon,
    svg {
      display: none;
    }

    .page-print-inner {
      width: 100%;
      position: relative;
      &.build-a-book-single-container {
        height: 500px;
        margin-top: 50px;
        .book-card-component {
          margin: auto;
          width: 90%;
          max-width: 500px;
        }
      }
      &.build-a-book-facing-container {
        margin-top: 20px;
        transform: scale(0.8);
        position: absolute;
        top: 0px;
        flex-direction: row;
        .book-card-component {
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          margin: auto;
          justify-content: center;
          .book-card {
            max-width: 450px;
            margin-bottom: 40px;
            min-height: 430px;
            max-height: 430px;
            height: 430px;
            // .book-card-text {
            //   margin-top: 0px !important;
            // }
          }
        }
      }
      &.matching-container {
        height: 70%;
        width: 100%;
        margin-top: 60px;
        .matching-component-prompts {
          margin-bottom: 10px;
        }
        .matching-component-prompts-area-question-container {
          width: 70%;
          max-height: 330px !important;
        }
        .matching-component-responses-area {
          width: 100% !important;
          .matching-component-response-card {
            min-height: 200px !important;
          }
        }
      }
      &.sorting-container {
        margin-top: -50px;
        transform: scale(0.85);
        height: 100%;
        width: 100%;
        .sorting-prompt-area {
          max-height: 400px;
        }
        .sorting-response-card {
          max-height: 200px;
        }
      }
      &.game-container {
        margin: auto;
        transform: scale(0.85);
        margin-top: -50px;
        height: 100%;
        width: 100%;
        .card-container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-grow: 1;
          .game-card-cell {
            width: 100%;
          }
        }
      }
      &.sequencing-container {
        height: 70%;
        width: 100%;
        margin-top: 60px;
        .sequencing-prompt-draggable {
          grid-gap: 20px !important;
        }
        .prompt-cards-area {
          min-height: 200px !important;
          max-height: 200px !important;
          flex-wrap: wrap;
          .propmpt-container {
            min-height: 130px !important;
          }
        }
        .response-cards-area {
          max-height: 150px !important;
        }
      }

      &.cause-and-effect-container {
        height: 70%;
        width: 100%;

        svg {
          display: block;
        }

        .item-placeholder {
          background: white;
          border: 3px solid $color-gray-monarch-website--light;
        }

        .cards-area-container {
          margin-top: 50px;
        }

        .cause-and-effect-area {
          &.cause-length-1 {
            grid-template-columns: auto;
            width: 82%;
            height: 60%;
          }

          &.cause-length-2 {
            width: 82%;
            grid-template-columns: auto;
          }
        }

        .card-margin-2-cards {
          width: 35%;
          max-width: 220px;
        }

        .card-margin-4-cards {
          width: 30%;
          margin-left: 1rem;
          margin-right: 1rem;
          max-width: 220px;
        }

        .card-margin-6-cards {
          width: 30%;
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }
      &.categorizing-container {
        height: 80%;
        transform: scale(0.85);
      }
      &.flexible-matching-container {
        height: 80%;
        transform: scale(0.85);
      }
      &.multiple-choice-container {
        margin-top: 50px;
        height: 80%;
      }
      &.multiple-choice-v2-container {
        margin-top: 50px;
        height: 80%;
        
      }
    }
  }
}

@media print {
  html,
  body {
    height: 100%;
  }
  .page-print {
    page-break-before: always;
  }
}

@page {
  margin: 5mm !important;
  size: auto;
}
