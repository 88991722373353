.single-field-form {
  .single-field-form-textfield {
    width: 30vw;
    label {
      font-size: 1.1em;
    }
    input {
      font-size: 1.2em;
    }
    
  }
}