.hotspot {
  position: absolute;
  cursor: pointer;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
  &.not-playable {
    z-index: 1;
  }
  &:hover {
    opacity: 0.7;
  }
  &.circle {
    border-radius: 50% !important;
  }

  &.triangle {
    background-color: transparent !important;
    border: 0px !important;
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .text-lines-text-content {
      margin-top: 15px;
      z-index: 1;
    }
  }

  &.no-opacity {
    opacity: 1 !important;
  }
}
