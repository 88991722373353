@import '../../styles/colors.scss';

.notification-dialog {
  .notification-dialog-title {
    padding: 10px 0px;
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
  }
  .notification-dialog-icon {
    font-size: 5em;
    margin: auto;
    margin-bottom: 15px;
    display: flex;
    color: $color-secondary;
    width: 100%;
  }
  .notification-dialog-text {
    color: $color-dark;
    font-size: 1.2em;
    margin-top: 25px;
    text-align: center;
  }
  .notification-dialog-button {
    margin: auto;
    margin-bottom: 10px;
    width: 70%
  }
}