@import '../../styles/colors.scss';

.full-screen-dialog-component {
  .full-screen-dialog-toolbar {
    background-color: $color-primary;
  }
  .full-screen-dialog-close-button {
    margin-left: auto;
  }

  .close-button {
    margin-left: 10px;
  }
}