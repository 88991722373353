.matching-editor-question-key {
  position: absolute;
  z-index: 1102;
  left: 5px;
  width: 29%;
  @media screen and (max-width: 1024px) {
    width: 35%;
  }
  top: 3px;
  display: flex;
  .matching-editor-question-tooltip {
    padding-top: 6px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }
}
