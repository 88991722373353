@import "../../../../styles/mixins.scss";
@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";
@import "../../../../styles/fonts.scss";

.objective-card-container {
  margin-right: 15px;
  position: relative;
  height: 100%;
  .action-area {
    height: 100%;
  }

  .title {
    padding: 13px 15px 8px 15px;
    background-color: lighten($color-gray, 30%);
    height: 16%;
    position: relative;
    .MuiCardHeader-title {
      font-size: 1.2em !important;
      color: $color-primary;
      position: absolute;
      top: 4px;
      width: 80%;
      @include hideText(2);
    }
    .MuiCardHeader-subheader {
      float: right;
      font-size: 1.07em !important;
      font-weight: 500;
      margin-top: auto;
      position: absolute;
      bottom: 2px;
      right: 10px;
    }
    .MuiCardHeader-avatar {
      position: absolute;
      top: 10px;
      right: 0px;
      color: $color-secondary;
    }
  }
  .content {
    height: calc(84% - 20px);
    padding: 0px;
    .graph-area {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60%;
      padding-top: 15px;
      box-sizing: border-box;
    }

    .summary {
      margin-top: 10px;
      margin-left: 10px;
      display: grid;
      grid-template-columns: 40% 60%;
      align-items: center;
      .percent-correct {
        font-size: 3em;
        font-weight: 500;
        color: $color-gray-monarch-website;
        &.percent-small {
          font-size: 2.2em;
        }
      }
      .description {
        font-weight: 500;
        color: $color-gray--light;

        .score {
          color: $color-dark;
        }
      }
    }

    .benchmark-reach {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      color: white;
      padding: 3px 10px;
      position: absolute;
      bottom: 7px;
      right: 0px;
      background-color: $branding-blue;
      font-size: 0.9em;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
}
