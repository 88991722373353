@import '../../../../styles/colors.scss';

.lesson-activity-top-bar {
  box-shadow: none !important;
  .lesson-activity-toolbar {
    background-color: $color-blue-primary;
    color: white;
    min-height: 50px; 
  }

  .button-container {
    display: flex;
    margin-left: auto;
  }
  .exit-button {
    min-height: 45px;
    color: white;
    background-color: transparent !important;
    font-size: 1em;
    &:hover {
      background-color: $branding-blue !important;
    }
  }
}