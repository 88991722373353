@import "../../../../styles/colors.scss";

.my-lessons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  box-sizing: border-box;

  .container {
    height: 100%;
    width: 100%;
    position: relative;

    .my-lessons-container {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }

  .lesson-tags-filter {
    width: 220px;
    margin-right: 10px;
  }

  .grade-filter {
    width: 200px;
    margin-right: 10px;
  }

  .activity-filter {
    width: 200px;
    margin-right: 10px;
  }

  .lesson-row-action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
  }

  .lesson-card-list-detail-header {
    display: flex;
    align-items: center;
    display: grid;
    grid-template-columns: 23% 76%;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 26% 73%;
    }
    .content {
      flex-wrap: wrap;
      gap: 5px;
    }
  }
}
