.image-list {
  width: 100%;
  height: 100%;

  &.one-image {
    display: flex;
  }

  &.multiple-images {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 100%));
  }
  .image-container {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    object-fit: contain;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
}
