.player-turn {
  display: flex;
  width: 82%;
  height: 100%;
  margin: auto;
  cursor: pointer;
  position: relative;
  .spinner-background {
    position: absolute;
    height: 100%;
    display: flex;
    width: 100%;
    z-index: -1;
    opacity: 0.25;
    left: 0px;
    div {
      margin:auto;
    }
  }

  .arrow-icon-container {
    position: relative;
    width: 90px;
    margin-right: 30px;
  }
  .arrow-icon {
    @keyframes arrowPoint {
      0% {
        left: 0px;
      }
      50% {
        left: -10px;
      }
      100% {
        left: 0px;
      }
    }
    position: absolute;
    top: 0px;
    width: 70px;
    bottom: 0;
    margin: auto;
    transition: all 0.5s ease-in-out;
    animation: arrowPoint normal 1.5s infinite ease-in-out; /* Opera */
  }
  .player-turn-icon {
    height: 100%;
    display: flex;
    img {
      height: 6em;
      margin-right: 20px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .player-turn-text {
    margin: auto;
    // font-size: 1.5em;
    font-weight: 500;
    word-break: break-word;
  }
}