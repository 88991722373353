@import "../../styles/colors.scss";

.lesson-tag {
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .lesson-tag-tooltip {
      margin-left: 10px;
      font-size: 0.85rem;
    }
  }

  .no-tag {
    margin-top: 10px;
    margin-bottom: 10px;
    color: $color-secondary;
    font-weight: 500;
  }

  .lesson-tag-container {
    display: flex;
    flex-wrap: wrap;
    min-height: 50px;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 0;
    margin-bottom: 10px;
  
    .lesson-tag-chip {
      font-size: 1em;
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: #e0e0e0;
      svg {
        width: 25px;
        height: 25px;
        color: $color-medium;
      }
    }
  }

  .auto-complete-textfield {
    background-color: white;
    border-radius: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    ::placeholder {
      color: $color-dark;
      opacity: 0.8;
    }
  }
}

.lesson-tag-add-new-tag-container {
  display: flex;
  align-items: center;
  .new-tag-icon {
    color: $color-primary;
    margin-right: 10px;
  }
}

.lesson-tag-tag-render {
  display: flex;
  align-items: center;
  .tag-icon {
    color: $color-tertiary;
    margin-right: 10px;
  }
}