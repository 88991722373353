@import '../../../../styles/colors.scss';

.play-interaction-popup {
  .content {
    box-sizing: border-box;
    &.blue-background {
      background-color: $branding-blue;
    }
  }
  .close-icon {
    float: right;
    color: $color-primary;
    padding: 10px 20px;
    border-radius: 2px;
    background-color: lighten( $color-primary, 45% );
    cursor: pointer;
    position: absolute;
    right: 28px;
    top: 24px;
    z-index: 1102;
    &:hover {
      background-color: lighten( $color-primary, 35% );
    }
  }
  .content-component {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    border: none;
    box-sizing: border-box;
    padding-top: 5px;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .book-card-popup {
    width: 98% !important;
    height: 96%;
    display: flex;
    margin: auto;
    .book-card {
      box-shadow: none;
    }
    .replay-button-container {
      display: none;
    }
    .book-card-activity-small {
      width: 100% !important;
    }
    > div {
      margin: 0px !important;
    }
    .media-player-icon {
      left: 10px;
    }
  }

  .matching-popup {
    
    .matching-activity {width: 100%;}
  }
}