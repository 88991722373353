.sequencing-activity-prompt {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  padding: 5px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  .prompt-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: none;
    .sequencing-component-image {
      flex-grow: 1;
      background-size: contain;
    }
    .sequencing-component-text {
      padding: 5px;
      &.text-with-image {
        max-height: 30%;
      }
    }
  }

  .sequence-media {
    z-index: 1;
  }
}