.math-editor-container-dialog {
  .MuiDialog-scrollPaper {
    align-items: flex-start;
  }

  .math-editor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 500px;
    max-width: 1000px;
    gap: 20px;
    .math-container {
      margin-top: 30px;
      height: 70px;
      padding-right: 30px;
      padding-left: 30px;
      box-sizing: border-box;

      .math-view-component {
        font-size: 2em;

        .ML__fieldcontainer__field {
          overflow-x: auto;
        }
      }
    }
  }
}

.ML__keyboard {
  z-index: 1400 !important;
}
