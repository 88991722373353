@import '../../../styles/colors.scss';

.copy-to-edit-action {
  margin-left: auto !important;
}

.copy-to-edit-dialog {
  .dialog-content {
    width: 400px;
    padding: 20px 0px;
  }
  
  .content-message {
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      color: $branding-orange;
      font-size: 3em;
      margin-right: 10px;
    }
  }
}
