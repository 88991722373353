.district-school-selector {
  width: 100%;
  .district-school-selector-dropdown {
    width: 100%;
    max-width: unset;
  }
  .margin-top {
    margin-top: 10px;
  }

  .school-text {
    margin-top: 15px;
  }

  .form-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .form-label {
      width: 200px;
    }
  }
}
