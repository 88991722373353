.video-player-dialog {
  min-width: 75vw;
  .MuiDialog-paper {
    max-width: none;
    max-height: none;
  }

  .video-player-dialog-content {
    margin: 20px;
    .video-player {
      width: 55vw;
      height: 70vh;
      outline: none;
      border-radius: 5px;
    }
  }
}

.video-player-inline-video-container {
  display: flex;
  height: 100%;
}
.video-player-inline-video {
  background-size: contain;
  margin: auto;
  outline: none;
}