@import '../../../../../styles/colors.scss';

.teacher-course-topic-guide {
	margin-top: 5px;
	width: 100%;
  height: 98%;
	display: flex;
	flex-direction: column;

	.pdf-container {
		flex-grow: 1;
		padding-top: 10px;
		box-sizing: border-box;
	}

	iframe {
		width: 100%;
		height: 100%;
	}

	.unit-guide-state-selector {
		border: 2px solid $color-gray;
		margin-right: 10px;
		box-sizing: border-box;
	}
}
