.fill-in-the-blank-preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .example {
    font-style: italic;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  .example-text {
    margin-top: 20px;
    font-style: italic;
    margin-bottom: 20px;
  }
  .preview {
    word-break: break-word;
    overflow-y: scroll;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  .preview-question {
    padding: 5px 10px;
    display: flex;
    flex-wrap: wrap;
    overflow: visible;
    white-space: pre;
    flex-direction: row;
    align-items: baseline;
  }

  .pre-blank {
    margin-right: 5px;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .post-blank {
    margin-left: 5px;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
}
