.lesson-pages-container {
  width: 100%;
  height: 100%;
  .lesson-pages-container-slider {
    height: 100%;
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-list, .slick-track, .slick-slide > div {
      height: 100%;
    }
    .slick-center {
      display: flex;
      justify-content: center;
    }
  }

  .activity-container {
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-items: center;
    align-items: center;
    position: relative;
  }

  .center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
