.overlay-mask-loader-component {
  .overlay-mask-loader {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100000;
  }

  .overlay-mask-loading-message {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    background: white;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .overlay-mask-loading-icon {
    margin: auto;
  }

  .overlay-mask-loading-text {
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
