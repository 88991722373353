.mysetting-profile-info-flexbox {
  width: 100%;
  .flex-my-setting-on-edit {
    margin-top: 20px;
    .action-button {
      min-width: 100px;
    }
    .margin-right {
      margin-right: 10px;
    }
  }
  .flex-teacher-setting-on-edit {
    // margin-top: 20px;
    height: 60px;
    .action-button {
      min-width: 100px;
    }
  }
  .my-setting-title {
    font-weight: 500;
  }
}
