@import '../../../../../styles/colors.scss';

.external-links-table-container {
  width: 100%;
  word-break: break-all;
  .external-links-table {
    width: 100%;
    a {
      color: $color-primary;
      font-weight: 500;
      text-decoration: none;
    }

    .table-actions {
      min-width: 100px;
    }
  }
}

.no-external-links {
  padding-left: 0px;
}