@import "../../../styles/colors.scss";

.teacher-course-search-bar {
  padding: 15px 10px;
  border-radius: 3px;

  .search-bar {
    padding: 0px 10px;
    border-radius: 3px;
    z-index: 2;
    min-height: 30px;

    .search-container {
      .title-class {
        font-size: 0.8em;
      }
    }
    .MuiSelect-select {
      background-color: white !important;
      border-radius: 5px !important;
      padding: 10px 32px 10px 15px;
      min-height: 20px;
    }

    .search-button {
      border: 2px white solid;
    }

    .add-button {
      margin-left: 10px;
      border: 2px white solid;
    }

    .actions-container {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      &.center {
        justify-content: center;
      }
    }
  }
}
