@import '../../styles/colors.scss';

.vizzle-slider {
  display: flex;
  align-items: center;

  .label {
    min-width: 50px;
    font-weight: 500;
  }
  .value {
    padding: 5px 10px;
    background-color: $color-secondary;
    color: white;
    border-radius: 5px;
    font-weight: 400;
  }
  .range {
    margin-left: 20px;
    white-space: nowrap;
    font-weight: 400;
  }

  .slider {
    margin-left: 40px;
    margin-right: 40px;

    &.MuiSlider-root {
      color: $color-secondary;
      .MuiSlider-rail {
        color: $color-medium;
      }
      .MuiSlider-mark {
        color: black;
        width: 3px;
        height: 3px;
      }
      .MuiSlider-markActive {
        background-color: black;
      }
      .MuiSlider-thumb {
        width: 18px;
        height: 18px;
        margin-top: -9px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .label, .value, .range {
      font-size: 1.3em;
    }
  }
}