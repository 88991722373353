body {
  margin: 0;
  font-family: "Rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px !important;
  margin: 0px;
}
* {
  outline: none;
}
@media screen and (max-width: 1024px) {
  * {
    letter-spacing: 0.01em;
    font-size: calc(1vw + 0.15em);
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
}

.intercom-launcher {
  bottom: 75px !important;
}

#chat-widget-container {
  top: -8px !important;
  right: 25px !important;
  z-index: 1200 !important;
}
#chat-widget-minimized {
  transform: scale(0.7);
}

.acsb-trigger-hide {
  display: none !important;
  opacity: 0 !important;
  z-index: -1 !important;
}

.rvNotification {
  display: none;
}
