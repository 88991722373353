@import "../../../../../../styles/colors.scss";

.admin-add-student-form {
  display: flex;
  flex-direction: column;
  width: 420px;

  .form-teacher-schoology-name {
    margin-top: 10px;
  }
  
  .MuiFormControl-root {
    margin-top: 10px;
  }
  .MuiInputLabel-asterisk {
    color: $color-bad;
  }
}
