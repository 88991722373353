@import '../../../../../styles/colors.scss';
@import '../../../../../styles/components.scss';

.activity-instruction-dialog {
  
  .close-button {
    position: absolute;
    color: $branding-blue;
    top: 2px;
    right: 2px;
    z-index: 10;
  }

  .content {
    min-width: 250px;
    padding-top: 40px;
    padding-bottom: 20px;
  }


}

.activity-instruction-dialog-with-audio {
  .MuiDialog-paper {
    @extend .vizzle-selected-component-medium;
  }
}