@import "../../../../styles/colors.scss";

.answer-key-move-indicator {
  margin-right: 5px;
  .icon {
    fill: $color-secondary;
  }

  h5 {
    font-size: 0.8rem;
  }
  .answer-key-move-indicator-dropdown {
    .MuiSelect-select {
      padding: 3px 5px;
      background-color: white;
    }
  }
}
