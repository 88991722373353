.multiple-choice-activity {
  position: relative;
  &.side-by-side {
    height: 97%;
    width: 98% !important;
    .multiple-choice-component-question {
      height: 100%;
      width: 55%;
    }

    .multiple-choice-component-responses-form {
      width: 45%;
      padding: 0 15px;
      height: 100%;
    }
    .multiple-choice-component-responses {
      height: 100%;
      row-gap: 2.3em;
      padding: 5px 0;
      box-sizing: border-box;

      .multiple-choice-component-response {
        min-height: 70px;
      }
    }
  }
  &.single-page {
    width: 60% !important;
    min-width: 850px;
    row-gap: 0em;
    background: white;
    border-radius: 5px;
    margin-top: -5px;

    .multiple-choice-component-question {
      padding: 5px 10px;
      box-sizing: border-box;
      height: 100%;
    }
    .multiple-choice-component-responses-form {
      // height: 100%;
      padding: 0px 15px;
      margin-bottom: 5px;
      max-height: 50%;
      .multiple-choice-component-response {
        min-height: 45px;
      }
    }
  }

  .multiple-choice-component-responses-form {
    background: white;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .multiple-choice-component-response-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 0 0 0 10px;
    box-sizing: border-box;
    cursor: pointer;
    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-self: center;
    }
    .prefix {
      font-weight: 500;
      margin-right: 0px;
    }
  }

  .only-text {
    padding-top: 7px !important;
  }
}

.multiple-choice-submit-answer-action {
  margin-left: auto !important;
  width: 160px;
  height: 50px;
}
