.keywords-editor {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .add-keyword {
    display: flex;
    flex-direction: row;
    .text-field {
      width: 85%;
      margin-right: 10px;
      margin-left: 10px;

      .MuiInputBase-input.Mui-disabled {
        background-color: white;
      }
      input::placeholder {
        color: black !important;
        opacity: 0.8;
      }
    }

    .no-keyword-text {
      font-size: 0.95em;
    }
  }

  .keywords {
    margin-top: 10px;
    overflow-y: auto;
    box-sizing: border-box;
    .chip {
      margin-bottom: 5px;
      margin-right: 5px;
      .MuiChip-label {
        white-space: initial;
      }
    }
    height: 20vh;
    overflow-x: auto;
    width: 100%;
  }
}
