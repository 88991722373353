@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.natural-theme-container {
  margin: auto;
  background-color: $color-primary;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .card {
    margin: auto;
    width: 300px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:hover {
      @extend .vizzle-selected-component;
      cursor: pointer;
    }
  }
}
