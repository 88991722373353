.teacher-courses-row-actions {
  margin-left: auto !important;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;

  button {
    margin-right: 10px;
    min-height: 40px;
  }
}
