@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.editable-form {
  max-width: 450px;
  display: flex;
  align-items: flex-end;

  .editable-field-title {
    margin-right: 10px;
    flex-shrink: 0;
  }
  .form-title {
    font-size: 1.7em;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    @include hideText(1);
  }

  .editable-form-display {
    display: flex;
  }
  .edit-icon {
    fill: white;
    background-color: $color-secondary;
    padding: 4px;
    margin-left: 15px;
    border-radius: 25px;
    font-size: 1.3em;
    cursor: pointer;
  }
}