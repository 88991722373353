.game-card-grid-preview {
  height: 100%;
  width: 100%;
  .game-grid {
    height: 100%;
    width: 100%;
  }

  .card-container {
    position: relative;
  }
  .small-icon {
    z-index: 101;
    width: 100%;
    height: 100%;
    font-size: 0.75em;
    svg {
      top: 5px;
    }
  }
  .disable-cell {
    pointer-events: none;
  }

  .wheel-spinner-container {
    grid-area: spin;
    display: flex;
    margin: auto;
    width: 100%;
    height: 100%;
  }

  @keyframes blink {
    50% {
      z-index:100;
      border-color: #FBAB30;
      background-color: #d9d9d9; } }

  .highlight--blink {
    animation-name: blink;
    animation-duration: .5s;
    animation-timing-function: step-end;
    animation-iteration-count: infinite;
    animation-direction: alternate;
   }

  .blocker-no-state-change {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1003;
    left: 0;
    display: none;
  }
  .blocker-no-state-change-show {
    display: block;
  }
}