@import "../../styles/colors.scss";

.color-picker-button {
  border-radius: 5px;
  white-space: nowrap;
  border: 1px solid $color-gray-monarch-website--light;
  min-width: 120px !important;
  text-transform: none !important;
}

.color-pick-modal-container {
  .sketch-picker {
    width: 300px !important;
  }
}