@import "../../styles/colors.scss";

.profile-box {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  .profile-box-label {
    font-weight: 500;
  }
  .MuiFormHelperText-root {
    color: $color-bad;
  }
  .MuiInputLabel-asterisk {
    color: $color-bad;
  }
  .end-decorator-container {
    position: absolute;
    right: -40px;
  }
}