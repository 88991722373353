.student-group-form {
  padding: 15px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  .student-selector-table-container {
    flex-grow: 1;
    overflow-y: auto;
    min-height: 400px;
    position: relative;
    margin-top: 20px;
  }
  .student-selector-container {
    display: block;
    height: 100%;
    width: 95%;
    grid-template-rows: unset;
    .students {
      position: absolute;
    }
  }
}
