@import '../../styles/colors.scss';

.url-link {
  color: $color-medium !important;
  margin-right: 1em !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;

  @media screen and (min-width: 1030px) {
    font-size: 0.9rem !important;
  }

  @media screen and (min-width: 1600px) {
    font-size: 1rem !important;
  }

  @media screen and (min-width: 1800px) {
    font-size: 1.15rem !important;
  }

  height: 50px !important;

  &.url-selected-link {
    font-weight: 500 !important;
    border-bottom: 3px solid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
