@import '../../styles/colors.scss';

.leson-label {
  width: 200px;
  background: $color-tertiary;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}
