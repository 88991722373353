.button-list-component {
  display: flex;
  width: fit-content;
  text-transform: none;
}

.button-list-component-vertical {
  flex-flow: column;
  flex-wrap: wrap;
  button {
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    text-transform: none;
  }
}

.button-list-component-horizental {
  flex-flow: row;
  button {
    margin-right: 10px;
    text-transform: none;
  }
}