@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.sorting-card-editor {
  display: flex;
  height: 100%;
  width: 100%;

  .sorting-card-editor-left-column {
    width: 50%;
    height: 100%;
  }

  .sorting-card-editor-right-column {
    width: 55%;
    height: 100%;
  }

  .sorting-card-editor-instruction {
    height: 18%;
    margin-bottom: 7px;
    margin-left: 0px;
  }


  .sorting-card-editor-prompts {
    display: flex;
    flex-direction: column;
    margin-right: 5%;
    font-size: 0.9em;
    height: 80%;
    .sorting-card-editor-prompt {
      height: 50%;
      margin-bottom: 0.5em;
      position: relative;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .sorting-card-editor-responses {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.7em;

    .sorting-card-editor-response {
      width: 47%;
      height: 23.7%;
      margin: 0.2em;
      position: relative;

      @media screen and (min-width: 1025px) {
        width: 48%;
      }
    }
  }

  .prompt-card-key {
    position: absolute;
    z-index: 1;
    margin-left: 5px;
    margin-top: 3px;
    padding: 7px;
    padding: 5px 10px;
    background-color: white;
    border-radius: 5px;
    font-weight: 500;
  }

  .response-card-key {
    position: absolute;
    z-index: 2;
    width: 42%;
    @media screen and (min-width: 1025px) {
      width: 40%;
    }
    .MuiOutlinedInput-input {
      padding: 8px;
      background: white;
      border-radius: 5px;
      font-size: 0.8em;
    }
    .MuiSelect-iconOutlined {
      right: 0px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23) !important;
      fieldset {
        span {
          content: '';
        }
      }
    }
  }

  .response-key-overlay {
    position: absolute;
    background-color: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.sorting-response-key {
  white-space: normal;
}