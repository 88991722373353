@import "../../../../../../styles/colors.scss";

.copy-lesson-multiple-pages-action-dialog {
  .custom-dialog-title {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $color-primary;
  }

  .container {
    height: calc(100% - 64px);
    padding-top: 5px;
    box-sizing: border-box;
  }

  .tab-container {
    height: 100%;
  }
  .MuiTab-root {
    background-color: $color-primary;
    margin-right: 5px;
    opacity: 0.9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .Mui-selected {
    color: white !important;
    font-weight: 500;
    opacity: 1;
  }
  .MuiTabs-indicator {
    background-color: $color-secondary;
    height: 3px;
  }
}