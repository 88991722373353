@import '../../../styles/colors.scss';

.lesson-detail-panel-play-button {
  color: $color-primary;
  position: absolute;
  top: 34%;
  left: 45%;
  .play-icon {
    fill: $color-primary;
    font-size: 90px;
  }
}