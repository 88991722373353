@import "../../../styles/icons.scss";
@import "../../../styles/buttons.scss";
@import "../../../styles/colors.scss";

.LoginForm {
  height: 100vh;
  padding-top: 100px;
  text-align: center;
  box-sizing: border-box;

  .login-form-wrapper {
    // Fix for safari 10.1 that does not support fit-content
    width: 345px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
  }
  
  img {
    width: 200px;
  }

  .login-form {
    margin-top: 20px;

    .form-input {
      width: 305px;
      margin-bottom: 20px;
    }

    .form-icon {
      margin-top: 22px;
      margin-right: 10px;
      width: 22px;
    }

    .login-button {
      margin-top: 10px;
      margin-left: 10px;
      height: 45px;
    }

    .remember-me-container {
      display: flex;
      justify-content: space-between;
    }
    .login-form-remember-me {
      float: left;
      margin-left: 0px;
      color: $color-gray-monarch-website;
    }
  }

  .login-form-error-area {
    margin-bottom: 20px;
    width: 335px;
    margin-left: 10px;
    display: inline-block;
    text-align: left;
  }

  .forgot-password {
    margin-top: 8px;
    color: $color-gray-monarch-website;
  }

  .free-trial-link {
    display: flex;
    justify-content: flex-end;
    margin-top: -7px;
    a {
      margin-right: 5px;
      color: $color-gray-monarch-website !important;
      text-decoration: none;
      font-size: 1.07rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .children-container {
    margin-top: 20px;
    margin-left: 10px;
  }
}
