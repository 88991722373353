@import "../../styles/colors.scss";

.chat-panel {
  width: 400px;
  height: calc(100vh - 100px);
  max-height: 520px;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    justify-content: space-between;
    background-color: $color-secondary;
    padding: 7px 20px;
    box-sizing: border-box;
    color: white;
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    box-sizing: border-box;
    margin: 5px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 0 5px;
    margin-top: auto;
    .chat-record {
      border-radius: 10px;
      padding: 10px;
      max-width: 85%;
      font-size: 0.9em;
      .chat-footer {
        margin-top: 5px;
        font-size: 0.78em;
      }
      &:first-child {
        margin-top: auto;
      }
      @media screen and (max-width: 1024px) {
        font-size: 1em;
        .chat-date {
          font-size: 0.88em;
        }
      }
      &.left {
        align-self: flex-start;
        background-color: $color-gray-monarch-website--light;
        .chat-date {
          text-align: start;
        }
      }

      &.right {
        align-self: flex-end;
        background-color: $color-gray-monarch-website--light-fading;
        .chat-date {
          text-align: end;
        }
      }
    }
  }

  .input-text-panel {
    padding-left: 5px;
    display: flex;
    box-sizing: border-box;
    .input-text {
      .MuiOutlinedInput-input {
        padding: 10px 14px;
      }
    }
    .submit-button {
      color: $color-tertiary;
    }
    margin-bottom: 5px;
  }
}