.assigned-student-courses-list {
  margin-top: 20px;
  .loading-spinner-container {
    margin-top: 50px;
  }

  .list-container {
    display: grid;
    grid-template-columns: repeat(5, calc(20% - 15px));
    grid-gap: 15px;
  }
}