@import '../../../../../styles/colors.scss';

.matching-component-prompts-container {
  height: 100%;
  width: 100%;
  
  .matching-component-prompts-area {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .matching-component-prompts-area-question-container {
    height: 100%;
    display: grid;
    grid-template-columns: 55% 45%;
    padding: 10px;
    box-sizing: border-box;
    width: 57%;
    margin: auto;
    position: relative;
  }

  .matching-component-prompts-area-question-video {
    width: 25%;
    margin-right: auto;
    display: flex;
    position: relative;
    height: 100%;
  }

  .matching-component-prompts-area-question {
    height: 100%;
    width: 100%;
  }

  .matching-component-prompts-area-question-placeholder {
    height: 100%;
    width: 97%;
    margin-left: 10px;
    overflow: visible;
  }

  .matching-component-prompts-media-player-icon {
    font-size: 2em;
    color: $color-primary;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 3px;
    background-color: white;
    border-radius: 15px;
  }
}