@import "../../../../../styles/colors.scss";

.view-student-answers-notification {
  position: absolute;
  top: 3px;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  .view-student-answers-notification-message {
    z-index: 10000;
    font-size: 1.7em;
    background-color: $color-secondary;
    padding: 5px  15px;
    border-radius: 10px;
    color: white;
  }
}
