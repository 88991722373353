@import "../../../../styles/colors.scss";

.objective-detail-container {
  height: 90vh;
  overflow-y: auto;
  background-color: lighten($branding-blue, 10%);
  padding: 20px 5%;
  box-sizing: border-box;
  color: $color-gray--dark;
  border-radius: 6px;

  h5 {
    font-size: 1.7rem;
  }

  h6 {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  .title-name {
    font-weight: 500;
    margin-bottom: 10px;
  }

  .lesson-result-container {
    display: flex;

    .lesson-result {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .criteria-met {
        font-size: 2.6em;
        color: $color-secondary;
        margin-right: 10px;
        align-self: start;
      }


      .master-detail {
        .highlight {
          color: $color-secondary;
          font-weight: 500;
        }

        .result {
          display: flex;
          margin-bottom: 5px;

          .title {
            margin-right: 10px;
          }
        }

      }
    }

    .action-container {
      align-self: flex-end;
      margin-right: 5px;
    }
  }

  .graph-area {
    margin-top: 20px;
    margin-bottom: 5px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    background-color: white;
    border-radius: 5px;
    position: relative;
  }

  .objective-lesson {
    margin-top: 20px;
  }

  .lesson-standards {
    margin-top: 10px;
  }

  .objective-goal-name {
    margin-right: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 500px;
  }

  .master-set {
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .master-set-text {
    margin-right: 10px;
  }

}