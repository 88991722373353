.fitb-page-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &.card-padding {
    padding: 8px;
    box-sizing: border-box;
  }

  .fitb-page-activity-component {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .fitb-page-activity-image {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    margin-bottom: 0em;
    background-size: contain;
  }

  .fitb-list-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .fillin-the-blank-page-line {
    font-size: 1em;
    height: auto;
    .question-area {
      padding: 5px 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      overflow: visible;
      white-space: pre;
    }

    .MuiInputBase-formControl {
      input {
        padding: 8.5px 4px;
      }
    }
    .submit-answer-form {
      margin-top: 5px;
    }
    .text-field {
      position: relative;
      display: flex;
      align-items: center;
      .try-again {
        position: static;
        white-space: nowrap;
        margin-right: 10px;
      }
    }

  }
  .media-component {
    z-index: 100;
  }
}
