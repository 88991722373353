.reset-password-form-dialog {
  .reset-password-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 15px;
    min-width: 350px;
  }

  .vizzle-logo {
    width: 250px;
    margin-bottom: 20px;
  }
  .vizzle-custom-dialog-actions {
    margin-top: 12px;
    justify-content: center;
  }
}
