@import '../../styles/colors.scss';

.no-data-found-paper {
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;

  .no-data-found-icon {
    color: $color-secondary;
    font-size: 4em;
  }

  .no-data-found-message {
    color: $color-medium;
    font-size: 1.5em;
  }
}