.celebration-player {
  width: 100%;
  height: 100%;
  display: flex;
  .celebration-dialog-container {
    margin: auto;
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    min-height: 480px;
  }

  canvas {
    margin: auto;
  }

  .loading {
    width: 800px;
  }
}