@import '../../../../styles/colors.scss';

.delete-assigned-action {
  background: $color-bad !important;
  color: white !important;
  margin-left: 20px;
}

.delete-assigned-action-content {
  display: flex;
  flex-direction: column;

  .Mui-checked {
    color: $color-secondary;
  }
}