@import '../../../../styles/colors.scss';

.page-data-navigator {
  width: 91%;
  display: flex;

  .lesson-page-data-button {
    height: 0%;
    margin-top: auto;
    margin-bottom: auto;
    svg {
      color: $color-dark;
    }
  }

  .lesson-page-data-previous {
    svg {
      transform: scaleX(-1);
    }
    @extend .lesson-page-data-button;
    margin-left: 5px;
  }
  
  .lesson-page-data-next-button {
    @extend .lesson-page-data-button;
    margin-left: 5px;
  }

  .Mui-disabled {
    svg {
      opacity: 0.5 !important;
    }
  }
}
