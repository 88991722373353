@import '../../../../../../styles/colors.scss';

.admin-license-form-multiple {
  display: flex;
  flex-direction: column;
  width: 450px;
  position: relative;

  .MuiFormControl-root {
    margin-top: 15px;
  }
  .admin-license-date-selector {
    margin-right: 10px;
  }

  .license-date-form-to-container {
    background: none;
    padding: 10px 0px 10px 0px;

    .MuiButtonBase-root {
      svg {
        color: $color-primary;
      }
    }
    .date-picker {
      input {
        color: $color-dark;
      }
    }
  }
}
