.teacher-course-filter {
  margin-top: 25px;
  width: 100%;
  display: flex;
  gap: 10px;

  .MuiSelect-select {
    background-color: white !important;
    border-radius: 5px !important;
    padding: 10px 32px 10px 15px;
    min-height: 20px;
  }
  .MuiFormLabel-root {
    margin-top: -6px;
  }

  .search-container {
    .title-class {
      font-size: 0.8em;
    }
    &.subject {
      width: 190px;
    }
    &.grade {
      width: 220px;
    }
    &.level {
      width: 100px;
    }
    &.unit {
      width: 220px;
    }
    &.topic {
      width: 220px;
    }
  }
}
