.setting-section-container {
  .setting-section-container-title {
    font-weight: 500;
    min-height: calc(1.8em + 5px);
    white-space: nowrap;
    margin-right: 5px;
  }

  .limit-width-70 {
    width: 70%;
  }
  .limit-width-30 {
    width: 30%;
  }
  .limit-width-20 {
    width: 20%;
  }

  .setting-interaction-toggle {
    margin-bottom: 10px;
    .MuiButtonBase-root {
      font-weight: 300 !important;
      font-size: 14px;
      line-height: 24px;
      padding: 6px 12px;
    }
  }

  .max-choice-slider-container {
    min-width: calc(1.8em + 200px);
    align-items: center;
    .label {
      min-width: 0px !important;
    }
    .max-choice-slider {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
  }
}
