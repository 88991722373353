@import "../../../../../styles/colors.scss";

.teacher-activity-report-students {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;

  .no-data {
    padding-top: 10px;
  }
  .back-fab {
    background-color: $color-primary;
    color: $color-light;
    margin-right: 20px;
  }

  .teacher-activity-report-students-table {
    width: 600px;
    th {
      font-weight: 500;
    }
  }

  .student-subject-report-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  .student-subject-report {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(60%, 60%) 20%;
  }
}
