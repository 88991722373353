.game-card-layout {
  grid-auto-rows: auto;
  display: grid;
  height: 100%;
  min-width: 0;
  justify-content: center;
}

.game-card-layout-8 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(3, calc(94% / 3));
  grid-template-rows: repeat(3, calc(97% / 3));
  grid-template-areas: 'card0  card1   card2'
                       'card7  spin    card3'
                       'card6  card5   card4'
                       ;
  &.game-card-small {
    grid-gap: 0.15em;
  }
}

.game-card-layout-9 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(3, calc(94% / 3));
  grid-template-rows: repeat(4, calc(94% / 4));
  grid-template-areas: 'card0   card1   card2'
                       'finish  spin   card3'
                       'card8   spin    card4'
                       'card7   card6   card5'
                       ;
  &.game-card-small {
    grid-gap: 0.15em;
    grid-template-columns: repeat(3, calc(94% / 3));
    grid-template-rows: repeat(4, calc(92% / 4));
  }
}

.game-card-layout-10 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(3, calc(94% / 3));
  grid-template-rows: repeat(4, calc(94% / 4));
  grid-template-areas: 'card0  card1   card2'
                       'card9  spin    card3'
                       'card8  spin    card4'
                       'card7  card6   card5'
                       ;
  &.game-card-small {
    grid-gap: 0.3em;
    grid-column-gap: 0.3em;
    grid-template-columns: repeat(3, calc(94% / 3));
    grid-template-rows: repeat(4, calc(88% / 4));
  }
}

.game-card-layout-11 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(4, calc(97% / 4));
  grid-template-rows: repeat(4, calc(93% / 4));
  grid-template-areas: 'card0   card1   card2   card3'
                       'finish  spin    spin    card4'
                       'card10  spin    spin    card5'
                       'card9   card8   card7   card6'
                       ;
  &.game-card-small {
    grid-gap: 0.25em;
    grid-column-gap: 0.3em;
    grid-template-columns: repeat(4, calc(92% / 4));
    grid-template-rows: repeat(4, calc(89% / 4));
  }
}

.game-card-layout-12 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(4, calc(94% / 4));
  grid-template-rows: repeat(4, calc(94.5% / 4));
  grid-template-areas: 'card0   card1   card2   card3'
                       'card11  spin    spin    card4'
                       'card10  spin    spin    card5'
                       'card9   card8   card7   card6'
                       ;
  &.game-card-small {
    grid-gap: 0.25em;
    grid-column-gap: 0.3em;
    grid-template-columns: repeat(4, calc(92% / 4));
    grid-template-rows: repeat(4, calc(89% / 4));
  }
}

.game-card-layout-13 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(4, calc(94% / 4));
  grid-template-rows: repeat(5, calc(92% / 5));
  grid-template-areas: 'card0   card1   card2   card3'
                       'finish  spin    spin    card4'
                       'card12  spin    spin    card5'
                       'card11  spin    spin    card6'
                       'card10  card9   card8   card7'
                       ;
  &.game-card-small {
    grid-gap: 0.2em;
    grid-column-gap: 0.3em;
    grid-template-columns: repeat(4, calc(92% / 4));
    grid-template-rows: repeat(5, calc(89% / 5));
  }
}

.game-card-layout-14 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(4, calc(94% / 4));
  grid-template-rows: repeat(5, calc(92% / 5));
  grid-template-areas: 'card0   card1   card2   card3'
                       'card13  spin    spin    card4'
                       'card12  spin    spin    card5'
                       'card11  spin    spin    card6'
                       'card10  card9   card8   card7'
                       ;
  &.game-card-small {
    grid-column-gap: 0.22em;
    grid-row-gap: 0.15em;
    // grid-column-gap: 1.5em;
  }
}

.game-card-layout-15 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(5, calc(95% / 5));
  grid-template-rows: repeat(5, calc(92% / 5));
  grid-template-areas: 'card0   card1   card2   card3    card4'
                       'finish  spin    spin    spin     card5'
                       'card14  spin    spin    spin     card6'
                       'card13  spin    spin    spin     card7'
                       'card12  card11  card10  card9    card8'
                       ;
  &.game-card-small {
    grid-gap: 0.1em;
  }
}

.game-card-layout-16 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(5, calc(95% / 5));
  grid-template-rows: repeat(5, calc(92% / 5));
  grid-template-areas: 'card0   card1   card2   card3    card4'
                       'card15  spin    spin    spin     card5'
                       'card14  spin    spin    spin     card6'
                       'card13  spin    spin    spin     card7'
                       'card12  card11  card10  card9    card8'
                       ;
  &.game-card-small {
    grid-gap: 0.1em;
  }
}

.game-card-layout-17 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(6, calc(99% / 6));
  grid-template-rows: repeat(5, calc(92% / 5));
  grid-template-areas: 'card0   card1   card2   card3    card4   card5'
                       'finish  spin    spin    spin     spin    card6'
                       'card16  spin    spin    spin     spin    card7'
                       'card15  spin    spin    spin     spin    card8'
                       'card14  card13  card12  card11   card10  card9'
                       ;
  &.game-card-small {
    grid-gap: 0.05em;
    grid-template-columns: repeat(6, calc(97% / 6));
    grid-template-rows: repeat(5, calc(94% / 5));
  }
}

.game-card-layout-18 {
  grid-gap: 0.7em;
  grid-template-columns: repeat(6, calc(99% / 6));
  grid-template-rows: repeat(5, calc(92% / 5));
  grid-template-areas: 'card0   card1   card2   card3    card4   card5'
                       'card17  spin    spin    spin     spin    card6'
                       'card16  spin    spin    spin     spin    card7'
                       'card15  spin    spin    spin     spin    card8'
                       'card14  card13  card12  card11   card10  card9'
                       ;
  &.game-card-small {
    grid-gap: 0.05em;
    grid-template-columns: repeat(6, calc(97% / 6));
    grid-template-rows: repeat(5, calc(94% / 5));
  }
}

.game-card-layout-19 {
  grid-gap: 0.4em;
  grid-template-columns: repeat(6, calc(99% / 6));
  grid-template-rows: repeat(6, calc(94.7% / 6));
  grid-template-areas: 'card0  card1   card2   card3   card4  card5'
                       'finish spin    spin    spin    spin   card6'
                       'card18 spin    spin    spin    spin   card7'
                       'card17 spin    spin    spin    spin   card8'
                       'card16 spin    spin    spin    spin   card9'
                       'card15 card14  card13  card12  card11 card10';
  &.game-card-small {
    grid-template-columns: repeat(6, calc(96% / 6));
    grid-template-rows: repeat(6, calc(94% / 6));
    grid-gap: 0.05em;
  }
}

.game-card-layout-20 {
  grid-gap: 0.4em;
  grid-template-areas: 'card0  card1   card2   card3   card4  card5'
                       'card19 spin    spin    spin    spin   card6'
                       'card18 spin    spin    spin    spin   card7'
                       'card17 spin    spin    spin    spin   card8'
                       'card16 spin    spin    spin    spin   card9'
                       'card15 card14  card13  card12  card11 card10';
  grid-template-columns: repeat(6, calc(99% / 6));
  grid-template-rows: repeat(6, calc(94.7% / 6));
  &.game-card-small {
    grid-template-columns: repeat(6, calc(96% / 6));
    grid-template-rows: repeat(6, calc(94% / 6));
    grid-gap: 0.05em;
  }
}