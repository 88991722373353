@import '../../../../../../styles/colors.scss';

.delete-external-link {
  .delete-external-link-icon {
    fill: $color-bad;
  }
}

.delete-confirmation-content {
  max-width: 800px;
  min-width: 400px;
  word-break: break-all;

  .to-delete-link {
    font-weight: 500;
    color: $color-primary;
    margin-top: 20px;
  }
}