.cause-and-effect-card {
  display: flex;
  height: 90%;
  width: 100%;

  .card {
    margin-left: 0.2em;
    margin-right: 0.2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 99%;
    position: relative;
  }
  .card-thumbnail-image {
    background-size: contain;
    height: 100%;
    display: flex;
    margin-top: 3px;
  }

  .card-thumbnail-text-background {
    background: lightgray;
  }

  .card-thumbnail-placeholder {
    height: 100%;
    background: lightgray;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    @media screen and (max-width: 1024px) {
      font-size: 0.75em;
    }
    margin-bottom: 0.3em;
    display: flex;
    &.small-text {
      font-size: 0.5em;
      word-break: break-all;
    }
  }

  .card-thumbnail-text {
    align-items: center;
    padding: 0.2em 0.4em 0.2em 0.4em !important;
    justify-content: center;
    margin-bottom: auto;
    display: flex;
    height: 15%;
    min-height: 15px;
    &.small-text {
      min-height: auto;
      padding: 0.2em 0em 0.2em 0em !important;
      font-size: 0.6em;
    }
    @media screen and (max-width: 1024px) {
      font-size: 0.1em;
    }
  }

  .card-thumbnail-text-with-value {
    display: flex;
    width: 100%;
    padding: 0.2em 0.4em 0.2em 0.4em !important;
    font-size: 1.6em;
    @media screen and (max-width: 1024px) {
      padding-left: 3px;
      padding-right: 3px;
      box-sizing: border-box;
    }
  }

  .card-thumbnail-placeholder-text {
    text-align: center;
  }
}
