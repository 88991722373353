.contact-account-manager-action {
  height: 75px;
  display: flex;
  margin-right: 0px;

  .action-button {
    align-self: flex-end;
  }

  .license-expire-message {
    position: absolute;
  }

  .contact-account-manager-action-span {
    align-self: flex-end;
  }
}

.contact-account-dialog {
  .form-container {
    width: 400px;

    .message-field {
      margin-top: 20px;
    }
  }
}