@import "../../../../../styles/colors.scss";

.teacher-menu-bar {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
  flex-wrap: wrap;

  .no-wrap-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    margin-top: 10px;
  }
  .search-dropdown {
    min-width: 250px;
    max-width: 250px;
    margin-right: 10px;
    position: relative;
  }

  .license-type {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
  }

  .admin-data-date-selector {
    margin-right: 10px;
  }
}
