.setup-two-factor-authentication {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .vizzle-logo-container {
    margin-top: 50px;
    img {
      width: 200px;
    }
  }

  .title {
    padding-top: 30px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 2em;
  }

  .content {
    margin-top: 20px;
    p {
      margin: 0px;
      font-size: 1.5em;
      text-align: center;
    }

    .actions {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      max-width: 340px;
    }

    .qr-code {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
