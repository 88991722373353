.print-card-activity {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0.8em;
  padding: 0.6em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
  overflow: hidden;

  .card-activity-image {
    max-height: 70%;
    height: 70%;
    width: 100%;
    background-size: contain;
  }

  .card-activity-text {
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
  }
  .inner-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 0.6em;
  }
}
