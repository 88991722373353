.sequencing-activity-response-card {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0.8em;
  padding: 0.6em 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
  cursor: pointer;
  
  .response-image {
    flex-grow: 1;
    background-size: contain;
    width: 100%;
  }

  .response-text {
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    padding: 10px;
    &.text-with-image {
      max-height: 30%;
    }
  }

  .response-media {
    z-index: 1;
  }
}