@import '../../../../styles/colors.scss';

.show-scores-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 0.5em;
  box-sizing: border-box;
  height: 100%;

  .title {
    color: $color-primary;
  }

  .message {
    background-color: white;
    padding: 20px 40px;
    border-radius: 10px;
  }
  .close-button {
    text-transform: none;
    width: 220px;
    height: 42px;
    border-radius: 50px;
  }
}