@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.categorizing-activity-component {
  width: 99%;
  height: 100%;
  position: relative;

  .categorizing-prompt-draggable {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-gap: 0px;
  }

  .flexible-column-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .prompt-panels-container {
    width: 100%;
    height: 100%;
  }

  .panel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 7px 15px;
    box-sizing: border-box;
  }

  .instruction-container {
    min-height: 100px;
    max-height: 40%;
    align-items: baseline;
    margin-bottom: 10px;
    margin-top: 10px;
    &.full-height {
      height: 100%;
    }
    .card-activity {
      flex-direction: column-reverse;
      .MuiCardContent-root {
        padding: 7px 25px 7px 15px;
      }
    }

    .media-player-icon {
      top: 2px;
      right: 2px;
    }
  }

  .responses-container {
    margin-top: 5px;
    width: 100%;
    height: 100%;
    display: grid;
    flex-grow: 1;
    z-index: 999998;

    .response-card-container {
      width: 100%;
      height: 100%;

      .MuiCardContent-root {
        padding: 10px 18px 10px 5px;
      }
      .media-player-icon {
        top: 0px;
        right: 0px;
        font-size: 1.4rem;
      }
    }
    .response-card-place-holder {
      min-height: 113px;
      position: relative;
    }

    &.categorizing-response-card-1 {
      flex-grow: 0;
      display: flex;
      align-items: center;
      justify-items: center;
      width: 50%;
      height: 60%;
      &.flatten-cards {
        width: 90%;
      }
    }

    &.categorizing-response-card-2 {
      flex-grow: 0;
      grid-template-columns: 37% 37%;
      grid-gap: 7%;
      height: 60%;
      &.large-card {
        grid-template-columns: 40% 40%;
        grid-gap: 7%;
        height: 60%;

        &.flatten-cards {
          height: 100%;
        }
      }

      &.flatten-cards {
        flex-grow: 1;
        grid-template-columns: 100% !important;
        grid-row-gap: 5% !important;
        grid-template-rows: repeat(2, 45%);
        .response-card-place-holder {
          min-height: unset;
        }

        .card-activity {
          padding-top: 0px;
        }
        .card-activity-text {
          &.only-text {
            padding: 10px 5px;
          }
        }
      }
    }

    &.categorizing-response-card-3,
    &.categorizing-response-card-4 {
      grid-template-columns: 40% 40%;
      grid-template-rows: repeat(2, 45%);
      grid-gap: 7%;
      grid-column-gap: 10%;
      height: 60%;
      &.large-card {
        grid-template-columns: 40% 40%;
        grid-gap: 7%;
        height: 100%;
        grid-row-gap: 10% !important;
      }

      &.flatten-cards {
        grid-template-columns: 100% !important;
        grid-row-gap: 5% !important;
        .response-card-place-holder {
          min-height: unset;
        }

        .card-activity {
          padding-top: 0px;
        }
        .card-activity-text {
          &.only-text {
            padding: 0;
          }
        }
      }
    }

    &.categorizing-response-card-3 {
      &.flatten-cards {
        grid-template-rows: repeat(3, 27%);
        &.large-card {
          &.flatten-cards {
            grid-template-rows: repeat(3, 30%);
          }
        }
      }
    }

    &.categorizing-response-card-4 {
      &.flatten-cards {
        grid-gap: 5% !important;
        grid-template-rows: repeat(4, 21%);
      }
    }

    &.categorizing-response-card-5,
    &.categorizing-response-card-6 {
      grid-template-columns: repeat(3, 30%);
      grid-template-rows: repeat(2, 45%);
      grid-gap: 5%;
      grid-column-gap: 5%;
      height: 20%;
      &.large-card {
        grid-template-columns: repeat(3, 30%);
        @media screen and (min-width: 1600px) {
          grid-template-columns: 28% 28% 28% 28%;
        }
        grid-gap: 4%;
        height: 100%;
        grid-row-gap: 10% !important;
      }

      &.flatten-cards {
        grid-template-columns: 50% 50%;
        height: 50%;
        grid-template-rows: 30%;
        grid-row-gap: 5% !important;
        .response-card-place-holder {
          min-height: unset;
        }

        .card-activity {
          padding-top: 0px;
        }
        .card-activity-text {
          &.only-text {
            padding: 0;
          }
        }
      }
    }

    &.categorizing-response-card-7,
    &.categorizing-response-card-8 {
      grid-template-columns: 22% 22% 22% 22%;
      @media screen and (min-width: 1600px) {
        grid-template-columns: 21% 21% 21% 21%;
      }
      grid-gap: 3%;
      grid-column-gap: 5%;
      height: 50%;

      &.large-card {
        grid-gap: 4%;
        height: 80%;
        grid-row-gap: 10% !important;
      }

      &.flatten-cards {
        grid-template-columns: 50% 50%;
        .response-card-place-holder {
          min-height: unset;
        }

        .card-activity {
          padding-top: 0px;
        }
        .card-activity-text {
          &.only-text {
            padding: 0;
          }
        }
      }
    }
  }
  .prompt-container {
    &.two-columns {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .prompt {
      width: 100%;
      height: 100%;
      flex-grow: 1;
      max-height: 45%;
      margin-top: 5px;
      margin-bottom: 5px;

      &.prompt-1 {
        max-height: 45%;
      }
      &.prompt-2 {
        max-height: 45%;
      }
      &.prompt-3 {
        max-height: 31%;
      }
      &.prompt-4 {
        max-height: 23%;
      }
      &.prompt-5 {
        max-height: 17.5%;
        .categorizing-activity-prompt-card {
          padding: 10px 5px 10px 15px;
        }
      }
      &.prompt-6 {
        max-height: 15.5%;
        margin: 3px 0;
        .categorizing-activity-prompt-card {
          padding: 10px 5px 10px 15px;
        }
      }

      &.prompt-7, &.prompt-8 {
        max-height: 23%;
        max-width: 47%;
        margin-right: 10px;
      }
    }
  }

  .categorizing-response-draggable-container {
    position: absolute !important;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    &.categorizing-response-draggable {
      &:hover {
        @extend .vizzle-selected-component;
        border-radius: 7px;
      }
    }
  }

  .response-card-dragable-containr {
    width: 100%;
    height: 100%;
  }
  .categorizing-component-response-card-non-drag-items {
    transform: translate(0, 0) !important;
    transition: transform 0.7s !important;
    z-index: 1;
  }

  .categorizing-selected-component {
    @extend .vizzle-selected-component-medium;
  }
  .selected-response-cards {
    height: 100%;
    width: 100%;
    background-color: $color-gray-monarch-website--light-fading;
    border-radius: 7px;
    z-index: 100;
    position: relative;
  }

  &.prompt-selected-response-cards-hover {
    .prompt-selected-response-cards {
      &:hover {
        @extend .vizzle-selected-component-medium;
        cursor: pointer;
      }
    }
  }

  .switch-select {
    border-radius: 5px;
    box-shadow: 0px 1px 3px 4px $color-tertiary, 0px 0px 7px 7px $color-tertiary, 0px 3px 2px -1px $color-tertiary !important;
    &:hover {
      box-shadow: 0px 1px 3px 4px $color-tertiary, 0px 0px 7px 7px $color-tertiary, 0px 3px 2px -1px $color-tertiary !important;
    }
  }
}
