@import "../../../../../../styles/colors.scss";

.import-user-result {
  width: 800px;
  padding: 0px 10px;

  .summary-table {
    width: 500px;
  }

  .detail-table-container {
    margin-top: 20px;
    .detail-table {
      width: 100%;
    }
  }

  .additional-message {
    margin-top: 10px;
    font-weight: 500;
    font-size: 1rem;
    color: $color-secondary;
  }
}
