@import '../../../../../styles/colors.scss';
@import "../../../../../styles/components.scss";

.sorting-activity-prompt-card {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;

  background-color: white;
  
  &.reverse-layout {
    flex-direction: row-reverse;
  }

  .media-action {
    z-index: 1;
    .media-player-action-area {
      border-radius: 10px;
    }
  }

  .prompt-details {
    width: 22%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  
    .prompt-image-container {
      min-height: 40%;
      max-height: 40%;
      height: 100%;
      .prompt-image {
        height: 100%;
        background-size: contain;
      }
    }

    .prompt-text {
      margin-top: auto;
      margin-bottom: auto;
      padding: 10px;
      box-sizing: border-box;
    }

    .media-player-icon {
      font-size: 2em;
      color: $color-primary;
      margin-left: auto;
      top: 10px;
      right: 10px;
      border: 3px;
      background-color: white;
      border-radius: 15px;
    }
  }

  .prompt-selected-response-cards {
    height: 100%;
    width: 75%;
    border-radius: 7px;
    z-index: 1;
    // &:hover {
    //   @extend .vizzle-selected-component;
    //   cursor: pointer;
    // }
  }
  
}