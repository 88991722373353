.admin-school-form {
    display: flex;
    flex-direction: column;
    width: 400px;

    .form-teacher-schoology-name {
      margin-top: 10px;
    }
    
    .MuiFormControl-root {
      margin-top: 15px;
    }
  }
