.topic-info {
  padding: 5px 0 20px 0;
  
  &.loading-container {
    margin-top: 10%;
  }

  .key-vocabulary-editor-header {
    background-color: #abc0f0;
  }

  .essential-question-editor-header {
    background-color: #bdd6ac;
  }

  .activities-editor-header {
    background-color: #f0bb2c;
  }

  .actions-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .cancel-button {
      margin-right: 30px;
      min-width: 100px !important;
    }

    .topic-action {
      min-width: 355px;
    }
  }
}