.teacher-course-set-lesson-order-dialog {
  .MuiDialog-paper {
    max-height: calc(100% - 15px);
    min-height: calc(100% - 15px);
    min-width: 490px;
  }
}

.reorder-lesson-action {
  height: 40px !important;
}