@import "../../../../../styles/colors.scss";
@import "../../../../../styles/components.scss";

.teacher-course-lessons {
	box-sizing: border-box;

	.lesson-row-action {
		margin-left: auto !important;
		margin-right: 10px;
		margin-top: 10px;
		display: flex;
		align-items: center;
	}

	.small-label {
		font-size: 0.7em;
		line-height: 19px;
		top: 25px;
		left: -40px;
		width: 170px;
	}
}
.lessons {
	box-sizing: border-box;
}
.lesson-img {
	width: 200px;
	min-width: 50px;
}

.lesson-detail-container {
	.label-lesson-detail-container {
		line-height: 25px;
		top: 40px;
		left: -52px;
		width: 233px;
	}
}
