@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/components.scss';

.lesson-card-container {
  margin-left: 5px;
  margin-right: 5px;
  border: 8px solid $color-light;
  position: relative;
  overflow: hidden;
  background-color: $color-dark !important;

  &.highlighted {
    border-color: $branding-orange;
    transform: scale(0.97);
  }

  .lesson-card-container-action-area {
    .MuiCardMedia-root {
      background-color: white !important;
    }
  }

  .lesson-card-video {
    width: 100%;
  }
  .lesson-card-content {
    padding: 6px 12px 20px 12px;
    background-color: $color-dark;
    color: $color-light;
    position: relative;
  }

  .lesson-card-title {
    @include hideText(2);
    font-size: 1.5em;
    white-space: pre-wrap;
    margin-block-start: 0.4em;
    word-break: break-all;
  }

  .lesson-card-description {
    @include hideText(3);
    margin-top: 2px;
    font-size: 0.9em;
    color: $color-gray-monarch-website--light;
  }

  .lesson-card-specialty-icon {
    position: absolute;
    width: 20px;
    right: 5px;
  }

  .lesson-labels {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: left;
    align-items: flex-start;
  }
  &:hover {
    .lesson-action-overlay {
      opacity: 1;
    }
  }
  .lesson-action-overlay {
    background-color: rgba(39, 39, 39, 0.5);
    transition: 1.2s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: right;
    opacity: 0;
  }
}
