.multiple-choice-editor {
  .multiple-choice-component-question {
    .matching-editor-question-key {
      width: 100%;
    }
  }

  .matching-editor-answer-key {
    position: relative;
    max-width: 80px;
    margin-right: 20px;
  }
  &.single-page {
    .multiple-choice-component-responses {
      flex-grow: 1;
      .multiple-choice-component-response {
        flex-grow: 1;
        min-height: 2.5em;
      }
    }
  }

  &.side-by-side {
    .multiple-choice-component-responses {
      flex-grow: 1;
      .multiple-choice-component-response {
        flex-grow: 1;
        height: 3.2em;
      }
    }
  }

  .multiple-choice-component-response {
    .card-editor-text-action {
      margin-bottom: 0px;
    }
  }
}
