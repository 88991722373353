.theme-player {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .theme-container {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-items: center;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    left: 0px;
  }

  canvas {
    margin: auto;
  }

  .loading {
    margin: auto;
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading-message {
      margin-top: 10px;
    }
  }
}