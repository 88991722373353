@import "../../../../../styles/colors.scss";

.objective-detail-action {
  background-color: $color-secondary !important;
  text-transform: unset !important;
  font-size: 0.95rem !important;
  color: white !important;
  &:hover {
    opacity: 0.8 !important;
  }

  min-width: 96px !important;
  // min-height: 64px !important;
  &.margin-right {
    margin-right: 15px;
  }
}