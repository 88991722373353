@import "../../../../../../../styles/colors.scss";

.view-answer-action {
  width: 140px;
  margin-right: 20px !important;
  &:hover {
    background-color: $color-secondary !important;
  }
}

.view-answer-action-dialog {
  .MuiDialog-paperScrollPaper {
    max-height: calc(100vh - 15px);
    max-width: unset;
  }
}
