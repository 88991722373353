.authored-lessons-filter-lesson-course {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  // justify-content: flex-start;
  gap: 20px;

  .MuiFormLabel-root {
    margin-top: -3px;
  }
  .MuiSelect-select {
    background-color: white !important;
    border-radius: 5px !important;
    padding: 12px 32px 12px 15px;
  }

  .dropdown-selector {
    width: 300px;
  }
  .search-button {
    min-width: 120px;
  }
}