@import "../../styles/colors.scss";

.my-student {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content-panel {
    padding: 5px 20px;
    box-sizing: border-box;
    overflow-y: auto;
    height: inherit;
    position: relative;
    width: 100%;
  }
  .inner-content-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .back-to-previous-container {
    position: absolute;
    z-index: 10;
    top: 75px;
    left: 15px;
  }
}
