.admin-tab-students {
  .filter-margin {
    margin-left: 5px;
  }

  .students-table {
    .keyword-input {
      width: 250px;
    }
  }
}
