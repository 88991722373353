@import "../../../../styles/colors.scss";

.lesson-activity-bottom-bar {
  box-shadow: none !important;
  .lesson-activity-toolbar {
    min-height: 50px;
    background-color: $color-blue-primary;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;

    &.success-message-toolbar {
      background-color: $color-green;
    }
  }

  .navigation-button-empty {
    width: 0px;
    height: 40px;
    &.show {
      width: 90px;
    }
  }
  .navigation-button {
    background-color: white !important;
    width: 90px;
    height: 40px;
    border-radius: 30px;
    font-size: 0.65em;
    &.navigation-button-mobile {
      &:hover {
        opacity: 1 !important;
      }
    }
  }

  .next-toolbar-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .icon {
    font-size: 2.5em;
    color: $color-primary;
  }
  .right {
    margin-left: auto;
  }
}
