@import "../../../../../styles/colors.scss";
@import "../../../../../styles/components.scss";

.free-text-response-popup-dialog {
  .MuiDialog-paperScrollPaper {
    max-width: unset;
    max-height: 95vh;
  }
  &.dialog-full-height {
    .MuiDialog-paperScrollPaper {
      height: 100%;
    }
  }
  .action-button {
    min-width: 120px;
  }
  .submit-button {
    margin-right: 70px !important;
  }

  .vizzle-custom-dialog-actions {
    margin: auto;
  }
  .free-text-response-popup-dialog-title {
    width: 100%;
  }
}
.free-text-response-activity-card {
  display: flex;
  flex-direction: column;
  position: absolute !important;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;

  .content {
    height: 100%;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &:hover {
    @extend .vizzle-selected-component;
  }

  .free-text-response-actions-container {
    // position: absolute;
    z-index: 10;
    margin-top: 10px;
    // align-self: flex-end;
    margin-bottom: 10px;
    .submit-button {
      min-width: 100px;
      margin-right: 100px;
    }
  }

  .card-activity-text {
    &.only-text {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      overflow: visible;
    }
  }
}
