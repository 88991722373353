.multiple-choice-v2-thumbnail {
  .prompt-area {
    overflow: visible;
  }

  &.small {
    padding: 2px 2px 7px 2px;
    box-sizing: border-box;
    &.side-by-side {
      grid-template-columns: repeat(2, 48%);
    }
    &.single-page {
      .response-more-card-text {
        grid-area: unset !important;
      }
    }

    grid-column-gap: 0.5em;
    .prompt-area {
      overflow: hidden;
      .prompt-cards-container {
        min-height: 40%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .small-thumbnail {
          width: 100%;
        }
      }
    }

    .response-area {
      .response-cards-container {
        margin-top: 0.5em;
        .multiple-choice-v2-response-card {
          font-size: 0.5em;
        }

        .response-more-card-text {
          grid-area: 3/1 / span 1 / span 2;
          margin: auto;
        }
      }
    }
  }
}
