@import "../../../../styles/colors.scss";
.teacher-course-instruction {
	p {
		color: $color-secondary;
		font-weight: 500;
		font-size: 1.3em;
	}

	.information-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 5%;
		@media screen and (max-width: 1200px) {
			padding: 0 1%;
		}
		.curriculum-intruction-video {
			width: 40vw;
			min-width: 40vw;
			// @media screen and (max-width: 1100px) {
			// 	width: 450px;
			// 	min-width: 450px;
			// }
		}
		.classroom-logo {
			border-bottom: 30px solid #184c5d;
		}
		.vizzle-logo {
			padding: 20px 0;
			width: 220px !important;
			margin-left: auto;
			margin-right: auto;
		}

		.text-content {
			padding: 10px 16px;
			background-color: #184c5d;
			color: white;
			width: 75%;
			margin-bottom: 30px;
			.subtitle1 {
				font-weight: 800;
				font-size: 0.9rem;
			}
			.subtitle2 {
				font-weight: 700;
				font-size: 0.8rem;
			}
		}
	}
}
