.flatten-standard-list {
  font-size: 1.1em;
  li {
    margin-bottom: 10px;
    .standard-code {
      font-weight: 500;
    }
  }

  .standard-description:first-letter {
    text-transform: capitalize;
  }
}