@import '../../../../styles/colors.scss';

.dropdown-action {
  margin: 0px 0px 0px 7px;
  padding: 6px 7px 6px 7px;
  background-color: #e0e0e0;
  color: $color-gray-monarch-website;
  min-height: 25px;
}

.lesson-detail-dropdown-menu {
  .MuiMenu-list {
    min-width: 120px;
  }
  
  li {
    font-weight: 500;
    padding-left: 20px;
    padding-right: 30px;
  }
}