.student-reset-password-dialog {
  .title {
    padding-top: 30px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.3em;
  }

  .content {
    box-sizing: border-box;
    margin: 5px 10px 50px 10px;
    .message {
      font-size: 1em;
    }
  }

  .back-button {
    width: 100%;
    text-transform: unset;
  }
}