.celebration-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-self: center;

  canvas {
    border-radius: 5px;
  }
  .celebration {
    height: 480px;
    margin: auto;
  }

  .loading {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading-message {
      margin-top: 10px;
    }
  }
}