.book-card-activity {
  height: 90%;
  width: 100%;
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: center;

  .book-card-activity-container {
    display: flex;
    height: 100%;
    &.book-card-activity-small {
      width: 50%;
    }
    
    &.book-card-activity-big {
      width: 100%;
      padding: 15px;
      justify-content: space-between;
      box-sizing: border-box;
    }
  }
  .slider-container {
    width: 100%;
  }

  .bookcard-free-text {
    position: relative !important;
  }

  .card-activity-text {
    &.only-text {
      padding-top: 6px;
    }
  }
  .two-page {
    width: 49%;
  }

  .replay-button-container {
    width: 100vw;
    position: absolute;
  }
}

.book-activity-instruction-dialog {
  .text-lines-text-content {
    margin-bottom: 10px;
  }
}
