.game-card-cell {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  border: 3px solid;
  z-index: 1;
  &:hover {
    cursor: pointer;
    background-color: #d9d9d9;
  }
  .game-card-action-area {
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .card-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    .card-image {
      height: 78%;
      width: 100%;
      background-size: contain;
      display: flex;
      margin-top: auto;
      margin-bottom: auto;
      &.image-with-text {
        height: 80%;
      }
    }
    &.no-height {
      height: 0%;
    }
  }

  .card-text-container {
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    box-sizing: border-box;
  }

  .special-card-text {
    font-size: 0.8em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: 500;
  }

  .extra-icon {
    position: absolute;
    height: 85%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}