@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';

.button-selector-component-horizental {
  white-space: nowrap;
  .button-selector {
    margin-right: 15px;
    @media screen and (max-width: 1024px) {
      margin-right: 10px;
    }
    text-transform: none;
  }
}

.button-selector-component-vertical {
  display: flex;
  flex-direction: column;
  .button-selector {
    margin-bottom: 15px;
    text-transform: none;
  }
}