@import "../../../../styles/colors.scss";

.unit-guite-section {
  border: 2px solid $color-medium;
  page-break-inside: avoid;
  .unit-guide-section-title {
    padding: 10px;
    border-bottom: 2px solid $color-medium;
    font-weight: 700;
    background-color: #f1c233;
  }

  .unit-guide-section-body {
    padding: 10px;

    p {
      margin: 0;
    }
  }

}
