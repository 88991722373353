.drawing-editor {
  width: 100%;
  height: 100%;
  position: relative;

  // canvas {
  //   min-height: 400px;
  // }

  .ptro-wrapper {
    bottom: 60px;
    margin: auto;
  }
  .ptro-bar {
    height: 60px;
  }

  .ptro-icon-btn {
    width: 53px;
    height: 53px;
  }
  .ptro-icon {
    font-size: 18px;
  }

  .ptro-info {
    display: none;
  }
  .ptro-bar-right {
    margin-left: auto;
  }

  .ptro-crp-el {
    border: 1px solid #333333;
    padding: 10px;
    box-sizing: border-box;
  }
  .ptro-center-table {
    background-color: white;
  }
  .ptro-text-tool-buttons {
    position: fixed;
  }
}
