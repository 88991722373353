.search-lessons-filter {
  // width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;

  .Mui-expanded {
    margin: 0px !important;
    min-height: 25px !important;
  }

  .MuiAccordionDetails-root {
    padding-bottom: 10px;
    padding-top: 0px;
  }

  .MuiAccordionSummary-expandIcon {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .accordion-summary {
    display: flex;
    flex-direction: row;
    flex: 1;
    .create-button {
      margin-left: auto;
    }
  }

  .accordion {
    width: 100%;
    z-index: 1;
    // min-height: 60px;
  }

  .accordion-detail {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
  }
}
