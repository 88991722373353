@import '../../../../../../styles/colors.scss';

.view-mtss-plan-action {
  background: $color-primary !important;
  color: white !important;
  &.with-label {
    min-width: 115px !important;
  }
  text-transform: none !important;
  font-size: 0.7em !important;

  &.large-font {
    font-size: 0.9em !important;
  }
}