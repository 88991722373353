@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.flexible-matching-activity-component {
  width: 97%;
  height: 95%;
  grid-column-gap: 3%;
  justify-items: center;
  justify-content: center;
  position: relative;

  &.no-prompt-area {
    grid-template-columns: repeat(1, calc(50% - 1em));
  }

  &.no-prompt-cards {
    .prompt-cards-container {
      display: none;
    }
    .flexi-matching-prompt-text,
    .flexi-matching-sub-text {
      flex-grow: 1;
    }
  }

  .flexi-matching-prompt-text,
  .flexi-matching-sub-text {
    min-height: 65px;
    .MuiCardContent-root {
      padding: 10px 25px 10px 15px;
    }
    .media-player-icon {
      top: 5px;
      right: 5px;
    }
  }

  .prompt-area {
    .flexi-matching-prompt-card {
      .MuiCardContent-root {
        padding: 10px 25px 10px 15px;
      }
    }
    .media-component {
      height: 100%;
      .media-player-icon {
        top: 5px;
        right: 5px;
      }
    }
  }
  .prompt-cards-container {
    flex-grow: 1;
  }

  .submit-response-area {
    background-color: white;
    position: relative;
  }

  .selected-response-container {
    border: none !important;
    background-color: transparent;
    .selected-response-card {
      width: 50%;
    }
  }

  .response-card-container {
    position: relative;
  }
  .flexi-matching-response-card {
    height: 100%;
    position: relative;
  }
  .flexi-matching-component-response-card-container {
    position: absolute !important;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    z-index: 100;
    .card-activity-text {
      padding: 10px 20px 10px 10px;
    }
    .media-player-icon {
      top: 7px;
      right: 6px;
    }
    &:hover {
      @extend .vizzle-selected-component;
    }
  }

  .flexi-matching-component-disabled {
    pointer-events: none;
  }
  .flexi-matching-component-fading {
    opacity: 0.6;
  }

  .flexi-matching-selected-card {
    @extend .vizzle-selected-component-medium;
    .media-component {
      pointer-events: none;
    }
  }
  &.flexi-matching-prompt-selectable {
    .flexible-matching-activity-prompt-card {
      .media-component {
        pointer-events: none;
      }
    }
    .submit-response-area {
      cursor: pointer;
      &:hover {
        @extend .vizzle-selected-component;
      }
    }
  }
}
