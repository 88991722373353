@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.sequencing-card-editor {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  .insruction-area {
    height: 15%;
  }
  .sequencing-card-editor-instruction {
    height: 50%;
    min-height: 80px;
    width: 100%;
    margin-left: 0px;
  }
  .cards {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 85%;
    width: 100%;
    margin-top: 5px;
    grid-gap: 1em;

    .cards-column {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(4, calc(93% / 4));
      grid-gap: 0.5em;
    }
    .sequencing-card-editor-sequencing-cards-column {
      height: 100%;
      display: flex;
      flex-direction: column;
      .sequencing-cards-container {
        flex-grow: 1;
        @extend .cards-column;
        .sequencing-card-editor-text {
          margin-bottom: 0.15em;
        }
      }
    }

    .sequencing-card-editor-response-cards-column {
      @extend .cards-column;
      grid-template-rows: repeat(4, calc(94% / 4));
      .sequencing-card-editor-video {
        height: 57% !important;
      }

      .sequencing-card-editor-text {
        height: 20% !important;
      }

      .sequencing-card-editor-audio {
        height: 20% !important;
      }
    }

    .sequencing-card {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
    }

    .sequencing-card-key {
      position: absolute;
      top: 3px;
      left: 3px;
      z-index: 1;
      padding: 2px 7px 3px 7px;
      background-color: white;
      border-radius: 3px;
      font-weight: 500;
      color: $color-secondary;
    }

    .response-card-key {
      position: absolute;
      z-index: 2;
      width: 42%;
      @media screen and (min-width: 1025px) {
        width: 40%;
      }
      .MuiOutlinedInput-input {
        padding: 8px;
        background: white;
        border-radius: 5px;
        font-size: 0.8em;
      }
      .MuiSelect-iconOutlined {
        right: 0px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23) !important;
        fieldset {
          span {
            content: "";
          }
        }
      }
    }

    .response-key-overlay {
      position: absolute;
      background-color: black;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}
