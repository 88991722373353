.apply-text-style-action {
  margin-left: auto !important;
  text-transform: none !important;
}

.apply-text-style-action-dialog {
  .content {
    width: 700px;
  }
}
