.flexible-matching-activity-prompt {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .media-player-icon {
    display: none;
  }

  .flexible-matching-activity-prompt-card {
    .only-text {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}