.sorting-activity-response-card {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0.8em;
  padding: 0.6em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
  cursor: pointer !important;
  overflow: hidden;

  .response-image {
    max-height: 70%;
    height: 70%;
    width: 100%;
    background-size: contain;
  }

  .response-text {
    margin-top: auto;
    margin-bottom: auto;
    // overflow: hidden;
  }

  .response-media {
    z-index: 1;
  }
}