.extension-activities-upload-file-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 7em;
  box-sizing: border-box;
  .icon {
    font-size: 4em;
  }
}