@import "../../../../../styles/colors.scss";
.existing-student-item {
  width: 100%;
  margin: 0px !important;
  word-break: break-word;
  .add-student-item-icon-box {
    padding: 1%;
    .add-student-item-icon {
      color: $color-tertiary !important;
    }
  }
  .search-existing-student-box {
    margin: 2px;
    font-weight: 700;
    padding-left: 2%;
    .search-existing-student-subtitle {
      font-size: 14px !important;
    }
    .search-existing-student-title {
      font-size: 14px !important;
      text-transform: uppercase;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
  }
}
