.create-external-link {
  .create-button {
    text-transform: none;
  }
}

.create-external-link-dialog {
  .dialog-content {
    width: 400px;
    min-height: 70px;
    padding: 20px 0px;
  }
}