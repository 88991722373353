@import '../../../../../styles/colors.scss';

.cause-and-effect-thumbnail-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  
  .card-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .arrow {
    fill: $color-secondary;
  }
}