@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.student-selector-menu {
  .student-selector-menu-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .my-student-title {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 0.8rem !important;

    @media screen and (min-width: 1030px) {
      font-size: 0.9rem !important;
    }
  
    @media screen and (min-width: 1600px) {
      font-size: 1rem !important;
    }
  
    @media screen and (min-width: 1800px) {
      font-size: 1.15rem !important;
    }
  }
  .title {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .title-padding {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    flex-wrap: nowrap;
    padding-left: 0px;
  }

  .selected-student {
    max-width: 125px;
    font-size: 0.9em;
    text-align: left;
    @include hideText(1);
  }

  .review-notification {
    margin-right: 10px;
    background-color: $color-secondary;
    color: white;
    border-radius: 50%;
    font-size: 1.2rem;
  }

  .expand-more {
    position: absolute;
    right: -10px;
    bottom: 0;
  }
}

.student-menu-item {
  min-width: 250px;
  .notification-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 20px;
  }
  .notification {
    font-size: 2em;
    font-size: 1.7em !important;
  }
  .review-notification {
    width: 20px;
    padding: 7px;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
  }

  .chat-notification {
    fill: $color-secondary;
    padding: 4px;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
  }
  .mastery-notification {
    color: $color-tertiary;
  }
}


.add-remove-students {
  display: flex;
  .add-remove-students-icon {
    margin-right: 10px;
    color: $color-primary;
  }
}