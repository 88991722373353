@import "../../../../../styles/colors.scss";

.add-student-group-dialog, .update-student-group-dialog {
  .action-button {
    min-width: 140px;
  }
}

.delete-student-group-action {
  background-color: $color-bad;
  fill: white !important;
}
.update-student-group-action {
  background-color: $color-primary;
  fill: white !important;
}

.student-group-confirm-action {
  min-width: 110px !important;
}
