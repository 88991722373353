@import '../../../../../styles/colors.scss';
@import '../../../../../styles/components.scss';

.matching-response-activity-card {
  display: flex;
  flex-direction: column;
  position: absolute !important;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    @extend .vizzle-selected-component;
    @media screen and (min-width: 1024px) {
      box-shadow: none !important;
    }
  }

  &.clickable {
    &:hover {
      cursor: pointer;
    }
  }

  .matching-response-activity-card-image {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background-size: contain;
    height: 100%;
    display: flex;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
  }

  .matching-response-activity-card-text {
    align-items: center;
    padding: 0.4em 0.4em 0.4em 0.4em !important;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .media-component {
    z-index: 1000;
  }
}