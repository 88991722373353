@import '../../styles/colors.scss';

.vizzle-snack-bar-component {
  .variant-success {
    background-color: $color-tertiary;
  }

  .variant-error {
    background-color: $color-bad;
  }

  .variant-info {
    background-color: $color-primary;
  }

  .variant-warning {
    background-color: $color-secondary;
  }

  .vizzle-snack-bar-content {
    min-width: 120px;
    display: flex;
    flex-wrap: inherit;
  }

  .vizzle-snack-bar-message {
    display: flex;
    // align-items: center;
    max-height: 200px;
  }

  .vizzle-snack-bar-message-text {
    margin-left: 3.5em;
    margin-right: 1.5em;
    overflow: auto;
  }

  .vizzle-snackbar-close-button {
    margin-left: 2em;
  }
}