.cause-and-effect-editor {
  width: 100%;
  height: 100%;
  margin-top: 2px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 1em;
  grid-row-gap: 2em;

  &.cause-length-1 {
    grid-template-columns: auto;
  }

  &.cause-length-2 {
    grid-template-columns: auto;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}