.selectable-card-div {
  position: relative;
  .settings-selectable-card-content {
    padding: 0px !important;
    border-radius: 2px;
    background-color: #232323;
    opacity: 20;
    align-content: center;
  }
  .settings-selectable-card:hover {
    .settings-selectable-card-overlay-icon {
      opacity: 1;
      z-index: 10;
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;
      opacity: 1;
      transition: 0.2s ease-in-out;
      justify-content: center;
      align-items: center;
      background-color: rgba(39, 39, 39, 0.3);
      border-radius: 4px;
    }
  }
  .settings-selectable-card-overlay-icon {
    position: absolute;
    margin: 2%;
    top: 0;
    transition: 0.2s ease-in-out;
  }
  .settings-card-checked {
    opacity: 100;
    color: #ef7c22;
  }
  .settings-card-unchecked:hover {
    opacity: 100;
  }
  .settings-card-unchecked {
    opacity: 0;
    color: #E0E0E0;
  }
  .settings-card-name {
    font-weight: 500 !important;
    color: white;
  }
  .settings-selectable-card-overlay-media {
    position: absolute;
    margin: 2%;
    top: 0;
    right: 0;
    font-size: 2.5em;
    z-index: 1;
    &:hover {
      transform: scale(1.2);
      color: #ef7c22;
    }
  }
}
