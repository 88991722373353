@import '../../../styles/colors.scss';

.user-verify-invalid-token {
  margin-top: 20px;
  .message {
    padding: 20px 20px;
    background: $color-bad;
    text-align: center;
    border-radius: 4px;
    color: white;
  }

  .actions {
    margin-top: 30px;
  }
}