.extension-activity-view-file {
  margin-bottom: 10px;

  .title-container {
    height: 60px;
    display: flex;

    .MuiAccordionSummary-content {
      align-items: center;
    }
  }

  .actions {
    margin-left: auto;
    margin-right: 10px;
    display: flex;
    gap: 10px;
  }
  .detail-body {
    height: 1000px;
  }
  .file-container {
    width: 100%;
    height: 100%;
  }

  .loading-container {
    margin-top: 100px;
  }
}
