@import '../../../../styles/colors.scss';

.matching-thumbnail-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
  .matching-thumbnail-prompts-area {
    height: 60%;
    width: 100%;
  }

  .matching-thumbnail-prompts-card {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .matching-thumbnail-responses-area {
    margin-top: 0.1em;
    height: 38%;
    width: 100%;
    display: flex;
  }

  .matching-thumbnail-responses-card {
    height: 99%;
    width: 99%;
    margin: auto;
    border: 1px solid $color-gray-monarch-website--light;
    padding: 1px 1px;
    padding-bottom: 3px;
    box-sizing: border-box;
    font-size: 0.7em;
  }

  .matching-thumbnail-image-container {
    height: 70%;
    position: relative;
    .matching-thumbnail-image {
      width: 100%;
      height: 100%;
      background-size: contain;
    }
  }

  .matching-thumbnail-text-container {
    height: 23%;
    padding: 0.1em 0.4em 0.3em 0.3em;
    overflow: hidden;
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    border-radius: 2px;
    position: relative;
    @media screen and (max-width: 1024px) {
      padding: 0em 0.15em;
      height: 37%;
    }
  }

  .matching-thumbnail-with-value {
    @media screen and (max-width: 1024px) {
      margin-top: -3px;
      font-size: 0.01em;
    }
  }

  .matching-thumbnail-placeholder {
    height: 97%;
    width: 100%;
    top:0;
    left: 0;
    position: absolute;
    background: lightgray;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    border-radius: 2px;
    @media screen and (max-width: 1024px) {
      font-size: 0.48em;
    }
    margin-bottom: 0.5em;
    display: flex;
  }
}