.user-verify-form {
  .content {
    .text-form {
      width: 100%;
    }

    .password-field {
      margin-top: 0px;
    }
  
    .confirm-password-field {
      margin-top: 30px;
    }
  }

  .actions {
    margin-top: 50px;
    width: 100%;
  }
}