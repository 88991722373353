@import '../../../../../styles/colors.scss';

.question-key-editor {
  .question-key-text-field {
    border-radius: 5px;
    background-color: $color-gray-monarch-website--light-fading;
    fieldset {
      border-width: 2px;
    }
    input {
      padding: 8px 5px 8px 10px;
      text-overflow: ellipsis;
    }
    input::placeholder {
      font-size: 0.87em;
      font-weight: 500;
    }
  }

  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(10px, 13px) scale(1);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
}