.admin-update-student-form {
  .form-container {
    width: 100%;
    label + .MuiInput-formControl {
      margin-top: 13px;
      flex-grow: 1;
    }

    .MuiTextField-root {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .MuiTextField-root,
    .MuiInput-root {
      flex-grow: 1;
    }

    .Mui-error {
      white-space: nowrap;
    }
  }

  .row {
    display: flex;
    align-items: center;
    position: relative;
  }

  .form-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &.flex-end {
      margin-top: 15px;
      align-items: flex-end;
      .form-label {
        line-height: unset;
      }
    }
    .form-label {
      width: 220px;
      &.for-checkbox {
        width: 128px;
      }
    }

    &.dropdown-field {
      margin-top: 10px;
      .form-label {
        width: 153px;
      }
    }
    .selector-dropdown-container {
      flex-grow: 1;
      width: auto;
    }
    .selector-dropdown {
      .MuiSelect-select {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    &.date-field {
      align-items: center;
      .form-label {
        width: 153px;
      }
      .rmdp-container {
        width: 53%;
      }
      .rmdp-input {
        width: 100%;
        height: 35px;
        font-size: 1em;
      }
    }

    .search-district-action, .district-school-selector-dropdown {
      margin-right: 0px;
    }

    .tooltip {
      position: absolute;
      left: 130px;
    }
  }
}
