@import "../../styles/colors.scss";

.setting-toggle {
  margin-bottom: 10px;
  .MuiButtonBase-root {
    font-weight: 300 !important;
    font-size: 14px;
    line-height: 24px;
    padding: 6px 12px;
  }

  .MuiToggleButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.4);
  }
}

.MuiToggleButton-root.Mui-selected {
  background-color: $color-orange-secondary !important;
}