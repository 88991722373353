.app-bar-logo {
  position: relative;
  button {
    padding-left: 0px;
  }
  .expand-icon {
    margin-left: 10px;
    color: black;
  }

  .MuiButton-root, .MuiMenuItem-gutters {
    padding: 0px !important;
  }
  .MuiButton-root {
    margin-right: 20px;
  }
}

.vizzle-logo {
  width: 135px;
  padding-right: 10px;
}

.app-logo-menu-list-popover {
  margin-top: 10px;
  margin-left: 0px;
  left: 40px !important;
}
