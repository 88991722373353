.game-specialty-card-popup {
  .content {
    display: flex;
    flex-direction: column;
    &.clickable {
      &:hover {
        cursor: pointer;
        background-color:  rgba(190,190,190,0.1);
      }
    }
    .content-title {
      text-align: center;
      font-weight: 500;
      font-size: 1.8em;
      margin-bottom: 10px;
    }
    .content-image {
      padding: 5px 15px;
      min-width: 400px;
      box-sizing: border-box;
      height: 100%;
      margin: auto;
      .image {
        width: 100%;
      }
    }
    .extra-icon {
      position: absolute;
      height: 85%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .action-area {
    display: flex;
    width: 80%;
    margin: auto;
    button {
      width: 100%;
      margin: auto;
    }
  }
}