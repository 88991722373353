.allow-custom-response {
  position: absolute;
  z-index: 1;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;

  .ff-image-count-selector {
    display: flex;
    flex-direction: column;
  }

  .ff-image-count-slider {
    margin-left: 5px !important;
    margin-right: 15px !important;
  }
}