@import "../../../../../../styles/colors.scss";

.admin-search-form {
  display: flex;
  align-items: flex-end;
  .admin-search-form-input-field {
    width: 200px;
    margin-right: 20px;
  }

  .search-button {
    height: 40px;
  }

  .clear-button {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 20px;
    font-size: 1.1em;
    color: $color-secondary;
    font-weight: 500;
    user-select: none;
    &:hover {
      opacity: 0.8;
    }
  }
}