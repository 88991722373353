@import "../../../styles/colors.scss";

.forgot-password-button {
  color: $color-gray-monarch-website;
  font-size: 0.9688rem;
  &:hover {
    text-decoration: underline;
  }
  box-shadow: none;
}
