@import "../../../../styles/colors.scss";

.multiple-choice-thumbnail {
  .multiple-choice-component-question,
  .multiple-choice-component-question > div {
    height: 100% !important;
    width: 100%;
    .MuiPaper-root {
      height: 100%;
    }
  }

  &.single-page {
    .multiple-choice-component-responses {
      height: unset;
    }
    .multiple-choice-single-page-text {
      padding: 0px 5px 2px 5px;
      box-sizing: border-box;
    }
  }
  &.side-by-side {
    .multiple-choice-component-responses {
      row-gap: 0.1em;
      font-size: 0.8em;
      .multiple-choice-component-response {
        width: 100%;
      }
    }
  }

  .prefix {
    margin-right: 5px;
  }
}
