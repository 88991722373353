@import '../../styles/components.scss';

.vizzle-app-bar-position-component {
  .vizzle-app-bar-position-top {
    top:0;
    background-color: #ffffff;
    @extend .vizzle-box-shadow;
  }

  .vizzle-app-bar-position-bottom {
    top: auto;
    bottom: 0;
  }
}

.vizzle-app-bar-container-position-bottom {
  position: absolute;
  bottom: 0px;
}