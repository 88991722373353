@import "../../../../styles/components.scss";

.matching-activity {
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;

  .place-holder-container {
    height: 100%;
    position: relative;
  }

  .matching-component-hidden {
    display: none;
  }

  .fading {
    opacity: 0.7;
  }

  .matching-activity-prompt-area-container {
    height: 45%;
    width: 83%;
    margin-bottom: 3em;
    margin-left: auto;
    margin-right: auto;
  }

  .matching-activity-responses {
    height: 38%;
    @media screen and (max-width: 1024px) {
      margin-top: 20px;
      height: 32%;
    }
  }

  .matching-activity-responses-area {
    margin-top: 0.5em;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .response-card-container {
    width: 20%;
    height: 100%;
    position: relative;
    margin-left: 0.8rem;
    margin-right: 0.8rem;

    &.response-margin-2-responses {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    &.response-margin-3-responses {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }

  .slider-container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
  }

  .matching-component-response-card-non-drag-items {
    transform: translate(0, 0) !important;
    transition: transform 0.7s !important;
    z-index: 1;
  }

  .matching-component-disabled {
    pointer-events: none;
  }
  .matching-component-fading {
    opacity: 0.6;
  }

  .matching-high-z-index {
    z-index: 1000;
  }

  .replay-button-container {
    position: absolute;
    left: 2em;
    @media screen and (max-width: 1024px) {
      left: 1em !important;
    }
    top: 0px;
    z-index: 1;
  }

  .matching-selected-card {
    @extend .vizzle-selected-component-medium;
    .media-component {
      pointer-events: none;
    }
  }

  .prompt-selected-card {
    .matching-prompt-activity-prompt-area-question-container {
      .media-component {
        pointer-events: none;
      }
    }
  }

  .matching-component-response-card-container {
    position: absolute !important;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left:0 !important;
    &:hover {
      @extend .vizzle-selected-component;
    }
  }
}
