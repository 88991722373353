.unit-guide-page {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  .unit-guide-page-container {
    margin-bottom: 30px;
  }
  .unit-guide-header {
    padding: 45px 10% 5px 10%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .unit-guide-pdf-logo img {
      width: 240px;
      // height: 100px;
    }

    .unit-info {
      flex-grow: 0.5;
      max-width: 60%;
      .header-text {
        font-weight: 800;
      }

      .unit-header {
        margin-top: 15px;
      }
      .topic-header {
        margin-top: 10px;
      }
    }
  }

  .unit-guide-body {
    padding: 20px 3%;

    .unit-guide-body-sub-title {
      font-weight: 500;
    }

    .standard-entry {
      margin-bottom: 10px;
    }

    .section-margin-top {
      margin-top: 25px;
    }

    ul {
      margin: 0px;
    }

    .standard-group {
      margin-bottom: 10px;
    }
    .standard-list {
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      .standard-entry {
        margin-bottom: 3px;
      }
    }
    .standard-in-progress {
      font-weight: 700;
    }
  }
}

@media print {
  html,
  body {
    height: 100%;
  }
  
  .unit-guide-page-container {
    page-break-before: always;
    &:nth-child(1) {
      page-break-before: avoid !important;
    }
    margin-bottom: 0px !important;
    box-shadow: none !important;
  }

  .unit-guide-body {
    padding-bottom: 0px !important;
    page-break-before: avoid !important;
  }

  // header {
  //   display: flex !important;
  //   width: 100%;

  //   .copy-right {
  //     margin-left: auto;
  //   }
  // }
}

@page {
  margin: 3mm 5mm 10mm 5mm !important;
  size: auto;
  font-size: 1em !important;
  line-height: 1 !important;
}
