@import '../../../../../styles/colors.scss';

.rejected-answer-response {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  opacity: 0;

  .rejected-answer-icon {
    margin: auto;
    font-size: 6em;
    color: $color-bad;
    font-weight: 700;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.rejected-answer-response-border {
  box-shadow: 0px 3px 3px 3px $color-bad, 0px 0px 7px 7px $color-bad, 0px 3px 2px -1px $color-bad !important;
}

.rejected-answer-response-show {
  opacity: 1;
  z-index: 100;
}

.rejected-answer-response-hide {
  opacity: 0;
  z-index: 0;
  transition: all 1.4s ease-in-out;
}