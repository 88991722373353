.admin-action-icon-button {
  padding: 5px !important;
  margin-left: 15px !important;
  transition: all 0.2s ease-in-out;
  svg {
    font-size: 1.3em;
    fill: black;
  }

  &.Mui-disabled {
    opacity: 0.5;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 5px !important;
    svg {
      font-size: 2em;
    }
  }

}

.admin-action-icon-button-label {
  font-weight: 500;
}

.admin-action-button {
  margin-left: 15px !important;
  svg {
    font-size: 1.6em !important;
    fill: black;
  }

  &.margin-top {
    margin-top: 5px;
  }
}

.admin-action-multiple-line-label {
  line-height: 20px !important;
  margin-left: 10px !important;
}