@import "../../../../styles/colors.scss";

.lesson-activity-navigator-bar {
  width: 87%;
  padding-top: 5px;
  padding-bottom: 5px;

  .page-data-navigator {
    width: 100%;
  }

  .lesson-page-data-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    font-size: 0.6em;
  }
  
  .lesson-page-data-card-wrapper {
    position: relative;
    outline: none;
    cursor: pointer;
  }

  .lesson-page-data-card {
    margin-right: 12px;
  }

  .lesson-page-data-card-selected {
    border: 3px solid $color-for-selection;
    box-sizing: border-box;
  }

  .page-number {
    position: absolute;
    z-index: 1;
    margin-left: -12px;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4em;
    font-weight: 500;
    text-shadow: 2px 2px #cccccc;
    opacity: 0.65;
  }

  .book-card-thumbnail-placeholder-text,
  .book-card-thumbnail-placeholder,
  .matching-thumbnail-placeholder,
  .card-editor-placeholder,
  .card-thumbnail-placeholder-text,
  .card-thumbnail-placeholder,
  .thumbnail-image-placeholder,
  .thumbnail-text {
    display: none !important;
  }
}
