@import '../../styles/colors.scss';
.date-from-to-form {
  width: 100%;
  padding: 10px 30px 10px 20px;
  background-color: $color-medium;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .error-bar {
    margin-bottom: 10px;
  }

  .date-from-to-container {
    display: flex;
    flex-wrap: wrap;
  }

  .input {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-right: 10px;
    
    .MuiButtonBase-root {
      padding-left: 10px;
      padding-right: 5px;
      svg {
        color: $color-secondary;
      }
    }
  }
  .date-input {
    @extend .input;
    max-width: 170px;
  }

  .time-input {
    @extend .input;
    .MuiButtonBase-root {
      svg {
        color: $color-light;
      }
    }
    max-width: 130px;
  }

  .picker {
    margin-left: 10px;
    // min-height: 63px;
    label {
      color: $color-gray-monarch-website--light;
    }
    input {
      color: $color-secondary;
      padding-bottom: 0px;
      margin-left: -10px;
    }
    .MuiInput-underline:before {
      border-bottom: none !important;
    }
  }
  .date-picker {
    max-width: 260px;
    @extend .picker;
  }

  .time-picker {
    @extend .picker;
    input {
      color: $color-light;
    }
  }

  .time-container {
    display: flex;
    position: relative;

    .time-error {
      position: absolute;
      bottom: 3px;
      left: 10px;
      color: #f44336; // same color as the date picker component;
      font-size: 0.8em;
    }
  }

  .required-field {
    label {
      &:after {
        content: '*';
        margin-left: 5px;
        color: red;
      }
    }
  }
}