.extension-activities-view-files {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  .toolbars {
    padding: 10px 15px;
    z-index: 1;
  }

  .file-list-container {
    overflow-y: auto;
    flex-grow: 1;
  }
}
