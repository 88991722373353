.subsequence-play-tree-view {
  .MuiTreeItem-label {
    font-size: 0.8rem;
  }
  .MuiTreeItem-content {
    padding: 8px 0px;
  }
  .tree-view-entry {
    display: flex;
    align-items: center;
    .title {
      white-space: nowrap;
    }
  }
}