@import "../../../styles/colors.scss";

.student-list-student-selector-no-students {
  .gap {
    margin-top: 20px;
  }
  .no-students {
    padding: 0px 20px;
    .title {
      opacity: 0.8;
      margin-top: 0px;
    }
    .add-student-link {
      padding-left: 0px;
      padding-right: 0px;
      color: $color-primary;
      font-size: 1.5em;
      font-weight: 500;
    }
  }
}

.student-list-student-selector {
  display: grid;
  height: calc(100% - 100px);
  grid-template-rows: auto 83%;
  min-width: 366px;
  max-width: 366px;

  @media screen and (max-width: 1024px) {
    min-width: 300px;
    max-width: 300px;
    height: calc(100% - 70px);
  }
  .students {
    margin-top: 0px;
    padding: 0px 15px 5px 15px;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 25px 30px auto;

    .MuiCheckbox-root {
      color: $color-secondary;
    }

    .MuiFormControlLabel-root {
      margin-left: 0px;
      min-height: 30px;
    }

    .select-all-label {
      color: $color-primary;
      font-weight: 500;
    }

    .student-list {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .selected-student {
        .MuiFormControlLabel-label {
          color: $color-secondary;
        }
      }
    }
  }
}
