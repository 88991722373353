.assign-student-dialog-container {
  
  .MuiDialog-paper {
    width: 900px;
    height: 750px;
    max-width: none;
  }

  .MuiDialogContent-root {
    overflow: hidden;
  }
}