.generate-standard-container {
  margin-top: 5px;
  width: 100%;
  height: 98%;
  iframe {
    border: 0;
  }
}

.generate-standard-container-loading {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .loading-message {
    margin-top: 15px;
    font-weight: 500;
  }
}