.student-groups {
  height: 100%;
  display: flex;
  justify-content: center;
  .student-groups-table-container {
    width: 80%;
    position: relative;
  }

  .table-row-actions-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
}
