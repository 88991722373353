@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.multiple-choice-v2-editor-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .multiple-choice-v2-question-key {
    position: relative;
  }
  .MuiTab-root {
    min-width: 130px;
    &.Mui-selected {
      color: $color-primary;
      font-weight: 500;
    }
  }

  .MuiTabs-indicator {
    background-color: $color-primary;
  }

  .flexi-matching-question-key {
    position: relative;
  }

  .editor-content-container {
    flex-grow: 1;
    margin-top: 10px;
    height: 100%;
    display: flex;
  }

  .prompt-text-container {
    flex-grow: 1;
    display: flex;
    height: 100%;
    flex-direction: column;

    .card-editor-text-action {
      margin-bottom: 0px;
    }
    .sub-text-prompt-text {
      margin-top: 10px;
    }
    .text-line-span {
      white-space: normal;
    }
  }

  .prompt-cards-container {
    height: 100%;
  }

  .response-cards-container {
    width: 100%;
    flex-grow: 1;
    margin-top: 1em;
    display: grid;
    grid-template-columns: repeat(3, calc(33.3% - 0.25em));
    grid-auto-rows: minmax(10px, 50%);
    grid-column-gap: 0.5em;
    grid-row-gap: 0.7em;
    align-content: flex-start;
    .card-editor-with-value {
      padding: 0.1rem 0.5rem;
    }

    .answer-card-content-container {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
  
      // .card-index {
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   z-index: 1;
      //   margin-right: 7px;
      //   width: 25px;
      //   height: 25px;
      //   background-color: $color-primary;
      //   color: white;
      //   border-radius: 40px;
      // }
      .matching-editor-answer-key {
        width: 40%;
        position: relative;
      }
      .answer-key-move-indicator-container {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}
