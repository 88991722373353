@import "../../../../../styles/colors.scss";

.edit-goal-name-action-edit-button {
  text-align: center;
  color: white !important;
  background-color: $color-secondary !important;
  border-radius: 50%;
  font-size: medium !important;
}

.edit-goal-name-action {

  .edit-goal-name-action-content {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .input {
    width: 333px !important;
    color: #5a5759;
    font-weight: 700;
    font-size: 100%
  }
}

.edit-goal-name-action-ok-button {
  margin-left: auto !important;
  margin-right: auto !important;
  min-width: 100px !important;
}