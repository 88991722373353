@import "../../../../../../../../styles/colors.scss";

.text-editor-warning-label {
  margin-top: -15px;
  margin-bottom: 5px;
  background: $color-bad;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-weight: 500;
}