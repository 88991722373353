@import '../../../../styles/colors.scss';

.matching-component {
  width: 99%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .matching-component-prompts {
    height: 45%;
    margin-bottom: 3em;
  }
  .matching-component-responses {
    height: 38%;
    @media screen and (max-width: 1024px) {
      margin-top: 20px;
      height: 32%;
    }
  }

  .matching-component-card {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .matching-component-image {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background-size: contain;
    height: 100%;
    display: flex;
  }

  .matching-component-text {
    align-items: center;
    padding: 0.4em 0.4em 0.4em 0.4em !important;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .matching-component-responses-area {
    margin-top: 0.5em;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .matching-response-drag-container {
    width: 20%;
    height: 100%;
    position: relative;

    &.response-margin-2-responses {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    &.response-margin-3-responses {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    &.response-margin-4-responses {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    &.response-margin-5-responses {
      margin-left: 0.7rem;
      margin-right: 0.7rem;
    }
  }

  .matching-component-response-card-non-drag-items {
    transform: translate(0, 0) !important;
  }

  .matching-component-prompts-area-question-mark-container {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: $color-gray-monarch-website--light;
    color: white;
    position: relative;
  }
  .matching-component-prompts-area-question-mark {
    margin: auto;
    font-size: 7em;
    font-weight: 500;
  }
  .matching-component-full-height {
    height: 100%;
  }
  .matching-component-hidden {
    display: none;
  }
  .matching-component-disabled {
    pointer-events: none;
  }
  .matching-component-fading {
    opacity: 0.6;
  }
  .matching-component-prompts-reject {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .matching-component-prompts-reject-show {
    opacity: 1;
  }

  .matching-component-prompts-reject-hide {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
}