@import "../../../../styles/colors.scss";

.authored-lesson-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .filter {
    width: 100%;
    padding-top: 0px;
    margin-bottom: 10px;
  }

  .lesson-list-container {
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
    .lesson-list {
      position: absolute;
      width: 100%;
    }
  }
}

.authored-lesson-additional-detail-panel {
  padding: 0px 20px;
  margin-top: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .title {
    margin-right: 15px;
  }

  .grade-level,
  .keyword {
    padding: 5px 10px;
    border-radius: 15px;
    background-color: $color-gray-monarch-website--light-medium;
    color: $color-medium;
  }

  .keyword-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
    gap: 8px;
    .keyword {
      min-width: 70px;
      text-align: center;
      margin-bottom: 5px;
    }
  }
}
