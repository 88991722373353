.text-to-speech-settings-form-v1 {
  width: 100%;
  max-width: 600px;
  min-width: 345px;

  .selector-grid {
    display: grid;
    align-items: center;
    grid-gap: 10px;
  }
  
  .selector-container {
    @extend .selector-grid;
    grid-template-columns: minmax(50px, 15%) minmax(100px, 50%);
  }

  .color-selector-container {
    @extend .selector-grid;
    grid-template-columns: minmax(50px, 15%) minmax(100px, 50%) auto;

    .tts-color {
      width: 40px;
      height: 40px;
      border-radius: 7px;
    }
  }

  .sample {
    display: flex;
    .sample-text {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      font-weight: 500;
    }
  }
}
