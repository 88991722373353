@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/colors.scss";

.objective-lesson-play {
  display: grid;
  grid-template-columns: 30% 50% 20%;
  height: 86px;
  margin-bottom: 10px;
  background-color: $color-light;
  border-radius: 3px;
  align-items: center;
  justify-items: center;
  border: 3px solid $color-gray;

  .lesson-image {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    border-right: 2px solid $color-gray-monarch-website--light;
  }

  .lesson-name {
    text-align: center;
    font-size: 1.2em;
    padding: 0 0.7em;
    @include hideText(3);
  }

  .percentage-correct {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid $color-gray-monarch-website--light;

    .lesson-played {
      text-align: center;
      .percentage {
        font-size: 1.5em;
        font-weight: 500;
      }
      .average {
        font-size: 0.8em;
        @media screen and (max-width: 1024px) {
          padding: 0 0.7em;
        }
      }
    }
    .no-lesson-played {
      text-align: center;
      font-size: 0.8em;
      margin: auto;
      padding: 0 0.9em;
    }
  }
}