@import "../../../../styles/colors.scss";
@import "../../../../styles/components.scss";

.game-card-grid-editor {
  height: 100%;
  width: 98%;
  margin: auto;
  .game-grid {
    height: 100%;
  }

  .game-card {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    .card-action-selector {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      font-size: 0.75em;
      &:hover {
        cursor: pointer;
        background-color: #d9d9d9;
      }
    }

    &.place-holder {
      background-color: lightgray;
    }
  }

  .card-text-container {
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    box-sizing: border-box;
    &.image-with-text {
      bottom: 0px;
    }
    &.only-text {
      position: relative;
    }
  }

  .card-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    .card-image {
      height: 70%;
      width: 100%;
      background-size: contain;
      display: flex;
      margin-top: auto;
      margin-bottom: auto;
      &.image-with-text {
        height: 60%;
      }
    }
    .card-with-media-icon {
      color: $color-primary;
      font-size: 1.7em;
      background-color: white;
      border-radius: 3px;
      margin-top: 5px;
      margin-right: 2px;
    }
    &.no-height {
      height: 0%;
    }
    .media-icons {
      position: absolute;
      right: 0px;
    }
    .extra-icon {
      position: absolute;
      height: 85%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .audio-uploader-drag-active {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $color-gray-monarch-website--light;
    opacity: 0.5;
    transition: 0.8s ease-in-out;
  }

  .card-selected {
    @extend .vizzle-selected-component;
  }
  .card-disabled {
    opacity: 0.5;
    background-color: $color-gray-monarch-website--light-fading;
  }
}
