.student-management-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  box-sizing: border-box;
  .action-container {
    display: flex;
    flex-direction: row;
  }

  .student-list {
    margin-top: 10px;
    overflow-y: auto;
    height: 100%;
  }

  .contact-label {
    padding-left: 0px;
    padding-top: 0px;
  }
}
