.username-to-reset-form-dialog {
  .title {
    padding-top: 30px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.3em;
  }
  .content {
    box-sizing: border-box;
    margin: 30px 10px;
    .text-form {
      width: 350px;
    }
  }

  .next-button {
    width: 100%;
    text-transform: unset;
  }
}