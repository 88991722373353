.flat-lesson-card-list-detail {

  .list-detail-container {
    margin-top: -30px;
    display: flex;
    flex-wrap: wrap;

    .lesson-card-action {
      margin-top: 30px;
      margin-right: 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}