@import "./colors.scss";

.btn-primary-rounded-base {
  border-radius: 50px !important;
  font-size: 1em;
  font-weight: 500;
  text-transform: none !important;
  box-shadow: none !important;
  border: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 18px;
  padding-right: 18px;
  min-width: 100px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.btn-primary {
  background-color: $color-primary !important;
  color: white !important;
  @extend .btn-primary-rounded-base;
  &:hover {
    background-color: darken($color-primary, 10) !important;
  }
}

.btn-primary:disabled {
  background-color: $color-gray-monarch-website--light !important;
}

.btn-blue-primary {
  background-color: $color-blue-primary !important;
  color: $color-light !important;
  min-height: 36px;
  &:hover {
    background-color: lighten($color-blue-primary, 10) !important;
  }
}

.btn--blue-primary:disabled {
  background-color: $color-gray-monarch-website--light !important;
}

.btn-secondary {
  background-color: $color-secondary !important;
  color: $color-light !important;
  min-height: 36px;
  &:hover {
    background-color: lighten($color-secondary, 10) !important;
  }
}

.btn-secondary:disabled {
  background-color: lighten($color-secondary, 20%) !important;
}

.btn-danger {
  background-color: $color-bad !important;
  color: $color-light !important;
  @extend .btn-primary-rounded-base;
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 70px !important;
  &:hover {
    background-color: lighten($color-bad, 10) !important;
  }
}

.btn-danger:disabled {
  opacity: 0.5;
}

.btn-gray-background {
  background-color: $color-gray-monarch-website--light-medium !important;
  color: $color-medium !important;
  &:hover {
    background-color: $color-gray-monarch-website--light !important;
  }
}

.btn-gray-background:disabled {
  opacity: 0.5;
}

.btn-green-background {
  background-color: $color-tertiary !important;
  color: $color-light !important;
  @extend .btn-primary-rounded-base;
  &:hover {
    background-color: lighten($color-tertiary, 10) !important;
  }
}

.btn-green-background:disabled {
  opacity: 0.5;
}

.btn-spinning {
  color: $color-light !important;
}

.btn-selected {
  box-shadow: 0 0 0 3px lighten($color-primary, 12%) !important;
  background-color: darken($color-primary, 10%) !important;
}

.btn-text-primary {
  color: $color-primary !important;
  text-transform: none !important;
  font-size: 1.1em !important;
}

.btn-link {
  color: $color-secondary !important;
  font-weight: 500 !important;
  font-size: 1.5em;
  transition: all 0.5s;
  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
}

.btn-primary-rounded-border {
  background-color: white !important;
  border: 1px solid $color-primary !important;
  color: $color-primary !important;
  @extend .btn-primary-rounded-base;
  &:hover {
    color: darken($color-primary, 10) !important;
    background-color: darken(white, 10) !important;
  }
}

.btn-primary-rounded-border:disabled {
  color: $color-gray-monarch-website--light !important;
}
