@import '../../../../../styles/colors.scss';
@import '../../../../../styles/components.scss';
@import '../../../../../styles/mixins.scss';

.card-editor-component {
  display: flex;
  height: 100%;
  width: 100%;

  .Mui-disabled {
    opacity: 0.5;
  }
  .card-editor {
    margin-left: 0.12em;
    margin-right: 0.12em;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
  
  .card-editor-image-action {
    height: 70%;
    margin-bottom: 0.2em;
    padding: 0.2em 0.5em;
    position: relative;
    .card-editor-image {
      height: 100%;
      width: 100%;
      top:0;
      left: 0;
      position: absolute;
      margin-bottom: 0.1em;
      background-size: contain;
      display: flex;
    }
    .card-editor-with-video-icon {
      color: $color-primary;
      font-size: 2em;
      margin-left: auto;
    }
  }

  .card-editor-text-action {
    height: 15%;
    min-height: 22px;
    padding: 0.2em 0.5em;
    overflow: hidden;
    margin-bottom: 0.1em;
    border-radius: 5px;
    position: relative;
    flex-grow: 1;
    &.large {
      height: 100%;
    }
  }

  .card-editor-audio-action {
    height: 15%;
    min-height: 22px;
    padding: 0.2em 0.5em;
    overflow: hidden;
    border-radius: 5px;
    .card-editor-with-audio {
      margin: auto;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .card-editor-with-audio-icon {
      color: $color-primary;
      font-size: 2em;
      margin: auto;
    }
    .card-editor-audio-file {
      line-height: 1.4em;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .card-editor-with-value {
    padding: 0.2rem 0.5rem;
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
    position: absolute;
    box-sizing: border-box;
    background-color: $color-gray-monarch-website--light-fading;
    border-radius: 5px;
    overflow: hidden;
  }

  .card-editor-placeholder {
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
    position: absolute;
    background: lightgray;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    border-radius: 5px;
    @media screen and (max-width: 1024px) {
      font-size: 0.75em;
    }
    margin-bottom: 0.5em;
    display: flex;
  }

  .card-editor-selected {
    .card-editor-section-selected {
      box-sizing: border-box;
      border: 3px solid $color-for-selection;
      border-radius: 5px;
    }
  }
}