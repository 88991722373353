.topic-unit-guide-editor {
  margin-bottom: 30px;

  .topic-unit-guide-body {
    height: 450px;
  }

  .topic-unit-guide-body {
    width: 100%;
    box-sizing: border-box;
  }

  .topic-unit-guide-editor-container {
    height: 420px;
    padding: 10px 20px;
    box-sizing: border-box;
  }

  .text-editor-editor {
    height: 100%;
    .public-DraftStyleDefault-block {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}
